.root__modal-overlay {
  padding: 0px var(--inside-padding);
  z-index: 999999;
}
.root__modal-content {
  position: relative;
  margin: auto;
  background: var(--secondary-dark-color);
  max-width: 750px;
  min-height: 320px;
  width: 100%;
  padding: 5px;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 25px 10px;
  backdrop-filter: blur(100px);
  box-shadow: 0px 1.4px 2.1px rgba(0, 0, 0, 0.032),
    0px 4.7px 6.9px rgba(0, 0, 0, 0.048), 0px 21px 31px rgba(0, 0, 0, 0.08);
  .root__modal-close {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer;
    svg {
      color: var(--main-text-color);
    }
    @media only screen and (min-width: 992px) {
      &:hover {
        svg {
          opacity: 0.55;
        }
      }
    }
  }
  h1 {
    font-size: var(--l-font-size);
    font-family: $font-bold;
    font-weight: 700;
    color: var(--main-text-color);
    text-align: center;
  }
  p {
    max-width: 75%;
    font-size: var(--m-font-size);
    font-family: $font-light;
    font-weight: 300;
    color: var(--main-secondary-text-color);
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    max-width: 550px;
    min-height: 250px;
    h1 {
      font-size: var(--m-font-size);
    }
    p {
      font-size: var(--s-font-size);
    }
  }
}
.root__modal-arrow {
  color: rgb(255, 255, 255);
}

.root__modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}
@keyframes anvil {
  0% {
    transform: scale(0.5) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  50% {
    transform: scale(0.7) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

// Color Variants
.yellow__modal-content {
  background-image: linear-gradient(
    112deg,
    rgba(0, 65, 107, 0.4) 20%,
    rgba(248, 180, 26, 0.4) 100%,
    rgba(0, 65, 107, 0.4) 80%
  );
  background-size: 200% 200%;
  background-position: top left;
  animation: gradientAnimation 10s ease-in infinite;
}
.red__modal-content {
  background-image: linear-gradient(
    115deg,
    rgba(0, 65, 107, 0.4) 20%,
    rgba(241, 91, 91, 0.4) 100%,
    rgba(0, 65, 107, 0.4) 20%
  );
  background-size: 200% 200%;
  background-position: top left;
  animation: gradientAnimation 10s ease-in infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}
