@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}

// Variables
:root {
  // Fonts Variables
  --xl-font-size: 1.75rem;
  --l-font-size: 1.4375rem;
  --m-font-size: 1.125rem;
  --s-font-size: 1rem;
  --xs-font-size: 0.875rem;

  // Colors Variables
  --main-text-color: #fff;
  --main-secondary-text-color: #e6e7e8;
  --main-secondary-text-color-2: #e7edfb;
  --secondary-color: #ffb000;
  --main-color: #40b1e5;
  --secondary-body-color: #e7edfb;
  --secondary-text-color: #333;
  --secondary-dark-color: #00416b;
  --secondary-light-blue: #4fabb8;
  --secondary-light-red: #f15b5b;
  --secondary-purple-color: #9f64a8;
  --secondary-light-green-color: #8dc63f;
  --secondary-subtitle-text-color: #5e5e5e;
  --secondary-dark-body-color: #0c1b2b;
  --disabled-color: #5e5e5e;
  --footer-color: #152e4a;

  // Side Padding
  --mobile-padding: 20px;
  --desktop-padding: 50px;

  // Inside content padding
  --inside-padding: 15px;
}

// Fonts Variables
$font-bold: "Poppins-Bold"; // Font weight 700
$font-medium: "Poppins-Medium"; // Font weight 500
$font-light: "Poppins-Light"; // Font weight 300

// Font Sizes
// $xl-font-size: 1.875rem;
// $l-font-size: 1.5625rem;
// $m-font-size: 1.25rem;

// Smallest we can do
$xl-font-size: 1.75rem;
$l-font-size: 1.4375rem;
$m-font-size: 1.125rem;

$s-font-size: 1rem;
$xs-font-size: 0.875rem;

// Colors Variables
$main-secondary-text-color: #e6e7e8;
$main-text-color: #fff;
$secondary-color: #ffb000;
$main-color: #40b1e5;
$secondary-body-color: #e7edfb;
$secondary-text-color: #333;
$secondary-dark-color: #00416b;
$secondary-light-blue: #4fabb8;
$secondary-light-red: #f15b5b;
$secondary-purple-color: #9f64a8;
$secondary-light-green-color: #8dc63f;
$secondary-subtitle-text-color: #5e5e5e;
$secondary-dark-body-color: #0c1b2b;
$disabled-color: #5e5e5e;
$footer-color: #152e4a;

// // Side Padding
// $mobile-padding: 20px;
// $desktop-padding: 50px;

// // Inside content padding
// $inside-padding: 15px;
