.home__success {
  width: 100%;
  height: 100%;
  padding: 70px 0px;
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color);
  overflow: hidden;
  .about__success-title {
    h1 {
      color: var(--secondary-text-color) !important;
    }
    h2 {
      color: var(--secondary-subtitle-text-color) !important;
    }
  }
}
