.article__integration {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  background-color: var(--main-secondary-text-color-2);
  overflow-x: hidden;
  .article__integration-container {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    padding: 0px var(--inside-padding);
    .article__integration-text {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 20px;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 5;
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        gap: 10px;
        .article__integration-title {
          align-items: center !important;
          h1,
          p {
            text-align: center !important;
          }
        }
      }
      .article__integration-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (max-width: 992px) {
          h1 {
            font-size: var(--l-font-size) !important;
          }
          p {
            font-size: var(--s-font-size) !important;
          }
        }
        h1 {
          font-size: var(--xl-font-size);
          color: var(--secondary-light-red);
          font-weight: 700;
          font-family: $font-bold;
        }
        p {
          font-size: var(--m-font-size);
          color: var(--secondary-dark-body-color);
          font-weight: 500;
          font-family: $font-medium;
          max-width: 95%;
        }
      }
      .article__integration-image {
        max-width: 250px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        @media only screen and (max-width: 992px) {
          max-width: 200px;
        }
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .article__integration-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      z-index: 5;
      svg {
        color: var(--secondary-light-red);
        width: 45px !important;
        height: 45px !important;
      }
      .article__integration__content-wrapper {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        div {
          width: 100%;
          padding: 15px 35px;
          background-color: var(--main-text-color);
          border-radius: 15px;
          p {
            font-size: var(--m-font-size);
            color: var(--secondary-dark-body-color);
            font-weight: 500;
            font-family: $font-medium;
            max-width: 95%;
            @media only screen and (max-width: 992px) {
              font-size: var(--s-font-size);
            }
          }
        }
      }
    }
    .article__integration-img {
      position: absolute;
      right: -130px;
      bottom: -50px;
      max-width: 350px;
      overflow: hidden;
      z-index: 0;
      opacity: 0.5;
      img {
        width: 100%;
        object-fit: contain;
        // transform: translateX(130px);
      }
    }
  }
}

.article__integration-vector {
  width: 100%;
  height: 122px;
  overflow: hidden;
  @media only screen and (max-width: 692px) {
    height: 80px;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
}
