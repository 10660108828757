.projects {
  width: 100%;
  min-height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-purple-color);
  padding: 125px 0px 0px;
  @media only screen and (max-width: 992px) {
    min-height: 60vh;
    padding: 135px 0px;
  }
  // @media only screen and (max-height: 805px) {
  //   min-height: 100vh;
  // }
  .projects__container {
    width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    text-align: center;
    padding: 0px var(--inside-padding);
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      .projects__img {
        display: none !important;
      }
      .projects__content {
        align-items: center !important;
        h1,
        p {
          text-align: center !important;
        }
      }
    }
    .projects__content {
      flex: 0.5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      @media only screen and (max-width: 692px) {
        h1 {
          font-size: var(--l-font-size) !important;
          text-align: center !important;
        }
        p {
          font-size: var(--s-font-size) !important;
          text-align: center !important;
        }
      }
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--main-text-color);
        text-align: left;
      }
      p {
        font-size: var(--s-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-text-color);
        text-align: left;
      }
    }
    .projects__img {
      position: relative;
      flex: 0.5;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        max-height: 380px;
        object-fit: contain;
      }
    }
  }
}

// Svg
.projects__waves-svg {
  width: 100%;
  max-height: 108.54px;
  img {
    width: 100%;
    object-fit: cover;
  }
}
