.about__text {
  // max-width: 450px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 5px;
  button {
    margin-top: 10px;
  }
  @media only screen and (max-width: 692px) {
    h1 {
      font-size: var(--l-font-size) !important;
      text-align: center !important;
    }
    p {
      font-size: var(--s-font-size) !important;
      text-align: center !important;
    }
  }
  h1 {
    font-size: var(--xl-font-size);
    font-weight: 700;
    font-family: $font-bold;
    color: var(--main-text-color);
    text-align: left;
  }
  p {
    font-size: var(--s-font-size);
    font-weight: 300;
    font-family: $font-light;
    color: var(--main-text-color);
    text-align: left;
  }
}
.about__svg {
  // Slider Css
  // position: relative;
  // max-width: 365px;
  // max-height: 286px;
  // border-radius: 7px;
  // overflow: hidden;
  // box-shadow: -4px 3px 20px rgba($color: #000000, $alpha: 0.3);
  // width: 100%;
  // @media only screen and (max-width: 500px) {
  //   display: none;
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba($color: #000000, $alpha: 0.1);
  //   z-index: 10;
  // }

  max-width: 538px;
  width: 100%;
  overflow: hidden;
  transform: translateY(50px);
  img {
    width: 100%;
    object-fit: contain;
  }
  // .about_us-carousel {
  //   width: 100%;
  //   .swiper-wrapper {
  //     .swiper-slide {
  //       height: 286px;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //       }
  //     }
  //   }
  // }
}

@media only screen and (max-width: 992px) {
  .about__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
  }
}

.about {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  .about__header-vectors {
    // width: 100%;
    position: absolute;
    bottom: -10%;
    z-index: 0;
    img {
      // width: 100%;
    }
  }
  .about__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 136px 0px 0px;
    min-height: 50vh;
    @media only screen and (max-width: 992px) {
      padding: 136px 0px 20px;
    }
    .about__container {
      max-width: 1300px;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 5;
      padding: 0px var(--inside-padding);
      gap: 30px;
      p {
        width: 100%;
        color: var(--main-text-color);
      }
      @media only screen and (max-width: 992px) {
        justify-content: space-around !important;
        .about__svg-mobile {
          display: none !important;
        }
        .about__text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          text-align: center;
          p {
            color: var(--main-text-color);
          }
        }
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
    border-radius: 0px 0px 0px 50px;
    background: url("assets/images/testimonials/binaryNumbersBg.png");
    background-size: contain;
    background-repeat: repeat-y;
    transform: translateX(-30px);
    opacity: 0.06;
    overflow: hidden;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
    border-radius: 0px 0px 50px 0px;
    background: url("assets/images/testimonials/binaryNumbersBg.png");
    background-size: contain;
    background-repeat: repeat;
    transform: translateX(30px);
    opacity: 0.06;
    overflow: hidden;
  }
  @media only screen and (max-width: 992px) {
    &::after {
      left: -100px;
    }
    &::before {
      right: -100px;
    }
    @media only screen and (max-width: 640px) {
      &::after {
        left: -200px;
      }
      &::before {
        right: -200px;
      }
    }
  }
}
