.register {
  width: 100%;
  // min-height: 100vh;
  min-height: 10vh;
  // padding: 70px 0px 150px;
  padding: 150px 0px !important;
  background-color: var(--main-secondary-text-color-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  .register__container {
    width: 100%;
    max-width: 950px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 var(--inside-padding);

    @media only screen and (max-width: 681px) {
      .register__input-double {
        flex-direction: column;
        gap: 5px !important;
      }
      .register__wrapper {
        gap: 5px !important;
      }
      .register__input {
        font-size: var(--s-font-size) !important;
        &::placeholder {
          font-size: var(--s-font-size) !important;
        }
        height: 55px !important;
      }
    }
    .register__title {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      gap: 10px;

      h1 {
        font-size: var(--xl-font-size);
        color: var(--secondary-text-color);
        font-weight: 700;
        font-family: $font-bold;
      }
      h2 {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--secondary-text-color);
      }
      .register__summer {
        h2 {
          font-size: var(--m-font-size);
          color: var(--secondary-text-color);
          font-weight: 300;
          font-family: $font-light;
          a {
            font-weight: 500;
            font-family: $font-medium;
            color: var(--main-color);
            text-decoration: underline 2px solid var(--main-color);
            @media only screen and (min-width: 992px) {
              &:hover {
                color: var(--secondary-purple-color);
                text-decoration: underline 2px solid
                  var(--secondary-purple-color);
              }
            }
          }
        }
      }
    }
    .register__wrapper {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 35px;
      margin-top: 30px;
      .register__input-double {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .register__input-date {
          width: 101%;
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 681px) {
            width: 100%;
          }
          label {
            font-size: var(--s-font-size);
            font-weight: 300;
            font-family: $font-medium;
            color: var(--secondary-subtitle-text-color);
            span {
              color: var(--secondary-light-red);
            }
            @media only screen and (min-width: 681px) {
              position: absolute;
              top: -25px;
              left: 0;
            }
          }
          .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
              width: 100%;
              height: 65px;
              border-radius: 10px;
              border: 1.5px solid var(--main-color);
              font-size: var(--m-font-size);
              font-weight: 500;
              font-family: $font-medium;
              color: var(--secondary-text-color);
              background-color: transparent;
              padding: 5px;
              outline: none;
              letter-spacing: 0.5px;
              transition: 0.5s ease-in;
              &::after,
              &::before {
                content: unset;
                display: none;
              }
              &:focus {
                border: 1px solid rgba($color: $main-color, $alpha: 0.5) !important;
                padding-left: 7px !important;
                background-color: rgba(
                  $color: var(--footer-color),
                  $alpha: 0.2 !important
                );
              }
              @media only screen and (max-width: 681px) {
                font-size: var(--s-font-size) !important;
                &::placeholder {
                  font-size: var(--s-font-size) !important;
                }
                height: 55px !important;
              }
              // @media only screen and (min-width: 992px) {
              //   &:hover {
              //     padding-left: 10px;
              //     border: 1.7px solid var(--main-color);
              //     transition: 0.1s ease-out;
              //     &::placeholder {
              //       color: var(--main-color) !important;
              //       -webkit-text-fill-color: var(--main-color) !important;
              //       transition: 0.3s ease-in-out;
              //     }
              //   }
              // }

              input {
                &::placeholder {
                  font-size: var(--m-font-size);
                  font-weight: 500;
                  font-family: $font-medium;
                  color: var(--main-secondary-text-color) !important;
                  -webkit-text-fill-color: var(
                    --main-secondary-text-color
                  ) !important;
                  transition: 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
    p {
      text-align: center;
      font-size: var(--s-font-size);
      color: var(--secondary-text-color);
      font-family: $font-light;
      font-weight: 300;
    }
  }
}

// Input Design
.register__input-container {
  position: relative;
  width: 100%;
  // height: 65px;
  label {
    // position: absolute;

    font-size: var(--s-font-size);
    font-weight: 300;
    font-family: $font-medium;
    color: var(--secondary-subtitle-text-color);
    span {
      color: var(--secondary-light-red);
    }
    @media only screen and (min-width: 681px) {
      position: absolute;
      top: -25px;
      left: 0;
    }
  }
}
.register__input {
  width: 100%;
  height: 65px;
  border-radius: 10px;
  border: 1.5px solid var(--main-color);
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: $font-medium;
  color: var(--secondary-text-color);
  background-color: transparent;
  padding: 5px;
  outline: none;
  letter-spacing: 0.5px;
  transition: 0.15s ease-in;
  &:focus {
    border: 1px solid rgba($color: $main-color, $alpha: 0.5) !important;
    padding-left: 7px !important;
    background-color: rgba($color: $main-color, $alpha: 0.15);
  }
  // @media only screen and (min-width: 992px) {
  //   &:hover {
  //     padding-left: 10px;
  //     border: 1.7px solid var(--main-color);
  //     transition: 0.1s ease-out;
  //     &::placeholder {
  //       color: var(--main-color) !important;
  //       -webkit-text-fill-color: var(--main-color) !important;
  //       transition: 0.3s ease-in-out;
  //     }
  //   }
  // }

  &::placeholder {
    font-size: var(--m-font-size);
    font-weight: 500;
    font-family: $font-medium;
    color: var(--secondary-text-color) !important;
    -webkit-text-fill-color: var(--secondary-text-color) !important;
    transition: 0.3s ease-in-out;
  }
  &::-webkit-input-placeholder {
    font-size: var(--m-font-size);
    font-weight: 500;
    font-family: $font-medium;
    color: var(--secondary-text-color) !important;
    -webkit-text-fill-color: var(--secondary-text-color) !important;
    transition: 0.3s ease-in-out;
  }
  option {
    background-color: var(--main-text-color);
    padding: 10px 0px;
  }
  option:checked {
    color: var(--main-text-color) !important;
    background-color: var(--secondary-purple-color);
  }
}
.react-datepicker__tab-loop,
.react-datepicker__tab-loop__start {
  transition: none !important;
}
select.register__input {
  cursor: pointer;
}

.register-message {
  width: 100% !important;
  padding: 10px;
  transition: 0.1s all;
  min-height: 200px;
  max-height: 320px;
  resize: vertical;
}

.register__btn-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  .register-form-btn {
    width: 270px;
    height: 55px;
  }
  @media only screen and (max-width: 681px) {
    margin-top: 20px;
  }
}

.register-birthday[type="date"]::-webkit-calendar-picker-indicator {
  // -webkit-appearance: none;
  // display: none;
  filter: invert(1);
}

.register__time-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-size: var(--m-font-size);
    font-weight: 500;
    font-family: $font-medium;
    color: var(--secondary-text-color) !important;
    transition: 0.3s ease-in-out;
    @media only screen and (max-width: 681px) {
      font-size: var(--s-font-size) !important;
    }
  }
}
.register__cards-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  .register__cards-parent {
    margin-top: 10px;
    overflow: hidden;
    border-radius: 50px;
    .register__cards {
      position: relative;
      // width: max-content;
      min-height: 30px;
      padding: 10px 20px;
      border-radius: inherit;
      background-color: rgba($color: $main-color, $alpha: 0.2);
      border: solid 1px var(--main-color);
      transition: 0.2s ease-in-out;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: url("assets/images/icons/exit.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        transition: 0.3s ease-in;
      }
      @media only screen and (min-width: 992px) {
        &:hover {
          background-color: rgba($color: $main-color, $alpha: 0.5);
        }
      }
      @media only screen and (max-width: 681px) {
        h1 {
          font-size: 0.7rem !important;
        }
      }
      &.clicked {
        background-color: rgba($color: $main-color, $alpha: 1) !important;
        @media only screen and (min-width: 992px) {
          &:hover {
            h1 {
              opacity: 0 !important;
            }
            &::before {
              opacity: 1 !important;
              transition: 0.3s ease-in;
            }
          }
        }
      }
      h1 {
        font-size: var(--xs-font-size);
        font-family: $font-medium;
        font-weight: 500;
        color: var(--secondary-dark-body-color);
        text-align: center;
        word-break: break-all;
      }
    }
  }
}
.register__select-menu {
  position: relative;

  select {
    appearance: none;
    -webkit-appearance: none;
  }
  &::after {
    position: absolute;
    content: "\25BE";
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 28px;
    opacity: 0.7;
    height: 28px;
    border-radius: 20px;
    @media only screen and (max-width: 681px) {
      transform: translateY(-10%);
    }
  }
}
