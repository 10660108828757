.contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  padding: 120px 0px 0px;
  min-height: 80vh;
  background: url("assets/vectors/bloody_vector_up_blue.svg") repeat
    var(--footer-color);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 800px;
  .contact__container {
    max-width: 1230px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px var(--inside-padding);
    gap: 50px;

    @media only screen and (max-width: 992px) {
      gap: 25px;
      h1,
      h2,
      p {
        text-align: center;
      }
    }
    .contact__desc {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      p {
        font-size: var(--s-font-size);
        color: var(--main-secondary-text-color);
        font-family: $font-bold;
        font-weight: 700;
        line-height: 1.6;
        span {
          font-family: $font-light;
          font-weight: 300;
        }
      }
    }
    .contact__title {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 5px;
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
      }
      h2 {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-text-color);
      }
    }
  }
}
.contact-footer {
  padding-top: 130px;
}
// Contact Section
.contact__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .contact__svg {
    width: 50%;
    padding-bottom: 70px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  @media only screen and (max-width: 992px) {
    justify-content: center !important;
    gap: 30px;
    // flex-wrap: wrap;
    .contact__svg {
      display: none;
      // width: 80% !important;
    }
  }
  .contact__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: clamp(500px, 100%, 200px);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    .number {
      height: 71px;
      width: 100%;
      display: flex;
      border-radius: 5px !important;
      padding: 3px;
      input {
        width: 100%;
        height: 100%;
        border-radius: inherit !important;
        border: none;
        background-color: transparent;
        outline: none;
        color: var(--main-text-color) !important;
        font-size: var(--s-font-size);
        font-weight: 500;
        font-family: $font-light;
        letter-spacing: 1px;
        padding-left: 5px;
        border: 0.5px solid rgba($color: $secondary-dark-color, $alpha: 1);
        &::placeholder {
          color: var(--main-text-color);
          font-size: var(--s-font-size);
          font-weight: 500;
          font-family: $font-light;
          letter-spacing: 0.5px;
          transition: 0.25s all ease-in-out;
        }
        &:focus {
          border: 2px solid var(--secondary-dark-color);
          border-radius: 5px !important;
          padding-left: 10px !important;
          &::placeholder {
            color: var(--main-color);
          }
        }
        @media only screen and (min-width: 992px) {
          &:hover {
            border-radius: 15px !important;
            &::placeholder {
              color: var(--main-color);
              transition: 0.25s all ease-in-out;
            }
            color: var(--main-color);
          }
        }
      }
      select {
        background-color: transparent;
        border: none;
        background-color: #00395e;
        border-radius: 4px;
        color: var(--main-text-color);
        font-family: $font-medium;
        font-weight: 500;
        font-size: var(--s-font-size);
        outline: none;
        padding: 0px 10px;
      }
    }
    .contact__input {
      width: 100%;
      height: 71px;
      border-radius: 5px !important;
      border: none;
      background-color: var(--secondary-text-color);
      outline: none;
      color: var(--main-text-color) !important;
      font-size: var(--s-font-size);
      font-weight: 500;
      font-family: $font-light;
      letter-spacing: 1px;
      padding-left: 5px;
      border: 0.5px solid rgba($color: $secondary-dark-color, $alpha: 1);

      &::placeholder {
        color: var(--main-text-color);
        font-size: var(--s-font-size);
        font-weight: 500;
        font-family: $font-light;
        letter-spacing: 0.5px;
        transition: 0.25s all ease-in-out;
      }
      &:focus {
        border: 2px solid var(--secondary-dark-color);
        border-radius: 5px !important;
        padding-left: 10px !important;
        &::placeholder {
          color: var(--main-color);
        }
      }
      @media only screen and (min-width: 992px) {
        &:hover {
          border-radius: 15px !important;
          &::placeholder {
            color: var(--main-color);
            transition: 0.25s all ease-in-out;
          }
          color: var(--main-color);
        }
      }
    }
    .email {
      border-radius: 20px 20px 5px 5px !important;
    }
    .message {
      transition: none;
      resize: vertical;
      min-height: 220px;
      max-height: 400px;
      padding-top: 7px;
      transition: 0.1s;
    }
    .contact__btn {
      width: 100%;
      border-radius: 5px 5px 20px 20px;
      &::after {
        border-radius: inherit !important;
      }
    }
  }
}
.programs__contact-container {
  .contact__info {
    .contact__inputs {
      .contact__input {
        &::placeholder {
          color: var(--main-text-color);
          font-size: var(--s-font-size);
          font-weight: 500;
          font-family: $font-light;
          letter-spacing: 0.5px;
          transition: 0.25s all ease-in-out;
        }
        &:focus {
          border: 2px solid var(--secondary-dark-color);
          border-radius: 5px !important;
          padding-left: 10px !important;
          &::placeholder {
            color: var(--secondary-light-red);
          }
        }
        @media only screen and (min-width: 992px) {
          &:hover {
            border-radius: 15px !important;
            &::placeholder {
              color: var(--secondary-light-red);
              transition: 0.25s all ease-in-out;
            }
            color: var(--secondary-light-red);
          }
        }
      }
    }
  }
}
.contact__contrast-color {
  background-color: var(--secondary-dark-color) !important;
  border: 0.5px solid var(--secondary-dark-color) !important;
  &:focus {
    border: 3px solid var(--secondary-dark-color) !important;
  }
  &:hover {
    &::placeholder {
      color: var(--main-color) !important;
    }
  }
}
// SVG
.contact__bloody-svg {
  width: 100%;
  height: 110px;
  background-color: var(--secondary-dark-color);
  font-size: 0;
  padding: 0;
  margin: 0;
}
.contact__nav-links {
  li {
    a {
      &::before {
        background-color: var(--secondary-dark-color) !important;
      }
    }
  }
  .active {
    color: var(--secondary-dark-color) !important;
  }
}
