@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.projects__cards-section {
  width: 100%;
  padding: 130px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  .projects__cards-section {
    padding: 30px 0px 80px;
  }
}
.projects__cards-section .projects__cards-container {
  width: 100%;
  min-height: 60vh;
  max-width: 1305px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
}
@media only screen and (max-width: 992px) {
  .projects__cards-section .projects__cards-container {
    gap: 30px;
  }
}
.projects__cards-section .projects__cards-container .projects__cards-title {
  width: 100%;
  position: sticky;
  top: 75px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  background-color: white;
  z-index: 10;
}
@media only screen and (min-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title {
    gap: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title {
    top: 30px !important;
  }
}
.projects__cards-section .projects__cards-container .projects__cards-title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-dark-body-color);
}
.projects__cards-section .projects__cards-container .projects__cards-title ul {
  display: flex;
  gap: 15px;
  padding: 10px 5px 10px;
}
@media only screen and (max-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title ul {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 5px 10px;
  }
  .projects__cards-section .projects__cards-container .projects__cards-title ul li {
    font-size: var(--xs-font-size) !important;
    min-width: unset !important;
    padding: 5px 10px !important;
  }
}
@media only screen and (max-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title ul div {
    border-radius: 100px;
    overflow: hidden;
  }
}
.projects__cards-section .projects__cards-container .projects__cards-title ul div li {
  min-width: 80px;
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-dark-body-color);
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 5px 20px;
  border-radius: 100px;
  background-color: rgba(12, 27, 43, 0.1);
  border: 2px solid var(--secondary-dark-body-color);
  transition: 0.4s;
}
.projects__cards-section .projects__cards-container .projects__cards-title ul div li.active {
  color: var(--main-text-color) !important;
  background-color: #0c1b2b !important;
  padding: 5px 23px !important;
}
@media only screen and (min-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title ul div li.active:hover {
    background-color: #0c1b2b !important;
    border: 2px solid #0c1b2b !important;
  }
  .projects__cards-section .projects__cards-container .projects__cards-title ul div li.active:active {
    color: white !important;
    border-radius: 100px !important;
  }
}
@media only screen and (min-width: 992px) {
  .projects__cards-section .projects__cards-container .projects__cards-title ul div li:hover {
    background-color: rgba(12, 27, 43, 0.4);
    border: 2px solid rgba(12, 27, 43, 0.9);
    transition: 0.4s;
  }
}
.projects__cards-section .projects__cards-container .projects__cards-title ul div li:active {
  color: rgba(12, 27, 43, 0.8);
  border-radius: 18px;
  transition: none;
}
.projects__cards-section .projects__cards-container .projects__cards-wrapper {
  display: grid;
  padding: 0px var(--inside-padding);
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  place-items: center;
  gap: 20px 50px;
}
@media only screen and (max-width: 1300px) {
  .projects__cards-section .projects__cards-container .projects__cards-wrapper {
    max-width: 992px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
  }
}
@media only screen and (max-width: 760px) {
  .projects__cards-section .projects__cards-container .projects__cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 30px;
  }
}

.projects__fullscreen-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -250vw;
  background-color: black;
  transition: 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px var(--inside-padding);
  z-index: 9999;
}
.projects__fullscreen-view .projects__fullscreen-view-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.projects__fullscreen-view .projects__fullscreen-view-container button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.projects__fullscreen-view .projects__fullscreen-view-container button svg {
  color: #fff;
}
@media only screen and (min-width: 992px) {
  .projects__fullscreen-view .projects__fullscreen-view-container button:hover svg {
    color: var(--secondary-color);
  }
}
.projects__fullscreen-view .projects__fullscreen-view-container div {
  width: 100%;
  height: 80%;
  overflow: hidden;
}
.projects__fullscreen-view .projects__fullscreen-view-container div img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.projects__fullscreen-view.true {
  left: 0;
}

.projects {
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-purple-color);
  padding: 125px 0px 0px;
}
@media only screen and (max-width: 992px) {
  .projects {
    min-height: 60vh;
    padding: 135px 0px;
  }
}
.projects .projects__container {
  width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 992px) {
  .projects .projects__container {
    flex-direction: column;
  }
  .projects .projects__container .projects__img {
    display: none !important;
  }
  .projects .projects__container .projects__content {
    align-items: center !important;
  }
  .projects .projects__container .projects__content h1,
  .projects .projects__container .projects__content p {
    text-align: center !important;
  }
}
.projects .projects__container .projects__content {
  flex: 0.5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (max-width: 692px) {
  .projects .projects__container .projects__content h1 {
    font-size: var(--l-font-size) !important;
    text-align: center !important;
  }
  .projects .projects__container .projects__content p {
    font-size: var(--s-font-size) !important;
    text-align: center !important;
  }
}
.projects .projects__container .projects__content h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  text-align: left;
}
.projects .projects__container .projects__content p {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: left;
}
.projects .projects__container .projects__img {
  position: relative;
  flex: 0.5;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects .projects__container .projects__img img {
  width: 100%;
  height: 100%;
  max-height: 380px;
  -o-object-fit: contain;
     object-fit: contain;
}

.projects__waves-svg {
  width: 100%;
  max-height: 108.54px;
}
.projects__waves-svg img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.project__cards {
  max-width: 400px;
  height: 400px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.project__cards::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
  border-radius: inherit;
  transition: 0.2s ease-in;
}
@media only screen and (max-width: 440px) {
  .project__cards {
    height: 350px;
  }
}
@media only screen and (max-width: 992px) {
  .project__cards::before {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .project__cards .project__info {
    height: 100% !important;
    border-radius: 10px !important;
  }
  .project__cards .project__info .project__info-fullscreen {
    opacity: 1 !important;
  }
}
@media only screen and (min-width: 992px) {
  .project__cards:hover::before {
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.2s ease-in;
  }
  .project__cards:hover .project__info {
    height: 100%;
    transition: 0.25s all ease-in-out;
    border-radius: inherit;
  }
  .project__cards:hover .project__info .project__info-fullscreen {
    opacity: 1;
    transition-delay: 0.15s;
  }
  .project__cards:hover p {
    -webkit-line-clamp: 7 !important; /* number of lines to show */
    line-clamp: 7 !important;
  }
  .project__cards:hover .project__img {
    filter: blur(2px);
  }
}
.project__cards .project__img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  filter: blur(0px);
}
.project__cards .project__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.project__cards .project__info {
  width: 100%;
  height: 170px;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  padding: 15px 25px;
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.project__cards .project__info .project__info-content {
  width: 100%;
}
.project__cards .project__info .project__info-content h1 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
}
.project__cards .project__info .project__info-content p {
  font-size: var(--s-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.project__cards .project__info .project__info-content h2 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--main-text-color);
}
.project__cards .project__info .project__info-content span {
  font-size: var(--s-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.project__cards .project__info .project__info-fullscreen {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
@media only screen and (max-width: 440px) {
  .project__cards .project__info .project__info-fullscreen {
    width: 55px !important;
    height: 55px !important;
  }
}
@media only screen and (max-width: 992px) {
  .project__cards .project__info .project__info-fullscreen {
    width: 70px;
    height: 70px;
  }
}
.project__cards .project__info .project__info-fullscreen svg {
  cursor: pointer;
  transition: 0.1s ease-in;
}
@media only screen and (min-width: 992px) {
  .project__cards .project__info .project__info-fullscreen:hover svg {
    fill: var(--secondary-color);
    transition: 0.1s ease-in;
  }
  .project__cards .project__info .project__info-fullscreen:active svg {
    fill: #da9604;
    transition: 0.1s ease-in;
  }
  .project__cards .project__info .project__info-fullscreen:focus svg {
    fill: var(--secondary-color);
    transition: 0.1s ease-in;
  }
}
.project__cards .project__info .project__link.disabled {
  pointer-events: none !important;
  cursor: pointer !important;
}
.project__cards .project__info .project__link.disabled svg {
  pointer-events: none !important;
  cursor: pointer !important;
  color: var(--disabled-color) !important;
}
.project__cards .project__info .project__link svg {
  color: var(--secondary-color);
  cursor: pointer;
}
.project__cards .project__info .project__link svg:hover {
  opacity: 0.5;
}
.project__cards .project__info .project__link svg:focus {
  opacity: 0.7;
}
.project__cards .project__info .project__link svg:active {
  opacity: 0.5;
}/*# sourceMappingURL=projectStyle.css.map */