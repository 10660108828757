.footer {
  position: relative;
  width: 100%;
  padding: 250px 0px 10px;
  // background-image: url("assets/vectors/bloody_vector_down.svg");
  // background-repeat: repeat-x;
  // background-size: auto;
  // background-position: bottom;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--footer-color);
  z-index: 0 !important;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  //   flex: 1;
  //   z-index: -1;
  //   background-image: linear-gradient(
  //     146deg,
  //     rgba(var(--footer-color), 0.4) 60%,
  //     rgba(var(--secondary-color), 0.4) 100%
  //   );
  //   background-size: 200% 200%;
  //   background-position: top left;
  //   animation: footerBreathingAnim 10s ease-in infinite;
  // }
  @media only screen and (max-width: 992px) {
    padding-top: 17vw;
  }

  .footer__wrapper {
    width: 100%;
    max-width: 1516px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 0px 20px;
    @media only screen and (max-width: 992px) {
      gap: 50px;
    }
    .working__info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 5px;
      h3 {
        font-size: var(--m-font-size);
        font-weight: 500;
        font-family: $font-medium;
        color: var(--main-text-color);
      }
      p {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-secondary-text-color);
      }
    }
    .working__days {
      text-align: center;
      p {
        span {
          margin-bottom: 10px;
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          &:first-child {
            &::after {
              content: "";
              position: absolute;
              bottom: -5px;
              background-color: rgba($color: #fff, $alpha: 0.8);
              height: 2px;
              width: 68%;
              border-radius: 10px;
            }
          }
        }
      }
    }
    .footer__title {
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--main-text-color);
        span {
          color: var(--main-color);
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold;
        }
      }
    }
    .footer__places {
      width: 100%;
      // display: -webkit-box;
      // display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 65px;
      @media only screen and (min-width: 1190px) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        place-content: center;
        place-items: center;
      }
      @media only screen and (max-width: 590px) {
        width: 100%;
        align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        flex-direction: column;
      }
      @media only screen and (max-width: 992px) {
        gap: 35px;
      }
      .footer__place-card {
        max-width: 253px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        @media only screen and (min-width: 590px) {
          min-height: 290px;
        }
        @media only screen and (max-width: 992px) {
          gap: 5px;
          // max-width: 300px;
        }
        @media only screen and (max-width: 590px) {
          max-width: 100%;
        }
        @media only screen and (max-width: 500px) {
          h2 {
            font-size: var(--l-font-size) !important;
          }
          h3,
          p {
            font-size: var(--m-font-size) !important;
          }
        }
        h2 {
          font-size: var(--l-font-size);
          font-family: $font-medium;
          font-weight: 500;
          color: var(--main-color);
        }
      }
    }
    .footer__info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 50px;
      .footer__info-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        .footer__nav {
          ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            gap: 10px 30px;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            a {
              font-size: var(--m-font-size);
              position: relative;
              font-weight: 300;
              font-family: $font-light;
              color: var(--main-text-color);
              @media only screen and (max-width: "500px") {
                font-size: var(--s-font-size) !important;
              }
              @media only screen and (min-width: "992px") {
                &:hover {
                  color: var(--main-color);
                  &::before {
                    width: 100%;
                  }
                }
              }
              &::before {
                content: "";
                background-color: var(--main-color);
                position: absolute;
                width: 0%;
                height: 1.5px;
                bottom: 0;
                transition: 0.35s all ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}

// .footer__animation {
//   background-image: linear-gradient(
//     146deg,
//     rgba($main-color, 0.001) 75%,
//     rgba($main-color, 0.2) 100%
//   );
//   background-size: 200% 200%;
//   background-position: top left;
//   animation: footerBreathingAnim 7s ease-in-out infinite;
// }
// @keyframes footerBreathingAnim {
//   0% {
//     background-position: top left;
//   }
//   30% {
//     background-position: bottom right;
//   }
//   100% {
//     background-position: top left;
//   }
// }

// Footer SVG Colors
.footer__vector-hidden {
  display: none;
  background-image: none;
}
.footer__vector-up {
  position: absolute;
  top: -0.9px;
  width: 100%;
  height: 182px;
}
.footer__vector-yellow {
  background-image: url("assets/vectors/bloody_vector_up_yellow.svg");
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-white {
  position: absolute;
  width: 100%;
  height: 182px;
  background-image: url("assets/vectors/bloody_vector_up_white.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-secondWhite {
  background-image: url("assets/vectors/bloody_vector_up_secondWhite.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-secondWhite2 {
  background-image: url("assets/vectors/bloody_vector_up_secondWhite2.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-blue {
  background-image: url("assets/vectors/bloody_vector_up_blue.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-purple {
  background-image: url("assets/vectors/bloody_vector_up_purple.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
.footer__vector-red {
  background-image: url("assets/vectors/bloody_vector_up_red.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}
