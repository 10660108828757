.article__use {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 50px;
  background-color: #fff;
  overflow-x: hidden;
  .article__use-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px var(--inside-padding);
    .article__use-content {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      justify-content: center;
      @media only screen and (max-width: 992px) {
        h1 {
          font-size: var(--l-font-size) !important;
        }
        p {
          font-size: var(--s-font-size) !important;
        }
      }
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-color);
        font-weight: 700;
        font-family: $font-bold;
      }
      p {
        font-size: var(--m-font-size);
        color: var(--secondary-text-color);
        font-weight: 500;
        font-family: $font-medium;
      }
    }
    .article__use-carousel {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      .article__carousel {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 30px 0px;
        @media only screen and (max-width: 992px) {
          padding: 30px 0px 70px;
        }
        .carousel__img {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0px 50px;
          @media only screen and (max-width: 992px) {
            padding: 0px;
          }
          div {
            padding: 10px 25px 50px;
            width: 95%;
            height: 100%;
            overflow: hidden;
            max-width: 990px;
            background-color: #313338;
            max-height: 426px;
            border-radius: 15px;
            @media only screen and (max-width: 992px) {
              width: 100%;
            }
            img {
              width: 100%;
              aspect-ratio: 2.5;
              object-fit: contain;
              @media only screen and (max-width: 992px) {
                object-fit: fill;
                aspect-ratio: unset;
              }
            }
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          background-color: #313338;
          width: 64px;
          height: 64px;
          border-radius: 50px;
          @media only screen and (max-width: 992px) {
            top: unset !important;
            bottom: 0 !important;
          }
        }
        .swiper-button-prev {
          left: 0;
          &::after {
            content: "";
            background: url("assets/images/icons/angle_left_white.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            align-self: center;
          }
        }
        .swiper-button-next {
          right: 0;
          &::after {
            content: "";
            background: url("assets/images/icons/angle_right_white.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            align-self: center;
          }
        }
        .swiper-pagination {
          bottom: 0;
          .swiper-pagination-bullet {
            width: 10px !important;
            height: 10px !important;
            opacity: 0.2 !important;
            &.swiper-pagination-bullet-active {
              color: var(--main-color) !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
}
