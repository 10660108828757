.about__separator {
  width: 100%;
  padding: 130px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-purple-color);
  @media only screen and (max-width: 992px) {
    padding: 90px 0px;
    h1 {
      font-size: var(--l-font-size) !important;
    }
    p {
      font-size: var(--s-font-size) !important;
    }
  }
  .about__separator-container {
    max-width: 987px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 0px var(--inside-padding);
    h1 {
      font-size: var(--xl-font-size);
      font-weight: 700;
      font-family: $font-bold;
      color: var(--main-text-color);
      text-align: center;
      span {
        color: var(--secondary-light-red);
        font-weight: 700;
        font-family: $font-bold;
      }
    }
    p {
      font-size: var(--m-font-size);
      font-weight: 300;
      font-family: $font-light;
      color: var(--main-text-color);
      text-align: center;
    }
  }
}
