.projects__cards-section {
  width: 100%;
  padding: 130px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    padding: 30px 0px 80px;
  }
  .projects__cards-container {
    width: 100%;
    min-height: 60vh;
    max-width: 1305px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
    @media only screen and (max-width: 992px) {
      gap: 30px;
    }
    .projects__cards-title {
      width: 100%;
      position: sticky;
      top: 75px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      gap: 0px;
      background-color: rgba($color: #ffffff, $alpha: 1);
      z-index: 10;
      @media only screen and (min-width: 992px) {
        gap: 10px;
      }
      @media only screen and (max-width: 992px) {
        top: 30px !important;
      }
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--secondary-dark-body-color);
      }
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 15px;
        padding: 10px 5px 10px;
        @media only screen and (max-width: 992px) {
          flex-wrap: wrap;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 5px;
          padding: 10px 5px 10px;

          li {
            font-size: var(--xs-font-size) !important;
            min-width: unset !important;
            padding: 5px 10px !important;
          }
        }
        div {
          @media only screen and (max-width: 992px) {
            border-radius: 100px;
            overflow: hidden;
          }
          li {
            min-width: 80px;
            font-size: var(--m-font-size);
            font-family: $font-medium;
            font-weight: 500;
            color: var(--secondary-dark-body-color);
            // text-decoration: underline solid 2px var(--secondary-color);
            cursor: pointer;
            text-align: center;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: 5px 20px;
            border-radius: 100px;
            background-color: rgba(
              $color: $secondary-dark-body-color,
              $alpha: 0.1
            );
            border: 2px solid var(--secondary-dark-body-color);
            transition: 0.4s;
            &.active {
              color: var(--main-text-color) !important;
              background-color: rgba(
                $color: $secondary-dark-body-color,
                $alpha: 1
              ) !important;
              padding: 5px 23px !important;
              @media only screen and (min-width: 992px) {
                &:hover {
                  background-color: rgba(
                    $color: $secondary-dark-body-color,
                    $alpha: 1
                  ) !important;
                  border: 2px solid
                    rgba($color: $secondary-dark-body-color, $alpha: 1) !important;
                }
                &:active {
                  color: rgba($color: $main-text-color, $alpha: 1) !important;
                  border-radius: 100px !important;
                }
              }
            }
            @media only screen and (min-width: 992px) {
              &:hover {
                background-color: rgba(
                  $color: $secondary-dark-body-color,
                  $alpha: 0.4
                );
                border: 2px solid
                  rgba($color: $secondary-dark-body-color, $alpha: 0.9);
                transition: 0.4s;
                // color: var(--main-color);
                // text-decoration: underline solid 2px var(--main-color);
              }
            }

            &:active {
              color: rgba($color: $secondary-dark-body-color, $alpha: 0.8);
              border-radius: 18px;
              transition: none;
            }
          }
        }
      }
    }
    .projects__cards-wrapper {
      display: grid;
      padding: 0px var(--inside-padding);
      grid-template-columns: repeat(3, 1fr);
      place-content: center;
      place-items: center;
      gap: 20px 50px;
      @media only screen and (max-width: 1300px) {
        max-width: 992px;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 30px;
      }
      @media only screen and (max-width: 760px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px 30px;
      }
    }
  }
}

.projects__fullscreen-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -250vw;
  background-color: rgba($color: #000000, $alpha: 1);
  transition: 0.5s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px var(--inside-padding);
  z-index: 9999;
  .projects__fullscreen-view-container {
    flex: 1;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background-color: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #fff;
      }
      @media only screen and (min-width: 992px) {
        &:hover {
          svg {
            color: var(--secondary-color);
          }
        }
      }
    }
    div {
      width: 100%;
      height: 80%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.projects__fullscreen-view.true {
  left: 0;
}
