.home__programs {
  width: 100%;
  padding: 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--main-secondary-text-color-2);
  .home__programs-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 100px;
    padding: 0px var(--inside-padding);

    .home__programs-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      gap: 25px;
      .home__programs-title {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 5px;
        h1 {
          font-size: var(--xl-font-size);
          font-family: $font-bold;
          font-weight: 700;
          color: var(--secondary-text-color);
        }
      }
    }
  }
  .home__programs-container {
    width: 100%;
    display: flex;
    gap: 30px 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    //-- Webkit --//
    -webkit-gap: 30px 20px;
    -moz-gap: 30px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
  }
}

// Card Design
.home__programs-card {
  position: relative;
  max-width: 330px;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  border: 0.5px solid transparent;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  background-color: var(--main-text-color);
  box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.02),
    1px 37px 24px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba($color: #fff, $alpha: 0.5);
  @media only screen and (max-width: 600px) {
    min-height: 350px;
    height: unset;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  &.yellow-theme {
    &:hover {
      background-color: var(--secondary-color);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        h2 {
          color: var(--main-text-color) !important;
          &::after {
            background-color: var(--secondary-purple-color);
          }
        }
      }
      button {
        background-color: var(--secondary-purple-color) !important;
      }
    }
  }
  &.purple-theme {
    &:hover {
      background-color: var(--secondary-purple-color);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        h1 {
          color: var(--main-text-color) !important;
        }
        h2 {
          color: var(--main-text-color) !important;
          &::after {
            background-color: var(--secondary-light-green-color);
          }
        }
      }
      button {
        background-color: var(--secondary-light-green-color) !important;
      }
    }
  }
  &.darkBlue-theme {
    &:hover {
      background-color: var(--secondary-dark-body-color);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        h2 {
          color: var(--main-text-color) !important;
          &::after {
            background-color: var(--main-color);
          }
        }
      }
      button {
        background-color: var(--main-color) !important;
      }
    }
  }
  &.blue-theme {
    &:hover {
      background-color: var(--footer-color);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        h1 {
          color: var(--main-text-color) !important;
        }
        h2 {
          color: var(--main-text-color) !important;
          &::after {
            background-color: var(--secondary-light-red);
          }
        }
      }
      button {
        background-color: var(--secondary-light-red) !important;
      }
    }
  }
  &.red-theme {
    &:hover {
      background-color: var(--secondary-light-red);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        h1 {
          color: var(--main-text-color) !important;
        }
        h2 {
          color: var(--main-text-color) !important;
          &::after {
            background-color: var(--secondary-color);
          }
        }
      }
      button {
        background-color: var(--secondary-color) !important;
      }
    }
  }
  &.lightBlue-theme {
    &:hover {
      background-color: var(--main-color);
      .home__programs-content-titles {
        .home__programs-subtitle {
          border: solid 1px var(--main-text-color) !important;
          h2 {
            color: var(--main-text-color) !important;
          }
        }
        span {
          h1 {
            color: var(--main-text-color) !important;
          }
          h2 {
            color: var(--main-text-color) !important;
            &::after {
              background-color: var(--secondary-light-red);
            }
          }
        }
      }
      button {
        background-color: var(--secondary-light-red) !important;
      }
    }
  }
  // &.purple-theme {
  // }
  // &.lightBlue-theme {
  // }
  .home__programs-bgImg {
    position: absolute;
    max-width: 170px;
    width: 100%;
    overflow: hidden;
    bottom: -60%;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .home__programs-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    // flex: 0.5;
    width: 100%;
    height: 50%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    z-index: 1;
    @media only screen and (max-width: 600px) {
      height: 100%;
      max-height: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .home__programs-content-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // flex: 0.4;
    width: 100%;
    height: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 30px;
    border: 0px 0px 20px 20px;
    padding: 0px 20px 15px;
    z-index: 1;
    .home__programs-content-titles {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      height: 100%;
      .summer__program-title {
        font-size: var(--m-font-size);
        font-family: $font-medium;
        font-weight: 500;
        color: var(--secondary-dark-body-color);
        text-align: center;
        text-transform: none;
      }
      .home__programs-subtitle {
        padding: 7px 10px;
        // background-color: rgba($color: $secondary-color, $alpha: 0.3);
        border-radius: 50px;
        border: solid 1px var(--secondary-dark-body-color);
        h2 {
          font-size: var(--xs-font-size);
          font-family: $font-medium;
          color: var(--secondary-dark-body-color);
          font-weight: 500;
          letter-spacing: 0.25px;
        }
      }
      span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 3px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: var(--m-font-size);
          font-family: $font-medium;
          font-weight: 500;
          color: var(--secondary-dark-body-color);
          text-align: center;
        }
        h2 {
          position: relative;
          font-size: var(--m-font-size);
          font-family: $font-medium;
          font-weight: 500;
          color: var(--secondary-dark-body-color);
          text-align: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          &::after {
            position: absolute;
            content: "";
            bottom: -5px;
            height: 2px;
            width: 80%;
            background-color: var(--secondary-purple-color);
            border-radius: 20px;
          }
        }
      }
    }

    a {
      button {
        width: 180px;
        height: 45px;
        font-size: var(--s-font-size) !important;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    &:hover {
      padding: 15px;
      box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.07),
        1px 37px 24px rgba(0, 0, 0, 0.14);
      background-color: var(--secondary-dark-body-color);
      border: 1px solid rgba($color: #fff, $alpha: 0.1);

      .home__programs-content-card {
        padding: 0px 0px 10px;
        .home__programs-subtitle {
          padding: 5px 15px;
        }
      }
      .home__programs-img {
        border-radius: 10px 10px 5px 5px;
      }
      .home__programs-bgImg {
        bottom: -40%;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    &:hover {
      padding: 15px;
      box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.07),
        1px 37px 24px rgba(0, 0, 0, 0.14);
      background-color: var(--footer-color);

      border: 1px solid rgba($color: #fff, $alpha: 0.1);
      .home__programs-img {
        border-radius: 10px 10px 5px 5px;
      }
    }
  }
}

// Wavy separator design
.wavy-svg {
  width: 100%;
  height: 190px;
  @media only screen and (max-width: 692px) {
    height: 100px;
  }
  img {
    width: 100%;
    height: 130%;
    object-fit: cover;
  }
}
.bloody-svg {
  width: 100%;
  // position: relative;
  height: 182px;
  background-image: url("assets/vectors/bloody_vector_down_blue.svg") !important;
  background-repeat: repeat-x;
  background-size: 1260px;
  background-position: bottom;
  div {
    // position: absolute;

    // width: 100%;
    // height: 100%;
    top: 0;
  }
}
// p {
//   font-size: var(--xs-font-size);
//   font-family: $font-light;
//   color: var(--main-text-color);
//   font-weight: 300;
//   text-align: center;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 5; /* number of lines to show */
//   line-clamp: 5;
//   -webkit-box-orient: vertical;
// }
