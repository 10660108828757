@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.media {
  width: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  padding-top: 105px;
}
@media only screen and (max-width: 992px) {
  .media {
    padding-top: 135px;
  }
}
.media .media__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px var(--inside-padding);
  gap: 20px;
}
@media only screen and (max-width: 992px) {
  .media .media__container {
    flex-direction: column;
    justify-content: center;
  }
  .media .media__container .media__info {
    max-width: 80% !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .media .media__container .media__info h1,
  .media .media__container .media__info p {
    text-align: center !important;
  }
  .media .media__container .media__img {
    max-width: 450px !important;
  }
}
.media .media__container .media__info {
  max-width: 40%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 500px) {
  .media .media__container .media__info {
    max-width: 100% !important;
  }
}
.media .media__container .media__info h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  color: var(--secondary-color);
  text-align: left;
  font-weight: 700;
}
.media .media__container .media__info span {
  width: 40%;
  height: 2px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.media .media__container .media__info p {
  font-size: var(--s-font-size);
  font-family: "Poppins-Light";
  color: var(--main-secondary-text-color);
  font-weight: 300;
  text-align: left;
}
.media .media__container .media__img {
  max-width: 600px;
  overflow: hidden;
}
.media .media__container .media__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.media__cards-section {
  width: 100%;
  padding: 130px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color);
}
@media only screen and (max-width: 992px) {
  .media__cards-section {
    padding: 50px 0px 130px;
  }
}
.media__cards-section .media__cards-wrapper {
  width: 100%;
  padding: 0px var(--inside-padding);
  display: grid;
  gap: 30px 20px;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  place-content: center;
}
@media only screen and (min-width: 992px) {
  .media__cards-section .media__cards-wrapper {
    align-items: flex-start;
    justify-content: center;
  }
}
@media only screen and (max-width: 992px) {
  .media__cards-section .media__cards-wrapper {
    max-width: 800px !important;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 550px) {
  .media__cards-section .media__cards-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.media__cards {
  max-width: 355px;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--footer-color);
  outline: 2px solid #40b1e5;
  border-radius: 15px 15px 9px 9px;
  overflow: hidden;
  padding-bottom: 10px;
  transition: 0.5s ease-in;
}
@media only screen and (max-width: 550px) {
  .media__cards {
    min-height: 450px;
  }
  .media__cards .media__cards-img {
    min-height: 130px !important;
    max-height: 130px !important;
    height: 130px !important;
  }
}
@media only screen and (max-width: 992px) {
  .media__cards {
    outline: 2px solid rgba(64, 177, 229, 0);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    padding-bottom: 0px;
  }
  .media__cards .media__cards-content {
    padding: 0px !important;
  }
  .media__cards .media__cards-content h1 {
    color: var(--secondary-light-red) !important;
  }
  .media__cards .media__cards-content p {
    max-height: 100% !important;
    line-clamp: 7 !important;
    -webkit-line-clamp: 7 !important;
  }
  .media__cards .media__cards-content span {
    width: 20% !important;
    background-color: var(--secondary-light-red) !important;
  }
  .media__cards .media__cards-container {
    padding: 10px !important;
  }
  .media__cards .media__cards-container .media__cards-button {
    bottom: 15px !important;
  }
  .media__cards .media__cards-container .media__cards-button h2 {
    color: var(--secondary-light-red) !important;
    font-weight: bold !important;
  }
  .media__cards .media__cards-container .media__cards-button h2::after {
    background-color: var(--secondary-light-red) !important;
    transition: 0.2ms;
  }
  .media__cards .media__cards-container .media__cards-button a {
    background-color: #f15b5b !important;
  }
  .media__cards .media__cards-container .media__cards-img {
    outline: solid 2px #f15b5b !important;
    border-radius: 20px 20px 150px 15px !important;
    min-height: 30% !important;
    max-height: 30% !important;
    height: 30% !important;
  }
}
@media only screen and (min-width: 992px) {
  .media__cards:hover {
    outline: 2px solid rgba(64, 177, 229, 0);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    padding-bottom: 0px;
  }
  .media__cards:hover .media__cards-content {
    padding: 0px !important;
  }
  .media__cards:hover .media__cards-content h1 {
    color: var(--secondary-light-red) !important;
  }
  .media__cards:hover .media__cards-content p {
    height: 150px;
    line-clamp: 7 !important;
    -webkit-line-clamp: 7 !important;
  }
  .media__cards:hover .media__cards-content span {
    width: 20% !important;
    background-color: var(--secondary-light-red) !important;
  }
  .media__cards:hover .media__cards-container {
    padding: 10px !important;
    height: 100%;
  }
  .media__cards:hover .media__cards-container .media__cards-button {
    bottom: 15px;
    left: 10px !important;
  }
  .media__cards:hover .media__cards-container .media__cards-button h2 {
    color: var(--secondary-light-red) !important;
    font-weight: bold !important;
  }
  .media__cards:hover .media__cards-container .media__cards-button h2::after {
    background-color: var(--secondary-light-red) !important;
    transition: 0.2ms;
  }
  .media__cards:hover .media__cards-container .media__cards-button a {
    background-color: #f15b5b !important;
  }
  .media__cards:hover .media__cards-container .media__cards-img {
    outline: solid 2px #f15b5b !important;
    border-radius: 20px 20px 150px 15px !important;
    min-height: 30% !important;
    max-height: 30% !important;
    height: 30% !important;
  }
}
.media__cards .media__cards-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 0px;
}
.media__cards .media__cards-container .media__cards-button {
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.media__cards .media__cards-container .media__cards-button h2 {
  position: relative;
  color: var(--main-secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
  font-size: var(--xs-font-size);
  padding: 0px 2.5px;
}
.media__cards .media__cards-container .media__cards-button h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--main-color);
  height: 2px;
  border-radius: 5px;
  transition: 0.2ms;
}
.media__cards .media__cards-container .media__cards-button a {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: var(--xs-font-size);
  font-family: "Poppins-Light";
  cursor: pointer;
  border-radius: 15px;
}
.media__cards .media__cards-container .media__cards-button a:hover {
  padding: 10px 15px !important;
}
.media__cards .media__cards-img {
  width: 100%;
  height: 100%;
  min-height: 45%;
  max-height: 45%;
  overflow: hidden;
  outline: solid 2px rgba(241, 91, 91, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.media__cards .media__cards-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.media__cards .media__cards-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px;
}
.media__cards .media__cards-content h1 {
  font-size: 0.97rem;
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: #40b1e5;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.media__cards .media__cards-content span {
  width: 80%;
  background-color: #40b1e5;
  height: 2px;
  border-radius: 20px;
}
.media__cards .media__cards-content p {
  font-size: var(--xs-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
  overflow-y: hidden;
  height: 107px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}/*# sourceMappingURL=mediaStyle.css.map */