.info__header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 7px var(--inside-padding);
  background-color: var(--secondary-dark-color);
  @media only screen and (max-width: 992px) {
    display: none;
  }
  &-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
  }
  &-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
      font-size: var(--xs-font-size);
      font-family: $font-light;
      font-weight: 300;
      color: var(--main-text-color);
    }
  }
}
