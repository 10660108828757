* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: 0.25s all ease-in-out;
}
body {
  background-color: var(--main-text-color);
  overflow-x: hidden;
}

#root {
  // overflow-x: hidden !important; // Problems with sticky position
}

.container {
  // width: min(100% - var(--inside-padding), 1075px) !important;
  max-width: 1075px !important;
}

.display-none {
  display: none !important;
}

//  SCROLL BAR
::-webkit-scrollbar {
  background: var(--secondary-text-color);
  width: 0.6rem;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-text-color);
}

// Text Selection
::-moz-selection {
  /* Code for Firefox */
  color: var(--main-text-color);
  background: #619da1;
}

::selection {
  color: var(--main-text-color);
  background: #619da1;
}
