.about__success {
  width: 100%;
  padding: 70px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-x: hidden;
}
.about__success-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px var(--inside-padding);
  overflow: hidden;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  //   width: 100px;
  //   height: 100%;
  //   z-index: 5;
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   width: 100px;
  //   height: 100%;
  //   background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
  //   z-index: 5;
  // }
  // @media only screen and (max-width: 992px) {
  //   &::before,
  //   &::after {
  //     display: none;
  //   }
  // }
  .about__success-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .success__carousel {
      .swiper-wrapper {
        // display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        // padding: 50px 0px;
        // display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        // justify-content: center;
        // -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        // gap: 30px;
        // .swiper-slide {
        //   display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        //   -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        //   justify-content: center;
        // }
      }
      // .swiper-slide {
      //   opacity: 0 !important;
      //   transition: opacity 0.5s ease-in-out;
      //   transform: none !important;
      //   &.swiper-slide-active {
      //     opacity: 1 !important;
      //     // transition: 0.6s ease-in-out !important;
      //   }
      // }
      .swiper-pagination {
        margin-top: 20px;
        @media only screen and (max-width: 992px) {
          margin-top: 0px;
        }
        .swiper-pagination-bullet {
          width: 10px !important;
          height: 10px !important;
          opacity: 0.2;
          &-active {
            background-color: var(--main-color) !important;
            width: 10px !important;
            height: 10px !important;
            opacity: 1;
          }
        }
      }
      .swiper-button-next {
        width: 64px;
        height: 64px;
        background-color: rgba($color: $secondary-dark-body-color, $alpha: 0.8);
        border-radius: 50px;
        right: 0;
        z-index: 999 !important;
        &:hover {
          background-color: rgba($color: $secondary-dark-body-color, $alpha: 1);
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-image: url("assets/images/icons/angle_right.png");
          background-repeat: no-repeat;
          background-size: 13px;
          background-position: center;
        }
        @media only screen and (max-width: 992px) {
          bottom: 0 !important;
          top: unset !important;
        }
      }
      .swiper-button-prev {
        width: 64px;
        height: 64px;
        background-color: rgba($color: $secondary-dark-body-color, $alpha: 0.8);
        border-radius: 50px;
        left: 0;
        z-index: 999 !important;
        &:hover {
          background-color: rgba($color: $secondary-dark-body-color, $alpha: 1);
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-image: url("assets/images/icons/angle_left.png");
          background-repeat: no-repeat;
          background-size: 13px;
          background-position: center;
        }
        @media only screen and (max-width: 992px) {
          bottom: 0 !important;
          top: unset !important;
        }
      }
    }
  }
  .about__success-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    @media only screen and (max-width: 650px) {
      h2 {
        max-width: 100% !important;
      }
    }
    h1 {
      font-size: var(--xl-font-size);
      font-family: $font-bold;
      font-weight: 700;
      color: var(--secondary-text-color);
    }
    h2 {
      width: 100%;
      max-width: 60%;
      font-size: var(--m-font-size);
      font-family: $font-medium;
      font-weight: 500;
      color: var(--secondary-text-color);
    }
  }
}

.success__section-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  .success__section-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-grow: 1;
    .success__timeline {
      position: absolute;
      top: 4%;
      left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 3px;
      height: calc(100% - 100px);
      span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 3px;
        height: 100%;
        background-color: var(--secondary-purple-color);
        border-radius: 5px;
      }
    }
    .success__section-content {
      width: 100%;
      padding: 30px 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 70px;
      flex-direction: column;
      @media only screen and (max-width: 992px) {
        gap: 35px;
      }
      .success__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        @media only screen and (max-width: 992px) {
          align-items: flex-start;
          .item__content-img,
          .item__date-img {
            display: none !important;
          }
          .item__date {
            max-width: 15px !important;
            transform: translateY(80px) !important;
            h3 {
              display: none;
            }
          }
          .item__card {
            max-width: max-content !important;
            flex-direction: column !important;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            &::after {
              display: none;
            }
            @media only screen and (max-width: 700px) {
              .item__content {
                max-width: 100% !important;
              }
              width: 100% !important;
              max-width: 100% !important;
            }
            .item__img {
              height: 130px !important;
              width: 210px !important;
              align-self: center !important;
              transform: translateY(-10px);
              @media only screen and (max-width: 700px) {
                width: 70% !important;
                height: 140px !important;
              }
            }
          }
        }
        .item__date {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          z-index: 1;
          // max-width: 300px;
          width: 100%;
          span {
            width: 100%;
            min-width: 15px;
            background-color: var(--secondary-purple-color);
            height: 3px;
            border-radius: 5px 0px 0px 5px;
            z-index: 1;
          }
          h3 {
            font-size: var(--xs-font-size);
            color: var(--secondary-subtitle-text-color);
            font-family: $font-medium;
            font-weight: 500;
            z-index: 1;
            padding-left: 10px;
            word-wrap: break-word;
          }
          .item__date-img {
            max-width: 150px;
            max-height: 112px;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-1%, -30%);
            overflow: hidden;
            z-index: -1;
            opacity: 0.4;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .item__card {
          position: relative;
          // max-width: 500px;
          // width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          transition: 0.4s;
          z-index: 5;
          &::after {
            content: "";
            position: absolute;
            bottom: -20%;
            left: 0;
            right: 0;
            height: 20px;
            width: 105px;
            margin-left: auto;
            margin-right: auto;
            background: url("assets/images/illustrations/effect_element.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            overflow: hidden;
            opacity: 0.5;
          }
          h3 {
            @media only screen and (min-width: 992px) {
              display: none;
            }
            font-size: var(--xs-font-size);
            color: var(--secondary-subtitle-text-color);
            font-family: $font-medium;
            font-weight: 500;
            word-wrap: break-word;
          }
          @media only screen and (min-width: 992px) {
            &:hover {
              .item__img {
                &::after {
                  backdrop-filter: blur(0px) !important;
                }
              }
              .item__content {
                border-radius: 10px !important;
              }
              transform: scale(1.01);
            }
          }

          .item__content {
            position: relative;
            max-width: 330px;
            width: 100%;
            padding: 30px 24px;
            border-radius: 15px;
            box-shadow: 2px 7px 11px 1px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            // background-image: linear-gradient(
            //   to right,
            //   $secondary-purple-color 46%,
            //   $secondary-light-red 83%,
            //   $secondary-light-red 100%
            // );
            h1 {
              font-size: var(--xs-font-size);
              color: var(--main-text-color);
              font-family: $font-bold;
              font-weight: 700;
              letter-spacing: 0.035rem;
              text-align: left;
            }
            p {
              font-size: var(--xs-font-size);
              color: var(--main-text-color);
              font-family: $font-light;
              font-weight: 300;
              letter-spacing: 0.02rem;
            }
          }
          .item__img {
            position: relative;
            width: 180px;
            height: 180px;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            z-index: 1;
            box-shadow: -3px 0 6px 1px rgba(0, 0, 0, 0.25);
            @media only screen and (min-width: 992px) {
              transform: translateX(-10px);
            }
            @media only screen and (max-width: 400px) {
              width: 90% !important;
            }
            // &::after {
            //   content: "";
            //   position: absolute;
            //   top: 0;
            //   bottom: 0;
            //   right: 0;
            //   left: 0;
            //   background-color: rgba($color: #000000, $alpha: 0.4);
            //   transition: 0.4s;
            //   // @media only screen and (min-width: 992px) {
            //   //   backdrop-filter: blur(0.5px);
            //   // }
            // }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          // .item__content-img {
          //   position: absolute;
          //   bottom: -15%;
          //   left: 25%;
          //   width: 100%;
          //   height: 100%;
          //   max-width: 105px;
          //   max-height: 50px;
          //   overflow: hidden;
          //   opacity: 0.5;
          //   img {
          //     width: 100%;
          //     height: 100%;
          //     object-fit: contain;
          //   }
          // }
        }
      }
    }
  }
}
