.home__partners {
  width: 100%;
  padding: 100px 0px 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  .home__partners-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 0px var(--inside-padding);
    .home__partners-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--secondary-text-color);
        text-align: center;
      }
      h2 {
        font-size: var(--l-font-size);
        font-weight: 500;
        font-family: $font-medium;
        color: var(--secondary-text-color);
        text-align: center;
      }
    }
    .home__partners-content {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 60px;
      .simple-pagination-swiper {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        .swiper-pagination {
          display: inline-block;
          position: unset;
        }
        .swiper-pagination {
          .swiper-pagination-bullet-active {
            background: var(--main-color) !important;
            background-color: var(--main-color) !important;
          }
          .swiper-pagination-bullet {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
      .home__partners-img {
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        max-width: 200px;
        min-width: 200px;
        height: 120px;
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          // height: 100%;
          aspect-ratio: 3/2;
          object-fit: contain;
          // @media only screen and (min-width: 992px) {
          //   filter: grayscale(100);
          //   &:hover {
          //     filter: grayscale(0);
          //   }
          // }
        }
      }
    }
  }
}
