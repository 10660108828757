.about__objectives-edu {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  background-color: var(--secondary-dark-color);
  @media only screen and (max-width: 692px) {
    padding-bottom: 30px;
  }
  .about__objectives-edu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 1310px;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px var(--inside-padding);
    .about__objectives-edu-title {
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
        text-align: center;
      }
    }
    .about__objectives-edu-content {
      width: 100%;
      max-width: 1000px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      gap: 15px;
      background: url("/src/assets/images/objectives/objectives_edu.webp");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .about__objectives-edu-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        .about__objectives-edu-card {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 650px;
          padding: 15px 13px;
          border-radius: 15px;
          p {
            font-size: var(--m-font-size);
            font-family: $font-medium;
            font-weight: 500;
            color: var(--main-text-color);
          }
        }
      }
    }
    .about__objectives-edu-more {
      width: 100%;
      border-top: 1px solid var(--main-text-color);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      padding: 60px 0px;
      p {
        font-size: var(--m-font-size);
        color: #c5c6c7;
        font-family: $font-light;
        font-weight: 300;
        text-align: center;
      }
    }
  }
}

// Colors
.regular-card-color {
  background-color: var(--secondary-color);
}
.danger-card-color {
  background-color: var(--secondary-light-red);
}
.purple-card-color {
  background-color: var(--secondary-purple-color);
}
.primary-card-color {
  background-color: var(--main-color);
}

@media only screen and (max-width: 992px) {
  .about__objectives-edu-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }
  .about__objectives-edu-card {
    width: 100% !important;
    height: max-content;
    p {
      font-size: var(--s-font-size) !important;
      -webkit-line-clamp: unset !important;
      line-clamp: unset !important;
    }
  }
  .about__objectives-edu-content {
    gap: 30px !important;
  }
}

// @media only screen and (min-width: 992px) {
//   .about__objectives-edu-grid:hover {
//     z-index: 5;
//     .about__objectives-edu-card {
//       width: 100% !important;
//       position: absolute;
//       z-index: 5;
//       transform: scale(1.02);
//       box-shadow: 0px 4px 2.2px rgba(0, 0, 0, 0.025),
//         0px 9.6px 5.3px rgba(0, 0, 0, 0.036),
//         0px 18.2px 10px rgba(0, 0, 0, 0.045),
//         0px 32.4px 17.9px rgba(0, 0, 0, 0.054),
//         0px 60.6px 33.4px rgba(0, 0, 0, 0.065),
//         0px 145px 80px rgba(0, 0, 0, 0.09);
//       transition: 0.4s;
//       &::before {
//         height: 100% !important;
//         background-color: rgba($color: #000, $alpha: 0.4) !important;
//         transition: 0.2s;
//         z-index: -1;
//       }
//       p {
//         -webkit-line-clamp: unset !important;
//         line-clamp: unset !important;
//       }
//     }
//   }
// }
