.project__cards {
  max-width: 400px;
  height: 400px;
  //   padding: 30px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 0;
    border-radius: inherit;
    transition: 0.2s ease-in;
  }
  @media only screen and (max-width: 440px) {
    height: 350px;
  }
  @media only screen and (max-width: 992px) {
    &::before {
      background-color: rgba($color: #000000, $alpha: 0.75);
    }
    .project__info {
      height: 100% !important;
      border-radius: 10px !important;
      .project__info-fullscreen {
        opacity: 1 !important;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    &:hover {
      &::before {
        background-color: rgba($color: #000000, $alpha: 0.6);
        transition: 0.2s ease-in;
      }
      .project__info {
        // backdrop-filter: blur(5px);
        // -webkit-backdrop-filter: blur(5px);
        height: 100%;
        transition: 0.25s all ease-in-out;
        border-radius: inherit;
        .project__info-fullscreen {
          opacity: 1;
          transition-delay: 0.15s;
        }
      }
      p {
        -webkit-line-clamp: 7 !important; /* number of lines to show */
        line-clamp: 7 !important;
      }
      .project__img {
        filter: blur(2px);
      }
    }
  }
  .project__img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    filter: blur(0px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .project__info {
    width: 100%;
    height: 170px;
    border-radius: 10px 10px 0px 0px;
    background-color: rgba($color: #000000, $alpha: 0.35);
    bottom: 0;
    padding: 15px 25px;
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .project__info-content {
      width: 100%;
      h1 {
        font-size: var(--m-font-size);
        font-family: $font-bold;
        font-weight: 700;
        color: var(--main-text-color);
      }
      p {
        font-size: var(--s-font-size);
        font-family: $font-light;
        font-weight: 300;
        color: var(--main-secondary-text-color);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      h2 {
        font-size: var(--m-font-size);
        font-family: $font-medium;
        font-weight: 500;
        color: var(--main-text-color);
      }
      span {
        font-size: var(--s-font-size);
        font-family: $font-medium;
        font-weight: 500;
        color: var(--secondary-color);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }
    }

    .project__info-fullscreen {
      border: none;
      outline: none;
      background-color: transparent;
      margin: 15px 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      opacity: 0;
      @media only screen and (max-width: 440px) {
        width: 55px !important;
        height: 55px !important;
      }
      @media only screen and (max-width: 992px) {
        width: 70px;
        height: 70px;
      }
      svg {
        cursor: pointer;
        transition: 0.1s ease-in;
      }
      @media only screen and (min-width: 992px) {
        &:hover {
          svg {
            fill: var(--secondary-color);
            transition: 0.1s ease-in;
          }
        }
        &:active {
          svg {
            fill: #da9604;
            transition: 0.1s ease-in;
          }
        }
        &:focus {
          svg {
            fill: var(--secondary-color);
            transition: 0.1s ease-in;
          }
        }
      }
    }
    .project__link {
      &.disabled {
        pointer-events: none !important;
        cursor: pointer !important;
        svg {
          pointer-events: none !important;
          cursor: pointer !important;
          color: var(--disabled-color) !important;
        }
      }
      svg {
        color: var(--secondary-color);
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
        &:focus {
          opacity: 0.7;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
