.scroll-to-top-btn {
  position: fixed;
  padding: 15px 10px;
  margin-bottom: 10px;
  border-radius: 15px 0px 0px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  transition: 0.4s ease-in;
  background-color: rgba($color: $secondary-dark-body-color, $alpha: 1);
  @media only screen and (max-width: 992px) {
    background-color: rgba($color: $secondary-dark-body-color, $alpha: 0.8);
    backdrop-filter: blur(3px);
  }
  z-index: 99;
  border: 1px solid rgba($color: #fff, $alpha: 0.2);
  border-right-width: 0px;
  cursor: pointer;
  @media only screen and (min-width: 992px) {
    width: 60px;
    height: 45px;
    margin-bottom: 25px;
    padding: 0px;
    border-radius: 10px 0px 0px 10px;
    &:hover {
      background-color: var(--footer-color);
    }
  }
  @media only screen and (max-width: 550px) {
    padding: 10px 8px;
    .scroll-btn,
    .register-scroll-btn {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
.hidden-scroll {
  right: -10rem;
  bottom: 0;
}
.visible-scroll {
  right: 0;
  bottom: 0;
}

// Scroll btn
.scroll-btn {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  border: 1.7px solid var(--main-color);
  cursor: pointer;
  @media only screen and (min-width: 992px) {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
    svg {
      color: var(--main-text-color) !important;
    }
  }
  svg {
    width: 17px;
    height: 17px;
    color: var(--main-color);
    @media only screen and (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }
}

// Register btn
.register-scroll-btn {
  display: none;
}
@media only screen and (max-width: 992px) {
  .register-scroll-btn {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border: 1.7px solid var(--secondary-color);
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary-color);
      @media only screen and (max-width: 550px) {
        width: 17px;
        height: 17px;
      }
    }
  }
}
