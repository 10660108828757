.error__page {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 140px 25px 0px;
  background-color: #131b94;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  overflow: hidden;
  @media only screen and (max-height: 960px) {
    overflow-y: auto;
  }
  @media only screen and (max-height: 730px) {
    gap: 10px;
  }
  @media only screen and (max-width: 992px) {
    h1 {
      font-size: 2rem;
    }
    .error__info {
      h1 {
        font-size: var(--l-font-size) !important;
      }
      p {
        font-size: var(--m-font-size) !important;
      }
    }
  }
  h1 {
    z-index: 1;
  }
  .error__container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1;
    gap: 15px;
    .error__content {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      @media only screen and (max-height: 730px) {
        gap: 0px;
        .error__page-gif {
          max-height: 110px !important;
          img {
            width: auto !important;
          }
        }
      }
      .error__page-gif {
        max-width: 400px;
        max-height: 250px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .error__info {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        // max-width: 450px;
        h1 {
          font-size: var(--xl-font-size);
          font-family: $font-bold;
          font-weight: 700;
          color: var(--main-text-color);
        }
        p {
          font-size: var(--l-font-size);
          font-family: $font-medium;
          font-weight: 500;
          color: var(--main-text-color);
          // word-break: break-all;
        }
      }
    }
    .error__page-information {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
      ul {
        a {
          font-size: 30px;
          color: var(--main-color);
          @media only screen and (min-width: 992992px) {
            &:hover {
              svg {
                color: var(--main-text-color);
                transition: 0.35s all;
                transform: scale(1.1);
              }
            }
          }
        }
      }
      p {
        font-family: $font-light;
        font-weight: 300;
        color: var(--main-secondary-text-color);
        font-size: var(--s-font-size);
      }
    }
  }
}
