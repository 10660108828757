.about__team {
  position: relative;
  width: 100%;
  padding: 70px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-dark-body-color);
  &::before {
    content: "";
    position: absolute;
    width: 388px;
    height: 100%;
    top: 0;
    left: 10%;
    background: url(/src/assets/images/illustrations/binary/aboutTeam.webp);
    background-position: bottom;
    background-repeat: repeat-y;
    z-index: 0;
    @media only screen and (max-width: 1340px) {
      left: -100px;
    }
    @media only screen and (max-width: 992px) {
      left: -285px;
    }
    @media only screen and (max-width: 650px) {
      left: -320px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 388px;
    height: 100%;
    top: 0;
    right: 10%;
    background: url(/src/assets/images/illustrations/binary/aboutTeam.webp);
    background-position: bottom;
    background-repeat: repeat-y;
    z-index: 0;
    @media only screen and (max-width: 1340px) {
      right: -100px;
    }
    @media only screen and (max-width: 992px) {
      right: -285px;
    }
    @media only screen and (max-width: 650px) {
      right: -320px;
    }
  }
  .about__team-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 0px var(--inside-padding);
    z-index: 5;
    .about__team-title {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 5px;
      @media only screen and (max-width: 692px) {
        h1 {
          font-size: var(--l-font-size) !important;
        }
        h2 {
          font-size: var(--m-font-size) !important;
        }
      }
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
      }
      h2 {
        font-size: var(--l-font-size);
        color: var(--main-secondary-text-color);
        font-weight: 300;
        font-family: $font-light;
      }
    }
  }
}

// Team cards
.team__cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 30px;
  .team__cards {
    max-width: 360px;
    width: 100%;
    height: 320px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 53% 100% 52% 90% / 100% 59% 100% 56%;
    @media only screen and (max-width: 992px) {
      height: 285px;
    }
    @media only screen and (min-width: 992px) {
      &:hover {
        border-radius: 100% 100% 95% 100% / 72% 100% 100% 100%;
        .team__description {
          backdrop-filter: blur(0px);
          -webkit-backdrop-filter: blur(0px);
          background-color: rgba($color: #000000, $alpha: 0.4);
        }
      }
    }

    .team__img-container {
      // max-width: 360px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
    }
    .team__description {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px var(--inside-padding);
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 5;
      width: 101%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.6);
      backdrop-filter: blur(2.5px);
      -webkit-backdrop-filter: blur(2.5px);
      @media only screen and (max-width: 992px) {
        backdrop-filter: blur(0px);
        background-color: rgba($color: #000000, $alpha: 0.7);

        h1,
        p {
          font-size: var(--s-font-size) !important;
        }
      }
      h1 {
        font-size: var(--m-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
        width: 100%;
        text-align: center;
        word-break: break-all;
      }
      p {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-text-color);
        width: 100%;
        text-align: center;
        word-break: break-all;
      }
    }
  }
  @media only screen and (max-width: 1160px) {
    place-items: center;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 772px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
