.btn {
  width: 200px;
  min-height: 50px;
  height: 50px;
  flex-grow: 0;
  text-align: center;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: $font-medium;
  color: var(--main-text-color);
  letter-spacing: 0.2px;
  z-index: 2;
  overflow: hidden;
  transition: 0.15s ease-in all;
  // box-shadow: 0px 3.5px 0px 0px rgba($color: var(--secondary-color), $alpha: 0.8);
  // -webkit-box-shadow: 0px 3.5px 0px 0px
  //   rgba($color: var(--secondary-color), $alpha: 0.8);
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-radius: inherit;
    transition: 0.15s ease-in;
    border: none;
    outline: none;
  }
  &:active {
    // margin-top: 5px;
    background-color: #d69d17;
    // letter-spacing: 0.4px;
    border-radius: 13px !important;
    &::after {
      background-color: #02304f6e;
    }
  }
  &:focus {
    // margin-top: 5px;
    background-color: #d69d17;
    // letter-spacing: 0.4px;
    border-radius: 13px !important;
    &::after {
      background-color: #02304f6e;
    }
  }
  @media only screen and (max-width: "992px") {
    width: 170px !important;
    height: 45px !important;
    font-size: var(--s-font-size) !important;
    border-radius: 10px !important;
    &::after {
      // border-radius: 10px;
    }
  }
  @media only screen and (min-width: 992px) {
    &:hover {
      transform: scale(1.05);
      &::after {
        opacity: 1;
      }
    }
  }
  a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: inherit;
  }
}
.disabled-btn {
  background-color: var(--disabled-color) !important;
  pointer-events: none !important;
  cursor: pointer;
  &:active {
    margin-top: 0;
    letter-spacing: 0.5px;
  }
  &:hover {
    transform: scale(1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    cursor: default;
    &::after {
      opacity: 0;
    }
  }
  &:active {
    background-color: var(--disabled-color) !important;
  }
}
.blue-btn {
  background-color: var(--main-color) !important;
  &:active {
    background-color: #309bcd !important;
  }
}

.red-btn {
  background-color: var(--secondary-light-red) !important;
  &:active {
    background-color: #db4e4e !important;
  }
}
.yellow-btn {
  background-color: var(--secondary-color) !important;
  &:active {
    background-color: #d69f1e !important;
  }
}
.green-btn {
  background-color: var(--secondary-light-green-color) !important;
  &:active {
    background-color: #44939e !important;
  }
}
.lightBlue-btn {
  background-color: var(--secondary-light-blue) !important;
  &:active {
    background-color: #44939e !important;
  }
}
.purple-btn {
  background-color: var(--secondary-purple-color) !important;
  &:active {
    background-color: #844f8c !important;
  }
}

// Changing the properties of the (a) attribute in button
.button-width {
  width: 100% !important;
}

.m-btn {
  width: 170px !important;
  min-height: 45px !important;
  height: 45px !important;
  border-radius: 10px;
  font-size: var(--s-font-size) !important;
}
.s-btn {
  width: 150px !important;
  min-height: 40px !important;
  height: 40px !important;
  border-radius: 10px;
  font-size: var(--xs-font-size) !important;
}
.xs-btn {
  width: 130px !important;
  min-height: 35px !important;
  height: 35px !important;
  border-radius: 5px !important;
  font-size: var(--xs-font-size) !important;
}
