.about__objectives {
  width: 100%;
  padding: 100px 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  min-height: 50vh;
  .about__objectives-wrapper {
    width: 100%;
    .about__objectives-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      gap: 60px;
      padding: 0px var(--inside-padding);
      @media only screen and (max-width: 992px) {
        .objective__card {
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        max-width: 900px;
        .mission-text,
        .vision-text {
          margin: 0px !important;
          margin-top: -30px !important;
        }
        .vision {
          flex-direction: column-reverse;
        }
      }
      .objective__card {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 600px) {
          .objective__card-text {
            max-width: 535px !important;
            padding: 35px 15px !important;
            h1 {
              font-size: var(--l-font-size) !important;
            }
            p {
              font-size: var(--m-font-size) !important;
            }
          }
        }
        @media only screen and (min-width: 992px) {
          &:hover {
            .objective__card-text {
              transform: scale(1.08);
            }
            .objective__image {
              height: 280px;

              &::before {
                backdrop-filter: blur(1.5px);
                -webkit-backdrop-filter: blur(1.5px);
                border-radius: 20px;
                background-color: rgba($color: #000000, $alpha: 0.4);
              }
            }
          }
        }

        .mission-text {
          margin-left: -80px;
        }
        .vision-text {
          margin-right: -80px;
        }
        .objective__card-text {
          width: 100%;
          max-width: 540px;
          border-radius: 10px 15px 15px 10px;
          background-color: var(--secondary-dark-color);
          padding: 40px 24px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          z-index: 5;
          box-shadow: 0.6px 2px 2.2px rgba(0, 0, 0, 0.031),
            1.6px 5px 5.3px rgba(0, 0, 0, 0.044),
            2.9px 8.9px 10px rgba(0, 0, 0, 0.055),
            4.6px 14.1px 17.9px rgba(0, 0, 0, 0.066),
            6.9px 21.3px 33.4px rgba(0, 0, 0, 0.079),
            12px 37px 80px rgba(0, 0, 0, 0.11);
          @media only screen and (max-width: 600px) {
            box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.051),
              0px 0px 5.3px rgba(0, 0, 0, 0.073),
              0px 0px 10px rgba(0, 0, 0, 0.09),
              0px 0px 17.9px rgba(0, 0, 0, 0.107),
              0px 0px 33.4px rgba(0, 0, 0, 0.129),
              0px 0px 80px rgba(0, 0, 0, 0.18);
            padding: 50px 20px;
          }
          h1 {
            width: 100%;
            text-align: left;
            font-size: var(--l-font-size);
            color: var(--main-text-color);
            font-weight: 700;
            font-family: $font-bold;
            span {
              color: var(--secondary-color);
              font-weight: 700;
              font-family: $font-bold;
            }
          }
          p {
            font-size: var(--m-font-size);
            color: var(--main-text-color);
            font-family: $font-light;
            font-weight: 300;
            span {
              color: var(--secondary-color);
              font-family: $font-light;
              font-weight: 300;
            }
          }
        }
        .objective__image {
          width: 320px;
          height: 300px;
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 1.6px 5px 5.3px rgba(0, 0, 0, 0.008),
            4.6px 14.1px 17.9px rgba(0, 0, 0, 0.012),
            12px 37px 80px rgba(0, 0, 0, 0.02);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.3);
            backdrop-filter: blur(0px);
            -webkit-backdrop-filter: blur(0px);
            border-radius: 20px;
            z-index: 5;
          }
          .about__objective-carousel {
            height: 100%;
            border-radius: inherit;
            .swiper-wrapper {
              height: 100% !important;
            }
          }
          @media only screen and (max-width: 600px) {
            width: 100%;
            height: 220px;
            padding: 0px 20px;
            &::before {
              width: 0;
            }
          }
          .about__objective-img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

// Vectors SVG
.about__objectives-vectors {
  width: 100%;
  max-height: 20vw;
  font-size: 0;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
}
