@charset "UTF-8";
@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.register {
  width: 100%;
  min-height: 10vh;
  padding: 150px 0px !important;
  background-color: var(--main-secondary-text-color-2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .register__container {
  width: 100%;
  max-width: 950px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 var(--inside-padding);
}
@media only screen and (max-width: 681px) {
  .register .register__container .register__input-double {
    flex-direction: column;
    gap: 5px !important;
  }
  .register .register__container .register__wrapper {
    gap: 5px !important;
  }
  .register .register__container .register__input {
    font-size: var(--s-font-size) !important;
    height: 55px !important;
  }
  .register .register__container .register__input::-moz-placeholder {
    font-size: var(--s-font-size) !important;
  }
  .register .register__container .register__input::placeholder {
    font-size: var(--s-font-size) !important;
  }
}
.register .register__container .register__title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.register .register__container .register__title h1 {
  font-size: var(--xl-font-size);
  color: var(--secondary-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.register .register__container .register__title h2 {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--secondary-text-color);
}
.register .register__container .register__title .register__summer h2 {
  font-size: var(--m-font-size);
  color: var(--secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
}
.register .register__container .register__title .register__summer h2 a {
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-color);
  -webkit-text-decoration: underline 2px solid var(--main-color);
          text-decoration: underline 2px solid var(--main-color);
}
@media only screen and (min-width: 992px) {
  .register .register__container .register__title .register__summer h2 a:hover {
    color: var(--secondary-purple-color);
    -webkit-text-decoration: underline 2px solid var(--secondary-purple-color);
            text-decoration: underline 2px solid var(--secondary-purple-color);
  }
}
.register .register__container .register__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 30px;
}
.register .register__container .register__wrapper .register__input-double {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.register .register__container .register__wrapper .register__input-double .register__input-date {
  width: 101%;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 681px) {
  .register .register__container .register__wrapper .register__input-double .register__input-date {
    width: 100%;
  }
}
.register .register__container .register__wrapper .register__input-double .register__input-date label {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Medium";
  color: var(--secondary-subtitle-text-color);
}
.register .register__container .register__wrapper .register__input-double .register__input-date label span {
  color: var(--secondary-light-red);
}
@media only screen and (min-width: 681px) {
  .register .register__container .register__wrapper .register__input-double .register__input-date label {
    position: absolute;
    top: -25px;
    left: 0;
  }
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root {
  width: 100%;
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
  height: 65px;
  border-radius: 10px;
  border: 1.5px solid var(--main-color);
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color);
  background-color: transparent;
  padding: 5px;
  outline: none;
  letter-spacing: 0.5px;
  transition: 0.5s ease-in;
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root::after, .register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root::before {
  content: unset;
  display: none;
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root:focus {
  border: 1px solid rgba(64, 177, 229, 0.5) !important;
  padding-left: 7px !important;
  background-color: rgba(var(--footer-color), 0.2 !important);
}
@media only screen and (max-width: 681px) {
  .register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root {
    font-size: var(--s-font-size) !important;
    height: 55px !important;
  }
  .register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root::-moz-placeholder {
    font-size: var(--s-font-size) !important;
  }
  .register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root::placeholder {
    font-size: var(--s-font-size) !important;
  }
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root input::-moz-placeholder {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-secondary-text-color) !important;
  -webkit-text-fill-color: var(--main-secondary-text-color) !important;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.register .register__container .register__wrapper .register__input-double .register__input-date .MuiFormControl-root .MuiInputBase-root input::placeholder {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-secondary-text-color) !important;
  -webkit-text-fill-color: var(--main-secondary-text-color) !important;
  transition: 0.3s ease-in-out;
}
.register .register__container p {
  text-align: center;
  font-size: var(--s-font-size);
  color: var(--secondary-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
}

.register__input-container {
  position: relative;
  width: 100%;
}
.register__input-container label {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Medium";
  color: var(--secondary-subtitle-text-color);
}
.register__input-container label span {
  color: var(--secondary-light-red);
}
@media only screen and (min-width: 681px) {
  .register__input-container label {
    position: absolute;
    top: -25px;
    left: 0;
  }
}

.register__input {
  width: 100%;
  height: 65px;
  border-radius: 10px;
  border: 1.5px solid var(--main-color);
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color);
  background-color: transparent;
  padding: 5px;
  outline: none;
  letter-spacing: 0.5px;
  transition: 0.15s ease-in;
}
.register__input:focus {
  border: 1px solid rgba(64, 177, 229, 0.5) !important;
  padding-left: 7px !important;
  background-color: rgba(64, 177, 229, 0.15);
}
.register__input::-moz-placeholder {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color) !important;
  -webkit-text-fill-color: var(--secondary-text-color) !important;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.register__input::placeholder {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color) !important;
  -webkit-text-fill-color: var(--secondary-text-color) !important;
  transition: 0.3s ease-in-out;
}
.register__input::-webkit-input-placeholder {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color) !important;
  -webkit-text-fill-color: var(--secondary-text-color) !important;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.register__input option {
  background-color: var(--main-text-color);
  padding: 10px 0px;
}
.register__input option:checked {
  color: var(--main-text-color) !important;
  background-color: var(--secondary-purple-color);
}

.react-datepicker__tab-loop,
.react-datepicker__tab-loop__start {
  transition: none !important;
}

select.register__input {
  cursor: pointer;
}

.register-message {
  width: 100% !important;
  padding: 10px;
  transition: 0.1s all;
  min-height: 200px;
  max-height: 320px;
  resize: vertical;
}

.register__btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.register__btn-container .register-form-btn {
  width: 270px;
  height: 55px;
}
@media only screen and (max-width: 681px) {
  .register__btn-container {
    margin-top: 20px;
  }
}

.register-birthday[type=date]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.register__time-select {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}
.register__time-select h2 {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color) !important;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 681px) {
  .register__time-select h2 {
    font-size: var(--s-font-size) !important;
  }
}

.register__cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
}
.register__cards-container .register__cards-parent {
  margin-top: 10px;
  overflow: hidden;
  border-radius: 50px;
}
.register__cards-container .register__cards-parent .register__cards {
  position: relative;
  min-height: 30px;
  padding: 10px 20px;
  border-radius: inherit;
  background-color: rgba(64, 177, 229, 0.2);
  border: solid 1px var(--main-color);
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.register__cards-container .register__cards-parent .register__cards::before {
  content: "";
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("assets/images/icons/exit.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: 0.3s ease-in;
}
@media only screen and (min-width: 992px) {
  .register__cards-container .register__cards-parent .register__cards:hover {
    background-color: rgba(64, 177, 229, 0.5);
  }
}
@media only screen and (max-width: 681px) {
  .register__cards-container .register__cards-parent .register__cards h1 {
    font-size: 0.7rem !important;
  }
}
.register__cards-container .register__cards-parent .register__cards.clicked {
  background-color: #40b1e5 !important;
}
@media only screen and (min-width: 992px) {
  .register__cards-container .register__cards-parent .register__cards.clicked:hover h1 {
    opacity: 0 !important;
  }
  .register__cards-container .register__cards-parent .register__cards.clicked:hover::before {
    opacity: 1 !important;
    transition: 0.3s ease-in;
  }
}
.register__cards-container .register__cards-parent .register__cards h1 {
  font-size: var(--xs-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-dark-body-color);
  text-align: center;
  word-break: break-all;
}

.register__select-menu {
  position: relative;
}
.register__select-menu select {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
}
.register__select-menu::after {
  position: absolute;
  content: "▾";
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 28px;
  opacity: 0.7;
  height: 28px;
  border-radius: 20px;
}
@media only screen and (max-width: 681px) {
  .register__select-menu::after {
    transform: translateY(-10%);
  }
}

.register__hero {
  width: 100%;
  padding-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00416b;
}
.register__hero .register__hero-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 60px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 992px) {
  .register__hero .register__hero-container {
    padding: 130px var(--inside-padding);
  }
  .register__hero .register__hero-container .register__hero-content {
    align-items: center !important;
    max-width: 70% !important;
  }
  .register__hero .register__hero-container .register__hero-content h1,
  .register__hero .register__hero-container .register__hero-content p {
    text-align: center !important;
  }
  .register__hero .register__hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("assets/images/illustrations/image5.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.2;
    z-index: 0;
  }
  .register__hero .register__hero-container .register__hero-img {
    display: none;
  }
}
.register__hero .register__hero-container .register__hero-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 40%;
  z-index: 1;
}
@media only screen and (max-width: 500px) {
  .register__hero .register__hero-container .register__hero-content {
    max-width: 100% !important;
  }
}
.register__hero .register__hero-container .register__hero-content h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-light-red);
}
.register__hero .register__hero-container .register__hero-content p {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
}
.register__hero .register__hero-container .register__hero-img {
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  transform: translate(0px, 70px);
}
.register__hero .register__hero-container .register__hero-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=registerStyle.css.map */