@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.about__objectives-edu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  background-color: var(--secondary-dark-color);
}
@media only screen and (max-width: 692px) {
  .about__objectives-edu {
    padding-bottom: 30px;
  }
}
.about__objectives-edu .about__objectives-edu-container {
  display: flex;
  flex-direction: column;
  width: 1310px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 0px var(--inside-padding);
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-title h1 {
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
  text-align: center;
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-content {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: url("/src/assets/images/objectives/objectives_edu.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-content .about__objectives-edu-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-content .about__objectives-edu-grid .about__objectives-edu-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 650px;
  padding: 15px 13px;
  border-radius: 15px;
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-content .about__objectives-edu-grid .about__objectives-edu-card p {
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--main-text-color);
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-more {
  width: 100%;
  border-top: 1px solid var(--main-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 60px 0px;
}
.about__objectives-edu .about__objectives-edu-container .about__objectives-edu-more p {
  font-size: var(--m-font-size);
  color: #c5c6c7;
  font-family: "Poppins-Light";
  font-weight: 300;
  text-align: center;
}

.regular-card-color {
  background-color: var(--secondary-color);
}

.danger-card-color {
  background-color: var(--secondary-light-red);
}

.purple-card-color {
  background-color: var(--secondary-purple-color);
}

.primary-card-color {
  background-color: var(--main-color);
}

@media only screen and (max-width: 992px) {
  .about__objectives-edu-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about__objectives-edu-card {
    width: 100% !important;
    height: -moz-max-content;
    height: max-content;
  }
  .about__objectives-edu-card p {
    font-size: var(--s-font-size) !important;
    -webkit-line-clamp: unset !important;
    line-clamp: unset !important;
  }
  .about__objectives-edu-content {
    gap: 30px !important;
  }
}
.about__objectives {
  width: 100%;
  padding: 100px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  min-height: 50vh;
}
.about__objectives .about__objectives-wrapper {
  width: 100%;
}
.about__objectives .about__objectives-wrapper .about__objectives-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 992px) {
  .about__objectives .about__objectives-wrapper .about__objectives-container {
    max-width: 900px;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card {
    flex-direction: column;
    align-items: center;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .mission-text,
  .about__objectives .about__objectives-wrapper .about__objectives-container .vision-text {
    margin: 0px !important;
    margin-top: -30px !important;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .vision {
    flex-direction: column-reverse;
  }
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text {
    max-width: 535px !important;
    padding: 35px 15px !important;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text h1 {
    font-size: var(--l-font-size) !important;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text p {
    font-size: var(--m-font-size) !important;
  }
}
@media only screen and (min-width: 992px) {
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card:hover .objective__card-text {
    transform: scale(1.08);
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card:hover .objective__image {
    height: 280px;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card:hover .objective__image::before {
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .mission-text {
  margin-left: -80px;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .vision-text {
  margin-right: -80px;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text {
  width: 100%;
  max-width: 540px;
  border-radius: 10px 15px 15px 10px;
  background-color: var(--secondary-dark-color);
  padding: 40px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
  box-shadow: 0.6px 2px 2.2px rgba(0, 0, 0, 0.031), 1.6px 5px 5.3px rgba(0, 0, 0, 0.044), 2.9px 8.9px 10px rgba(0, 0, 0, 0.055), 4.6px 14.1px 17.9px rgba(0, 0, 0, 0.066), 6.9px 21.3px 33.4px rgba(0, 0, 0, 0.079), 12px 37px 80px rgba(0, 0, 0, 0.11);
}
@media only screen and (max-width: 600px) {
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text {
    box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.051), 0px 0px 5.3px rgba(0, 0, 0, 0.073), 0px 0px 10px rgba(0, 0, 0, 0.09), 0px 0px 17.9px rgba(0, 0, 0, 0.107), 0px 0px 33.4px rgba(0, 0, 0, 0.129), 0px 0px 80px rgba(0, 0, 0, 0.18);
    padding: 50px 20px;
  }
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text h1 {
  width: 100%;
  text-align: left;
  font-size: var(--l-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text h1 span {
  color: var(--secondary-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text p {
  font-size: var(--m-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__card-text p span {
  color: var(--secondary-color);
  font-family: "Poppins-Light";
  font-weight: 300;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image {
  width: 320px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 1.6px 5px 5.3px rgba(0, 0, 0, 0.008), 4.6px 14.1px 17.9px rgba(0, 0, 0, 0.012), 12px 37px 80px rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 20px;
  z-index: 5;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image .about__objective-carousel {
  height: 100%;
  border-radius: inherit;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image .about__objective-carousel .swiper-wrapper {
  height: 100% !important;
}
@media only screen and (max-width: 600px) {
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image {
    width: 100%;
    height: 220px;
    padding: 0px 20px;
  }
  .about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image::before {
    width: 0;
  }
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image .about__objective-img {
  width: 100%;
  height: 100%;
}
.about__objectives .about__objectives-wrapper .about__objectives-container .objective__card .objective__image .about__objective-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}

.about__objectives-vectors {
  width: 100%;
  max-height: 20vw;
  font-size: 0;
}
.about__objectives-vectors img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.about__sep {
  width: 100%;
  padding: 100px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-body-color);
}
.about__sep .about__sep-container {
  max-width: 1230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px var(--inside-padding);
  flex-direction: column;
  gap: 70px;
}
@media only screen and (max-width: 1240px) {
  .about__sep .about__sep-container {
    max-width: 500px;
  }
}
.about__sep .about__sep-container .about__sep-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  justify-content: flex-start;
}
.about__sep .about__sep-container .about__sep-title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-light-red);
}
.about__sep .about__sep-container .about__sep-title h2 {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--secondary-text-color);
}

.dark-blue-inputs {
  background-color: var(--secondary-dark-color) !important;
}
.dark-blue-inputs:focus {
  color: var(--secondary-light-red) !important;
}
.dark-blue-inputs:focus::-moz-placeholder {
  color: var(--secondary-light-red) !important;
}
.dark-blue-inputs:focus::placeholder {
  color: var(--secondary-light-red) !important;
}
.dark-blue-inputs:hover {
  color: var(--secondary-light-red) !important;
}
.dark-blue-inputs:hover::-moz-placeholder {
  color: var(--secondary-light-red) !important;
}
.dark-blue-inputs:hover::placeholder {
  color: var(--secondary-light-red) !important;
}

.about__separator {
  width: 100%;
  padding: 130px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-purple-color);
}
@media only screen and (max-width: 992px) {
  .about__separator {
    padding: 90px 0px;
  }
  .about__separator h1 {
    font-size: var(--l-font-size) !important;
  }
  .about__separator p {
    font-size: var(--s-font-size) !important;
  }
}
.about__separator .about__separator-container {
  max-width: 987px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px var(--inside-padding);
}
.about__separator .about__separator-container h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  text-align: center;
}
.about__separator .about__separator-container h1 span {
  color: var(--secondary-light-red);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.about__separator .about__separator-container p {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: center;
}

.about__success {
  width: 100%;
  padding: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-x: hidden;
}

.about__success-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px var(--inside-padding);
  overflow: hidden;
}
.about__success-container .about__success-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.about__success-container .about__success-carousel .success__carousel .swiper-wrapper {
  display: flex;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination {
  margin-top: 20px;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-pagination {
    margin-top: 0px;
  }
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  opacity: 0.2;
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--main-color) !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next {
  width: 64px;
  height: 64px;
  background-color: rgba(12, 27, 43, 0.8);
  border-radius: 50px;
  right: 0;
  z-index: 999 !important;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next:hover {
  background-color: #0c1b2b;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("assets/images/icons/angle_right.png");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-button-next {
    bottom: 0 !important;
    top: unset !important;
  }
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev {
  width: 64px;
  height: 64px;
  background-color: rgba(12, 27, 43, 0.8);
  border-radius: 50px;
  left: 0;
  z-index: 999 !important;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev:hover {
  background-color: #0c1b2b;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("assets/images/icons/angle_left.png");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-button-prev {
    bottom: 0 !important;
    top: unset !important;
  }
}
.about__success-container .about__success-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
@media only screen and (max-width: 650px) {
  .about__success-container .about__success-title h2 {
    max-width: 100% !important;
  }
}
.about__success-container .about__success-title h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-text-color);
}
.about__success-container .about__success-title h2 {
  width: 100%;
  max-width: 60%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-text-color);
}

.success__section-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
}
.success__section-container .success__section-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
}
.success__section-container .success__section-wrapper .success__timeline {
  position: absolute;
  top: 4%;
  left: 0;
  display: flex;
  width: 3px;
  height: calc(100% - 100px);
}
.success__section-container .success__section-wrapper .success__timeline span {
  display: flex;
  width: 3px;
  height: 100%;
  background-color: var(--secondary-purple-color);
  border-radius: 5px;
}
.success__section-container .success__section-wrapper .success__section-content {
  width: 100%;
  padding: 30px 0px;
  display: flex;
  gap: 70px;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content {
    gap: 35px;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item {
    align-items: flex-start;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__content-img,
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date-img {
    display: none !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date {
    max-width: 15px !important;
    transform: translateY(80px) !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date h3 {
    display: none;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
    max-width: -moz-max-content !important;
    max-width: max-content !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card::after {
    display: none;
  }
}
@media only screen and (max-width: 992px) and (max-width: 700px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
    width: 100% !important;
    max-width: 100% !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    height: 130px !important;
    width: 210px !important;
    align-self: center !important;
    transform: translateY(-10px);
  }
}
@media only screen and (max-width: 992px) and (max-width: 700px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    width: 70% !important;
    height: 140px !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
  width: 100%;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date span {
  width: 100%;
  min-width: 15px;
  background-color: var(--secondary-purple-color);
  height: 3px;
  border-radius: 5px 0px 0px 5px;
  z-index: 1;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date h3 {
  font-size: var(--xs-font-size);
  color: var(--secondary-subtitle-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  z-index: 1;
  padding-left: 10px;
  word-wrap: break-word;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date .item__date-img {
  max-width: 150px;
  max-height: 112px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-1%, -30%);
  overflow: hidden;
  z-index: -1;
  opacity: 0.4;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date .item__date-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.4s;
  z-index: 5;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card::after {
  content: "";
  position: absolute;
  bottom: -20%;
  left: 0;
  right: 0;
  height: 20px;
  width: 105px;
  margin-left: auto;
  margin-right: auto;
  background: url("assets/images/illustrations/effect_element.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  opacity: 0.5;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card h3 {
  font-size: var(--xs-font-size);
  color: var(--secondary-subtitle-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  word-wrap: break-word;
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card h3 {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover {
    transform: scale(1.01);
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover .item__img::after {
    -webkit-backdrop-filter: blur(0px) !important;
            backdrop-filter: blur(0px) !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover .item__content {
    border-radius: 10px !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content {
  position: relative;
  max-width: 330px;
  width: 100%;
  padding: 30px 24px;
  border-radius: 15px;
  box-shadow: 2px 7px 11px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content h1 {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  letter-spacing: 0.035rem;
  text-align: left;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content p {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  letter-spacing: 0.02rem;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  z-index: 1;
  box-shadow: -3px 0 6px 1px rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    transform: translateX(-10px);
  }
}
@media only screen and (max-width: 400px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    width: 90% !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.about__team {
  position: relative;
  width: 100%;
  padding: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-dark-body-color);
}
.about__team::before {
  content: "";
  position: absolute;
  width: 388px;
  height: 100%;
  top: 0;
  left: 10%;
  background: url(/src/assets/images/illustrations/binary/aboutTeam.webp);
  background-position: bottom;
  background-repeat: repeat-y;
  z-index: 0;
}
@media only screen and (max-width: 1340px) {
  .about__team::before {
    left: -100px;
  }
}
@media only screen and (max-width: 992px) {
  .about__team::before {
    left: -285px;
  }
}
@media only screen and (max-width: 650px) {
  .about__team::before {
    left: -320px;
  }
}
.about__team::after {
  content: "";
  position: absolute;
  width: 388px;
  height: 100%;
  top: 0;
  right: 10%;
  background: url(/src/assets/images/illustrations/binary/aboutTeam.webp);
  background-position: bottom;
  background-repeat: repeat-y;
  z-index: 0;
}
@media only screen and (max-width: 1340px) {
  .about__team::after {
    right: -100px;
  }
}
@media only screen and (max-width: 992px) {
  .about__team::after {
    right: -285px;
  }
}
@media only screen and (max-width: 650px) {
  .about__team::after {
    right: -320px;
  }
}
.about__team .about__team-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 0px var(--inside-padding);
  z-index: 5;
}
.about__team .about__team-container .about__team-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
@media only screen and (max-width: 692px) {
  .about__team .about__team-container .about__team-title h1 {
    font-size: var(--l-font-size) !important;
  }
  .about__team .about__team-container .about__team-title h2 {
    font-size: var(--m-font-size) !important;
  }
}
.about__team .about__team-container .about__team-title h1 {
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.about__team .about__team-container .about__team-title h2 {
  font-size: var(--l-font-size);
  color: var(--main-secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
}

.team__cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 30px;
}
.team__cards-container .team__cards {
  max-width: 360px;
  width: 100%;
  height: 320px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 53% 100% 52% 90%/100% 59% 100% 56%;
}
@media only screen and (max-width: 992px) {
  .team__cards-container .team__cards {
    height: 285px;
  }
}
@media only screen and (min-width: 992px) {
  .team__cards-container .team__cards:hover {
    border-radius: 100% 100% 95% 100%/72% 100% 100% 100%;
  }
  .team__cards-container .team__cards:hover .team__description {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.team__cards-container .team__cards .team__img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.team__cards-container .team__cards .team__img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.team__cards-container .team__cards .team__description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px var(--inside-padding);
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  width: 101%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}
@media only screen and (max-width: 992px) {
  .team__cards-container .team__cards .team__description {
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0.7);
  }
  .team__cards-container .team__cards .team__description h1,
  .team__cards-container .team__cards .team__description p {
    font-size: var(--s-font-size) !important;
  }
}
.team__cards-container .team__cards .team__description h1 {
  font-size: var(--m-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
  width: 100%;
  text-align: center;
  word-break: break-all;
}
.team__cards-container .team__cards .team__description p {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  width: 100%;
  text-align: center;
  word-break: break-all;
}
@media only screen and (max-width: 1160px) {
  .team__cards-container {
    place-items: center;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 772px) {
  .team__cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.about__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.about__text button {
  margin-top: 10px;
}
@media only screen and (max-width: 692px) {
  .about__text h1 {
    font-size: var(--l-font-size) !important;
    text-align: center !important;
  }
  .about__text p {
    font-size: var(--s-font-size) !important;
    text-align: center !important;
  }
}
.about__text h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  text-align: left;
}
.about__text p {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: left;
}

.about__svg {
  max-width: 538px;
  width: 100%;
  overflow: hidden;
  transform: translateY(50px);
}
.about__svg img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media only screen and (max-width: 992px) {
  .about__text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about__text p {
    text-align: center;
  }
}
.about {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
}
.about .about__header-vectors {
  position: absolute;
  bottom: -10%;
  z-index: 0;
}
.about .about__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 136px 0px 0px;
  min-height: 50vh;
}
@media only screen and (max-width: 992px) {
  .about .about__wrapper {
    padding: 136px 0px 20px;
  }
}
.about .about__wrapper .about__container {
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  padding: 0px var(--inside-padding);
  gap: 30px;
}
.about .about__wrapper .about__container p {
  width: 100%;
  color: var(--main-text-color);
}
@media only screen and (max-width: 992px) {
  .about .about__wrapper .about__container {
    justify-content: space-around !important;
  }
  .about .about__wrapper .about__container .about__svg-mobile {
    display: none !important;
  }
  .about .about__wrapper .about__container .about__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .about .about__wrapper .about__container .about__text p {
    color: var(--main-text-color);
  }
}
.about::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 300px;
  height: 100%;
  border-radius: 0px 0px 0px 50px;
  background: url("assets/images/testimonials/binaryNumbersBg.png");
  background-size: contain;
  background-repeat: repeat-y;
  transform: translateX(-30px);
  opacity: 0.06;
  overflow: hidden;
}
.about::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 300px;
  height: 100%;
  border-radius: 0px 0px 50px 0px;
  background: url("assets/images/testimonials/binaryNumbersBg.png");
  background-size: contain;
  background-repeat: repeat;
  transform: translateX(30px);
  opacity: 0.06;
  overflow: hidden;
}
@media only screen and (max-width: 992px) {
  .about::after {
    left: -100px;
  }
  .about::before {
    right: -100px;
  }
}
@media only screen and (max-width: 992px) and (max-width: 640px) {
  .about::after {
    left: -200px;
  }
  .about::before {
    right: -200px;
  }
}/*# sourceMappingURL=aboutStyle.css.map */