.body.ur {
  background-color: var(--secondary-body-color);
  overflow-x: hidden;
  .programs__content-header {
    position: relative;
    width: 100%;
    min-height: 45vh;
    padding: 150px 0px 50px;
    padding-top: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--footer-color);

    .programs__content-container-header {
      width: 100%;
      max-width: 1185px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-around;
      gap: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0px var(--inside-padding);
      z-index: 5;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      .programs__content-text-header {
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
      }

      .programs__content-text-header {
        // max-width: 615px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        h1 {
          position: relative;
          width: 100%;
          font-size: var(--xl-font-size);
          color: var(--main-text-color);
          font-family: $font-bold;
          font-weight: 700;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          gap: 5px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
        }
        .programs__content-text-subtitle {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: max-content;
          padding: 0px 50px;
          @media only screen and (max-width: 992px) {
            padding: 5px 17px;
            border-radius: 5px;
            img {
              display: none !important;
            }

            h1 {
              position: static !important;
            }
          }
          img {
            max-width: 700px;
            width: 100%;
            object-fit: contain;
          }
          h1 {
            position: absolute;
          }
        }
        // p {
        //   font-size: var(--m-font-size);
        //   color: var(--main-text-color);
        //   font-family: $font-light;
        //   font-weight: 300;
        //   word-break: break-word;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 7; /* number of lines to show */
        //   -webkit-box-orient: vertical;
        // }
      }

      .programs__content-image {
        max-width: 450px;
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .programs__content-header {
    overflow-x: hidden;
  }
  .programs__learning-container {
    position: relative;
    background-color: var(--secondary-dark-body-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0px;
    &::after {
      content: "";
      position: absolute;
      bottom: -194px;
      width: 100%;
      height: 197px;
      background: url("assets/vectors/aboutProgram_bottom.svg");
      background-position: bottom;
      background-repeat: repeat-x;
    }
    @media only screen and (max-width: 992px) {
      padding: 5px 0px;
    }
    .programs__learning-wrapper {
      position: relative;
      width: 100%;
      max-width: 1100px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0px var(--inside-padding);
      @media only screen and (max-width: 600px) {
        h1 {
          font-size: var(--l-font-size) !important;
        }
        p {
          letter-spacing: 0.3px;
          line-height: 140%;
        }

        .separator-img-wrapper {
          max-width: 200px !important;
          margin: 0 !important;
        }
      }

      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--main-text-color);
      }

      p {
        max-width: 916px;
        width: 100%;
        font-size: var(--m-font-size);
        font-family: $font-light;
        font-weight: 300;
        color: var(--main-text-color);
        padding: 5px 0px 5px 17px;
        position: relative;
        z-index: 1;
      }

      .separator-img-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 272px;
        width: 100%;
        opacity: 0.3;
        margin: 0px 40px 10px 0px;
        z-index: 0;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .program__ur__content {
    width: 100%;
    padding: 190px 0px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--main-text-color);
    @media only screen and (max-width: 992px) {
      padding-bottom: 15px;
    }
    .program__ur__content-container {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      gap: 70px;
      flex-direction: column;
      padding: 0px var(--inside-padding);
      padding-right: 30px;
      @media only screen and (max-width: 992px) {
        padding: 0px var(--inside-padding);
        padding-right: var(--inside-padding);
      }
      .program__ur__content-container-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 5px;
        h1 {
          font-size: var(--xl-font-size);
          font-family: $font-bold;
          font-weight: 700;
          color: var(--main-text-color);
          text-transform: capitalize;
        }

        h2 {
          font-size: var(--m-font-size);
          font-family: $font-light;
          font-weight: 300;
          color: var(--secondary-subtitle-text-color);
        }
      }

      .program__ur__weeks-wrapper {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        gap: 90px;
        .program__ur__weeks-modules {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          // padding: 0px var(--inside-padding);
          gap: 40px;
          @media only screen and (max-width: 1050px) {
            justify-content: center;
          }
          .content__card {
            position: relative;
            width: 100%;
            padding: 30px 30px;
            min-height: 310px;
            padding-bottom: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            gap: 10px;
            border-radius: 13px;
            background-color: rgba($color: #e6e7e8, $alpha: 0.5);
            border: 2px solid rgba($color: #fff, $alpha: 0.7);
            &.blue-outline {
              border: 2px solid rgba($color: #40b1e5, $alpha: 0.7);
            }
            &.red-outline {
              border: 2px solid rgba($color: #f15b5b, $alpha: 0.7);
            }
            &.purple-outline {
              border: 2px solid rgba($color: #9f64a8, $alpha: 0.7);
            }
            &.yellow-outline {
              border: 2px solid rgba($color: #ffb000, $alpha: 0.7);
            }
            .content__card-wrapper {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-direction: column;
              gap: 40px;
              z-index: 5;
              .content-seperator {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 7px;
                .content__card-title-cont {
                  position: relative;
                  h1 {
                    font-size: var(--l-font-size);
                    color: var(--main-color);
                    font-family: $font-bold;
                    font-weight: 700;
                    @media only screen and (max-width: 992px) {
                      font-size: var(--m-font-size);
                    }
                  }
                  div {
                    position: absolute;
                    left: -10px;
                    top: 0;
                    width: 5px;
                    height: 100%;
                    background-color: var(--main-color);
                  }
                  div {
                    left: -15px;
                  }
                }
                p {
                  font-family: $font-medium;
                  font-weight: 500;
                  color: var(--secondary-subtitle-text-color);
                  font-size: var(--s-font-size);
                  @media only screen and (max-width: 992px) {
                    font-size: var(--xs-font-size);
                  }
                }
                h2 {
                  font-family: $font-bold;
                  font-weight: 700;
                  font-size: var(--s-font-size);
                  color: $secondary-subtitle-text-color;
                }
              }
            }
            .language-img {
              position: absolute;
              right: -20px;
              bottom: -20px;
              max-width: 215px;
              height: 130px;
              overflow: hidden;
              z-index: 1;
              opacity: 1;
              @media only screen and (max-width: 992px) {
                right: 5px;
                bottom: 5px;
                opacity: 0.2;
              }
              img {
                height: 100%;
                object-fit: contain;
                aspect-ratio: 5/5;
              }
            }
          }
        }
        .program__ur__weeks-info {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 50px;

          .plan__program-info-titles {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            gap: 0px;
            h1 {
              font-size: var(--xl-font-size);
              font-family: $font-bold;
              font-weight: 700;
              text-align: center;
            }
            h3 {
              font-size: var(--m-font-size);
              font-family: $font-light;
              font-weight: 300;
              color: var(--secondary-dark-body-color);
              text-align: center;
            }
          }
          .plan__program-info-desc {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: 10px;
            div {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 10px;
              p {
                font-size: var(--m-font-size);
                font-family: $font-light;
                font-weight: 300;
                color: var(--secondary-subtitle-text-color);
                text-align: center;
              }
              span {
                font-size: var(--m-font-size);
                font-family: $font-medium;
                font-weight: 500;
                color: var(--secondary-subtitle-text-color);
                text-align: center;
              }
              h1 {
                font-size: var(--l-font-size);
                font-family: $font-bold;
                font-weight: 700;
                color: var(--secondary-dark-body-color);
                text-align: center;
              }
              .plan__program-info-options {
                width: 100%;

                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: center;
                gap: 0px;
                flex-wrap: wrap;
                h3 {
                  font-size: var(--xs-font-size);
                  font-family: $font-bold;
                  font-weight: 700;
                  color: var(--main-text-color);
                  text-align: center;
                  padding: 20px 10px;
                  background-color: var(--secondary-dark-body-color);
                  border-radius: 100px;
                  letter-spacing: 0.5px;
                  &:first-child {
                    border-radius: 100px 0px 0px 100px;
                    padding-left: 15px;
                    @media only screen and (max-width: 992px) {
                      width: 65%;
                      border-radius: 20px 20px 0px 0px;
                    }
                  }
                  &:nth-child(2) {
                    border-radius: 0px 100px 100px 0px;
                    padding-right: 15px;
                    @media only screen and (max-width: 992px) {
                      width: 65%;

                      border-radius: 0px 0px 20px 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .programs__content-register {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px var(--inside-padding);
    padding-bottom: 140px;
    @media only screen and (max-width: 992px) {
      padding-bottom: 65px;
    }
    .programs__content-register-wrapper {
      position: relative;
      width: 70%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      padding: 35px var(--inside-padding);
      min-height: 300px;
      border-radius: 15px;
      background-color: rgba($color: #fff, $alpha: 0.7);
      outline: 1px solid rgba($color: #9f64a8, $alpha: 1);
      @media only screen and (max-width: 992px) {
        width: 85%;
        min-height: 220px;
      }
      @media only screen and (max-width: 692px) {
        width: 100%;
        min-height: 200px;
      }

      .programs__content-register-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 5;
        gap: 5px;
        @media only screen and (max-width: 992px) {
          h1 {
            font-size: var(--m-font-size) !important;
          }
          p {
            font-size: var(--s-font-size) !important;
          }
          button {
            margin-top: 10px !important;
          }
        }
        h1 {
          font-size: var(--l-font-size);
          font-family: $font-bold;
          color: var(--main-secondary-text-color);
          font-weight: 700;
          text-align: center;
        }
        p {
          font-size: var(--m-font-size);
          font-family: $font-light;
          color: var(--secondary-subtitle-text-color);
          font-weight: 300;
          text-align: center;
        }
        button {
          margin-top: 25px;
        }
      }
    }
  }
}

.footer__programs-content {
  padding-top: 30px;
}
