.register__hero {
  width: 100%;
  padding-top: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: $secondary-dark-color;
  .register__hero-container {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-around;
    gap: 60px;
    padding: 0px var(--inside-padding);
    @media only screen and (max-width: 992px) {
      padding: 130px var(--inside-padding);
      .register__hero-content {
        align-items: center !important;
        max-width: 70% !important;
        h1,
        p {
          text-align: center !important;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url("assets/images/illustrations/image5.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.2;
        z-index: 0;
      }
      .register__hero-img {
        display: none;
      }
    }
    .register__hero-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      max-width: 40%;
      z-index: 1;
      @media only screen and (max-width: 500px) {
        max-width: 100% !important;
      }
      h1 {
        font-size: var(--xl-font-size);
        font-family: $font-bold;
        font-weight: 700;
        color: var(--secondary-light-red);
      }
      p {
        font-size: var(--m-font-size);
        font-family: $font-light;
        font-weight: 300;
        color: var(--main-secondary-text-color);
      }
    }
    .register__hero-img {
      max-width: 400px;
      width: 100%;
      overflow: hidden;
      transform: translate(0px, 70px);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
