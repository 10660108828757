.about__video {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0px 150px;
  background-color: #fff;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    background-color: var(--footer-color);
    width: 100%;
    height: 40%;
    z-index: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    background-color: black;
    width: 100%;
    height: 45%;
    z-index: 0;
    background: url("assets/vectors/streight_vector_down.svg");
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  @media only screen and (max-width: 992px) {
    padding: 150px 0px;
  }
  @media only screen and (max-width: 692px) {
    padding: 50px 0px;
  }
  .about__video-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px var(--inside-padding);
    z-index: 5;
  }
}

.video__wrapper {
  // max-width: 1230px;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease-in;
  border-radius: 25px;

  @media only screen and (max-width: 992px) {
    border-radius: 15px;
  }
  @media only screen and (min-width: 1400px) {
    &.video__playing {
      transform: scale(1.3);
      transition: 0.15s ease-in !important;
    }
  }
  svg {
    width: 5em;
    height: 5em;
  }
  video {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: 0px 0.8px 2.2px rgba(0, 0, 0, 0.017),
      0px 2.3px 5.3px rgba(0, 0, 0, 0.024), 0px 4.9px 10px rgba(0, 0, 0, 0.03),
      0px 9.5px 17.9px rgba(0, 0, 0, 0.036),
      0px 18.6px 33.4px rgba(0, 0, 0, 0.043), 0px 44px 80px rgba(0, 0, 0, 0.06);
  }
  .video__button-wrapper {
    position: absolute;
    flex: 1;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    z-index: 1;
  }
  .play__button {
    position: absolute;
    width: 139px;
    height: 139px;
    border: none;
    background-color: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    z-index: 5;
    padding-left: 5px;
    opacity: 100;
    // Button hide when video playing
    &.video__playing {
      opacity: 0 !important;
    }
    &.hide-btn {
      opacity: 0 !important;
      cursor: unset !important;
      pointer-events: none !important;
    }
    @media only screen and (max-width: 992px) {
      width: 15vw;
      height: 15vw;
      padding-left: 0px;
      svg {
        width: 6vw;
        height: 6vw;
      }
    }
    @media only screen and (min-width: 992px) {
      &:hover {
        backdrop-filter: blur(1.5px);
        -webkit-backdrop-filter: blur(1.5px);
        background-color: rgba($color: #000000, $alpha: 0.45);
        border-radius: 70%;
        transform: scale(0.9);
      }
      &:active {
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        background-color: rgba($color: #000000, $alpha: 0.3);
        transform: scale(1);
      }
    }
  }
}
