.plan__program-table-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // flex-direction: column;
  gap: 30px;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  .plan__program-table-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .plan__program-table-titles {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0px;
      h4 {
        font-size: var(--s-font-size);
        color: var(--secondary-dark-body-color);
        font-family: $font-light;
        font-weight: 300;
        text-align: center;
      }
      h1 {
        font-size: var(--l-font-size);
        color: var(--secondary-dark-body-color) !important;
        font-family: $font-bold;
        text-align: center;
        font-weight: 700;
      }
    }
    .plan__program-table {
      width: 100%;
      max-width: 555px;
      border-collapse: collapse;

      .plan__program-table-head {
        tr {
          th {
            border: 1px solid rgba($color: #0c1b2b, $alpha: 0.3);
            background-color: var(--secondary-dark-body-color);

            padding: 5px 10px;
            h2 {
              font-size: var(--s-font-size);
              color: var(--main-text-color);
              font-family: $font-medium;
              font-weight: 500;
            }
            &:first-child {
              background-color: transparent;
              border: none;
            }
          }
        }
      }
      .plan__program-table-body {
        background-color: rgba($color: #152e4a, $alpha: 0.1);
        border-left: 1px solid rgba($color: #0c1b2b, $alpha: 0.3);
        tr {
          td {
            padding: 5px 10px;
            border-bottom: 1px solid rgba($color: #0c1b2b, $alpha: 0.3);
            border-right: 1px solid rgba($color: #0c1b2b, $alpha: 0.3);
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            div {
              width: 43px;
              height: 25px;
              overflow: hidden;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              justify-content: center;
              @media only screen and (max-width: 500px) {
                display: none;
              }
              img {
                height: 100%;
                object-fit: contain;
              }
            }
            h2 {
              font-size: var(--xs-font-size);
              color: var(--secondary-dark-body-color);
              font-family: $font-medium;
              font-weight: 500;
            }
            &:first-child {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              gap: 10px;
            }
            &:nth-child(2) {
              h2 {
                text-align: center;
                font-weight: 700;
                font-family: $font-bold;
              }
            }
            &:nth-child(3) {
              h2 {
                text-align: center;
                font-weight: 700;
                font-family: $font-bold;
              }
              padding: 5px 40px;
              @media only screen and (max-width: 430px) {
                padding: 5px 5px;
              }
            }
          }
        }
      }
    }
  }
}
