@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  padding: 120px 0px 0px;
  min-height: 80vh;
  background: url("assets/vectors/bloody_vector_up_blue.svg") repeat var(--footer-color);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 800px;
}
.contact .contact__container {
  max-width: 1230px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px var(--inside-padding);
  gap: 50px;
}
@media only screen and (max-width: 992px) {
  .contact .contact__container {
    gap: 25px;
  }
  .contact .contact__container h1,
  .contact .contact__container h2,
  .contact .contact__container p {
    text-align: center;
  }
}
.contact .contact__container .contact__desc {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.contact .contact__container .contact__desc p {
  font-size: var(--s-font-size);
  color: var(--main-secondary-text-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  line-height: 1.6;
}
.contact .contact__container .contact__desc p span {
  font-family: "Poppins-Light";
  font-weight: 300;
}
.contact .contact__container .contact__title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.contact .contact__container .contact__title h1 {
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.contact .contact__container .contact__title h2 {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
}

.contact-footer {
  padding-top: 130px;
}

.contact__info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.contact__info .contact__svg {
  width: 50%;
  padding-bottom: 70px;
}
.contact__info .contact__svg img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 992px) {
  .contact__info {
    justify-content: center !important;
    gap: 30px;
  }
  .contact__info .contact__svg {
    display: none;
  }
}
.contact__info .contact__inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: clamp(500px, 100%, 200px);
  align-items: center;
  justify-content: center;
}
.contact__info .contact__inputs .number {
  height: 71px;
  width: 100%;
  display: flex;
  border-radius: 5px !important;
  padding: 3px;
}
.contact__info .contact__inputs .number input {
  width: 100%;
  height: 100%;
  border-radius: inherit !important;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--main-text-color) !important;
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 1px;
  padding-left: 5px;
  border: 0.5px solid #00416b;
}
.contact__info .contact__inputs .number input::-moz-placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  -moz-transition: 0.25s all ease-in-out;
  transition: 0.25s all ease-in-out;
}
.contact__info .contact__inputs .number input::placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  transition: 0.25s all ease-in-out;
}
.contact__info .contact__inputs .number input:focus {
  border: 2px solid var(--secondary-dark-color);
  border-radius: 5px !important;
  padding-left: 10px !important;
}
.contact__info .contact__inputs .number input:focus::-moz-placeholder {
  color: var(--main-color);
}
.contact__info .contact__inputs .number input:focus::placeholder {
  color: var(--main-color);
}
@media only screen and (min-width: 992px) {
  .contact__info .contact__inputs .number input:hover {
    border-radius: 15px !important;
    color: var(--main-color);
  }
  .contact__info .contact__inputs .number input:hover::-moz-placeholder {
    color: var(--main-color);
    -moz-transition: 0.25s all ease-in-out;
    transition: 0.25s all ease-in-out;
  }
  .contact__info .contact__inputs .number input:hover::placeholder {
    color: var(--main-color);
    transition: 0.25s all ease-in-out;
  }
}
.contact__info .contact__inputs .number select {
  background-color: transparent;
  border: none;
  background-color: #00395e;
  border-radius: 4px;
  color: var(--main-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: var(--s-font-size);
  outline: none;
  padding: 0px 10px;
}
.contact__info .contact__inputs .contact__input {
  width: 100%;
  height: 71px;
  border-radius: 5px !important;
  border: none;
  background-color: var(--secondary-text-color);
  outline: none;
  color: var(--main-text-color) !important;
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 1px;
  padding-left: 5px;
  border: 0.5px solid #00416b;
}
.contact__info .contact__inputs .contact__input::-moz-placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  -moz-transition: 0.25s all ease-in-out;
  transition: 0.25s all ease-in-out;
}
.contact__info .contact__inputs .contact__input::placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  transition: 0.25s all ease-in-out;
}
.contact__info .contact__inputs .contact__input:focus {
  border: 2px solid var(--secondary-dark-color);
  border-radius: 5px !important;
  padding-left: 10px !important;
}
.contact__info .contact__inputs .contact__input:focus::-moz-placeholder {
  color: var(--main-color);
}
.contact__info .contact__inputs .contact__input:focus::placeholder {
  color: var(--main-color);
}
@media only screen and (min-width: 992px) {
  .contact__info .contact__inputs .contact__input:hover {
    border-radius: 15px !important;
    color: var(--main-color);
  }
  .contact__info .contact__inputs .contact__input:hover::-moz-placeholder {
    color: var(--main-color);
    -moz-transition: 0.25s all ease-in-out;
    transition: 0.25s all ease-in-out;
  }
  .contact__info .contact__inputs .contact__input:hover::placeholder {
    color: var(--main-color);
    transition: 0.25s all ease-in-out;
  }
}
.contact__info .contact__inputs .email {
  border-radius: 20px 20px 5px 5px !important;
}
.contact__info .contact__inputs .message {
  transition: none;
  resize: vertical;
  min-height: 220px;
  max-height: 400px;
  padding-top: 7px;
  transition: 0.1s;
}
.contact__info .contact__inputs .contact__btn {
  width: 100%;
  border-radius: 5px 5px 20px 20px;
}
.contact__info .contact__inputs .contact__btn::after {
  border-radius: inherit !important;
}

.programs__contact-container .contact__info .contact__inputs .contact__input::-moz-placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  -moz-transition: 0.25s all ease-in-out;
  transition: 0.25s all ease-in-out;
}

.programs__contact-container .contact__info .contact__inputs .contact__input::placeholder {
  color: var(--main-text-color);
  font-size: var(--s-font-size);
  font-weight: 500;
  font-family: "Poppins-Light";
  letter-spacing: 0.5px;
  transition: 0.25s all ease-in-out;
}
.programs__contact-container .contact__info .contact__inputs .contact__input:focus {
  border: 2px solid var(--secondary-dark-color);
  border-radius: 5px !important;
  padding-left: 10px !important;
}
.programs__contact-container .contact__info .contact__inputs .contact__input:focus::-moz-placeholder {
  color: var(--secondary-light-red);
}
.programs__contact-container .contact__info .contact__inputs .contact__input:focus::placeholder {
  color: var(--secondary-light-red);
}
@media only screen and (min-width: 992px) {
  .programs__contact-container .contact__info .contact__inputs .contact__input:hover {
    border-radius: 15px !important;
    color: var(--secondary-light-red);
  }
  .programs__contact-container .contact__info .contact__inputs .contact__input:hover::-moz-placeholder {
    color: var(--secondary-light-red);
    -moz-transition: 0.25s all ease-in-out;
    transition: 0.25s all ease-in-out;
  }
  .programs__contact-container .contact__info .contact__inputs .contact__input:hover::placeholder {
    color: var(--secondary-light-red);
    transition: 0.25s all ease-in-out;
  }
}

.contact__contrast-color {
  background-color: var(--secondary-dark-color) !important;
  border: 0.5px solid var(--secondary-dark-color) !important;
}
.contact__contrast-color:focus {
  border: 3px solid var(--secondary-dark-color) !important;
}
.contact__contrast-color:hover::-moz-placeholder {
  color: var(--main-color) !important;
}
.contact__contrast-color:hover::placeholder {
  color: var(--main-color) !important;
}

.contact__bloody-svg {
  width: 100%;
  height: 110px;
  background-color: var(--secondary-dark-color);
  font-size: 0;
  padding: 0;
  margin: 0;
}

.contact__nav-links li a::before {
  background-color: var(--secondary-dark-color) !important;
}
.contact__nav-links .active {
  color: var(--secondary-dark-color) !important;
}/*# sourceMappingURL=contactStyle.css.map */