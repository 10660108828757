.article__register {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  background-color: #fff;
  overflow-x: hidden;
  @media only screen and (max-width: 992px) {
    padding: 10px 0px 100px;
  }
  .article__register-container {
    padding: 0px var(--inside-padding);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    p {
      font-size: var(--m-font-size);
      font-weight: 300;
      font-family: $font-light;
      color: var(--secondary-dark-body-color);
      text-align: center;
      max-width: 75%;
      @media only screen and (max-width: 750px) {
        max-width: 100%;
      }
    }
  }
}
