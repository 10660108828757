a,
a:visited,
a:focus,
a:active,
a:checked,
li,
ul,
ol {
  text-decoration: none;
  color: var(--main-text-color);
  list-style-type: none;
  list-style: none;
  margin: 0;
}

// Given the h1 property as a Title
h1 {
  // font-size: var(--l-font-size);
  // font-weight: 700;
  color: var(--main-text-color);
  font: normal normal 700 $l-font-size $font-bold;
  margin: 0;
}

// Given the h12 property as a Subtitle
h2 {
  // font-size: var(--l-font-size);
  // font-weight: 300;
  // font-family: $font-light;
  color: var(--main-text-color);
  font: normal normal 300 $l-font-size $font-light;
  margin: 0;
}
h3 {
  // font-size: var(--m-font-size);
  // font-weight: 300;
  // font-family: $font-light;
  color: var(--main-text-color);
  font: normal normal 300 $m-font-size $font-light;
  margin: 0;
}

p {
  // font-size: var(--l-font-size);
  // font-weight: 500;
  // font-family: $font-medium;
  font: normal normal 500 $l-font-size $font-medium;
  color: var(--main-text-color);
  margin: 0;
}
