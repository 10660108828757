.media__cards-section {
  width: 100%;
  padding: 130px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color);
  @media only screen and (max-width: 992px) {
    padding: 50px 0px 130px;
  }
  .media__cards-wrapper {
    width: 100%;
    padding: 0px var(--inside-padding);
    display: grid;
    gap: 30px 20px;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    place-content: center;
    @media only screen and (min-width: 992px) {
      align-items: flex-start;
      justify-content: center;
    }
    @media only screen and (max-width: 992px) {
      max-width: 800px !important;
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 550px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}
.media__cards {
  max-width: 355px;
  width: 100%;
  // min-height: 420px;
  height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--footer-color);
  outline: 2px solid rgba($color: $main-color, $alpha: 1);
  border-radius: 15px 15px 9px 9px;
  overflow: hidden;
  padding-bottom: 10px;
  transition: 0.5s ease-in;
  @media only screen and (max-width: 550px) {
    min-height: 450px;
    .media__cards-img {
      min-height: 130px !important;
      max-height: 130px !important;
      height: 130px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    outline: 2px solid rgba($color: $main-color, $alpha: 0);
    box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: 0.3);
    padding-bottom: 0px;
    .media__cards-content {
      padding: 0px !important;
      h1 {
        color: var(--secondary-light-red) !important;
      }
      p {
        max-height: 100% !important;
        line-clamp: 7 !important;
        -webkit-line-clamp: 7 !important;
      }
      span {
        width: 20% !important;
        background-color: var(--secondary-light-red) !important;
      }
    }
    .media__cards-container {
      padding: 10px !important;
      .media__cards-button {
        bottom: 15px !important;
        h2 {
          color: var(--secondary-light-red) !important;
          font-weight: bold !important;
          &::after {
            background-color: var(--secondary-light-red) !important;
            transition: 0.2ms;
          }
        }
        a {
          background-color: rgba(
            $color: $secondary-light-red,
            $alpha: 1
          ) !important;
        }
      }
      .media__cards-img {
        outline: solid 2px rgba($color: $secondary-light-red, $alpha: 1) !important;
        border-radius: 20px 20px 150px 15px !important;
        min-height: 30% !important;
        max-height: 30% !important;
        height: 30% !important;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    &:hover {
      outline: 2px solid rgba($color: $main-color, $alpha: 0);
      box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: 0.3);
      padding-bottom: 0px;
      .media__cards-content {
        padding: 0px !important;
        h1 {
          color: var(--secondary-light-red) !important;
        }
        p {
          height: 150px;
          // max-height: 100% !important;
          line-clamp: 7 !important;
          -webkit-line-clamp: 7 !important;
        }
        span {
          width: 20% !important;
          background-color: var(--secondary-light-red) !important;
        }
      }
      .media__cards-container {
        padding: 10px !important;
        height: 100%;
        .media__cards-button {
          bottom: 15px;
          left: 10px !important;
          h2 {
            color: var(--secondary-light-red) !important;
            font-weight: bold !important;
            // font-family: $font-medium !important;
            &::after {
              background-color: var(--secondary-light-red) !important;
              transition: 0.2ms;
            }
          }
          a {
            background-color: rgba(
              $color: $secondary-light-red,
              $alpha: 1
            ) !important;
          }
        }
        .media__cards-img {
          outline: solid 2px rgba($color: $secondary-light-red, $alpha: 1) !important;
          border-radius: 20px 20px 150px 15px !important;
          min-height: 30% !important;
          max-height: 30% !important;
          height: 30% !important;
        }
      }
    }
  }

  .media__cards-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    padding: 0px;
    .media__cards-button {
      position: absolute;
      bottom: 0;
      left: 10px;
      width: 90%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      h2 {
        position: relative;
        color: var(--main-secondary-text-color);
        font-weight: 300;
        font-family: $font-light;
        font-size: var(--xs-font-size);
        padding: 0px 2.5px;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: var(--main-color);
          height: 2px;
          border-radius: 5px;
          transition: 0.2ms;
        }
      }
      a {
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: left;
        font-size: var(--xs-font-size);
        font-family: $font-light;
        cursor: pointer;
        border-radius: 15px;
        &:hover {
          padding: 10px 15px !important;
        }
      }
    }
  }

  .media__cards-img {
    width: 100%;
    height: 100%;
    min-height: 45%;
    max-height: 45%;
    overflow: hidden;
    outline: solid 2px rgba($color: $secondary-light-red, $alpha: 0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .media__cards-content {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    h1 {
      font-size: 0.97rem;
      font-weight: 700;
      font-family: $font-bold;
      color: rgba($color: $main-color, $alpha: 1);
      overflow-y: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    span {
      width: 80%;
      background-color: rgba($color: $main-color, $alpha: 1);
      height: 2px;
      border-radius: 20px;
    }
    p {
      font-size: var(--xs-font-size);
      font-family: $font-light;
      font-weight: 300;
      color: var(--main-secondary-text-color);
      overflow-y: hidden;
      height: 107px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      line-clamp: 5;
      -webkit-box-orient: vertical;
      // @media only screen and (max-width: 550px) {
      //   -webkit-line-clamp: unset !important; /* number of lines to show */
      //   line-clamp: unset !important;
      // }
    }
  }
}
