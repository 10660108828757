.article__separator {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
  background: url("assets/images/article/separator/bg.webp");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    backdrop-filter: blur(4px);
    z-index: 0;
  }
  .article__separator-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    z-index: 5;
    padding: 0px var(--inside-padding);
    p {
      max-width: 70%;
      font-size: var(--m-font-size);
      color: var(--main-text-color);
      font-family: $font-light;
      font-weight: 300;
      text-align: center;
      @media only screen and (max-width: 652px) {
        max-width: 90%;
      }
    }
  }
}
