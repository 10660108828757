@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
:root {
  --xl-font-size: 1.75rem;
  --l-font-size: 1.4375rem;
  --m-font-size: 1.125rem;
  --s-font-size: 1rem;
  --xs-font-size: 0.875rem;
  --main-text-color: #fff;
  --main-secondary-text-color: #e6e7e8;
  --main-secondary-text-color-2: #e7edfb;
  --secondary-color: #ffb000;
  --main-color: #40b1e5;
  --secondary-body-color: #e7edfb;
  --secondary-text-color: #333;
  --secondary-dark-color: #00416b;
  --secondary-light-blue: #4fabb8;
  --secondary-light-red: #f15b5b;
  --secondary-purple-color: #9f64a8;
  --secondary-light-green-color: #8dc63f;
  --secondary-subtitle-text-color: #5e5e5e;
  --secondary-dark-body-color: #0c1b2b;
  --disabled-color: #5e5e5e;
  --footer-color: #152e4a;
  --mobile-padding: 20px;
  --desktop-padding: 50px;
  --inside-padding: 15px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: 0.25s all ease-in-out;
}

body {
  background-color: var(--main-text-color);
  overflow-x: hidden;
}

.container {
  max-width: 1075px !important;
}

.display-none {
  display: none !important;
}

::-webkit-scrollbar {
  background: var(--secondary-text-color);
  width: 0.6rem;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-text-color);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--main-text-color);
  background: #619da1;
}

::selection {
  color: var(--main-text-color);
  background: #619da1;
}

a,
a:visited,
a:focus,
a:active,
a:checked,
li,
ul,
ol {
  text-decoration: none;
  color: var(--main-text-color);
  list-style-type: none;
  list-style: none;
  margin: 0;
}

h1 {
  color: var(--main-text-color);
  font: normal normal 700 1.4375rem "Poppins-Bold";
  margin: 0;
}

h2 {
  color: var(--main-text-color);
  font: normal normal 300 1.4375rem "Poppins-Light";
  margin: 0;
}

h3 {
  color: var(--main-text-color);
  font: normal normal 300 1.125rem "Poppins-Light";
  margin: 0;
}

p {
  font: normal normal 500 1.4375rem "Poppins-Medium";
  color: var(--main-text-color);
  margin: 0;
}

.btn {
  width: 200px;
  min-height: 50px;
  height: 50px;
  flex-grow: 0;
  text-align: center;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-text-color);
  letter-spacing: 0.2px;
  z-index: 2;
  overflow: hidden;
  transition: 0.15s ease-in all;
}
.btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: inherit;
  transition: 0.15s ease-in;
  border: none;
  outline: none;
}
.btn:active {
  background-color: #d69d17;
  border-radius: 13px !important;
}
.btn:active::after {
  background-color: rgba(2, 48, 79, 0.431372549);
}
.btn:focus {
  background-color: #d69d17;
  border-radius: 13px !important;
}
.btn:focus::after {
  background-color: rgba(2, 48, 79, 0.431372549);
}
@media only screen and (max-width: 992px) {
  .btn {
    width: 170px !important;
    height: 45px !important;
    font-size: var(--s-font-size) !important;
    border-radius: 10px !important;
  }
}
@media only screen and (min-width: 992px) {
  .btn:hover {
    transform: scale(1.05);
  }
  .btn:hover::after {
    opacity: 1;
  }
}
.btn a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
}

.disabled-btn {
  background-color: var(--disabled-color) !important;
  pointer-events: none !important;
  cursor: pointer;
}
.disabled-btn:active {
  margin-top: 0;
  letter-spacing: 0.5px;
}
.disabled-btn:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  cursor: default;
}
.disabled-btn:hover::after {
  opacity: 0;
}
.disabled-btn:active {
  background-color: var(--disabled-color) !important;
}

.blue-btn {
  background-color: var(--main-color) !important;
}
.blue-btn:active {
  background-color: #309bcd !important;
}

.red-btn {
  background-color: var(--secondary-light-red) !important;
}
.red-btn:active {
  background-color: #db4e4e !important;
}

.yellow-btn {
  background-color: var(--secondary-color) !important;
}
.yellow-btn:active {
  background-color: #d69f1e !important;
}

.green-btn {
  background-color: var(--secondary-light-green-color) !important;
}
.green-btn:active {
  background-color: #44939e !important;
}

.lightBlue-btn {
  background-color: var(--secondary-light-blue) !important;
}
.lightBlue-btn:active {
  background-color: #44939e !important;
}

.purple-btn {
  background-color: var(--secondary-purple-color) !important;
}
.purple-btn:active {
  background-color: #844f8c !important;
}

.button-width {
  width: 100% !important;
}

.m-btn {
  width: 170px !important;
  min-height: 45px !important;
  height: 45px !important;
  border-radius: 10px;
  font-size: var(--s-font-size) !important;
}

.s-btn {
  width: 150px !important;
  min-height: 40px !important;
  height: 40px !important;
  border-radius: 10px;
  font-size: var(--xs-font-size) !important;
}

.xs-btn {
  width: 130px !important;
  min-height: 35px !important;
  height: 35px !important;
  border-radius: 5px !important;
  font-size: var(--xs-font-size) !important;
}

.register-btn {
  width: 245px;
  height: 55px;
  flex-grow: 0;
  text-align: center;
  border: none;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
  border: solid 1px var(--secondary-text-color);
  border-radius: 15px 15px 0px 15px;
}
.register-btn:before {
  content: "";
  border-radius: 15px 15px 0px 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
@media only screen and (min-width: 992px) {
  .register-btn:hover::before {
    opacity: 100;
  }
}

.scroll-to-top-btn {
  position: fixed;
  padding: 15px 10px;
  margin-bottom: 10px;
  border-radius: 15px 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  transition: 0.4s ease-in;
  background-color: #0c1b2b;
  z-index: 99;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right-width: 0px;
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .scroll-to-top-btn {
    background-color: rgba(12, 27, 43, 0.8);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
  }
}
@media only screen and (min-width: 992px) {
  .scroll-to-top-btn {
    width: 60px;
    height: 45px;
    margin-bottom: 25px;
    padding: 0px;
    border-radius: 10px 0px 0px 10px;
  }
  .scroll-to-top-btn:hover {
    background-color: var(--footer-color);
  }
}
@media only screen and (max-width: 550px) {
  .scroll-to-top-btn {
    padding: 10px 8px;
  }
  .scroll-to-top-btn .scroll-btn,
  .scroll-to-top-btn .register-scroll-btn {
    width: 30px !important;
    height: 30px !important;
  }
}

.hidden-scroll {
  right: -10rem;
  bottom: 0;
}

.visible-scroll {
  right: 0;
  bottom: 0;
}

.scroll-btn {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  border: 1.7px solid var(--main-color);
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  .scroll-btn {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
  }
  .scroll-btn svg {
    color: var(--main-text-color) !important;
  }
}
.scroll-btn svg {
  width: 17px;
  height: 17px;
  color: var(--main-color);
}
@media only screen and (max-width: 550px) {
  .scroll-btn svg {
    width: 15px;
    height: 15px;
  }
}

.register-scroll-btn {
  display: none;
}

@media only screen and (max-width: 992px) {
  .register-scroll-btn {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border: 1.7px solid var(--secondary-color);
    cursor: pointer;
  }
  .register-scroll-btn svg {
    width: 20px;
    height: 20px;
    color: var(--secondary-color);
  }
}
@media only screen and (max-width: 992px) and (max-width: 550px) {
  .register-scroll-btn svg {
    width: 17px;
    height: 17px;
  }
}
.socials__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px;
}
.socials__container .socials__img {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 7px;
}
.socials__container .socials__img a {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(230, 231, 232, 0.75);
  transition: 0.35s ease-in-out;
}
.socials__container .socials__img a svg {
  width: 20px;
  height: 20px;
  transition: 0.2s ease-in-out;
  color: var(--main-text-color);
  align-self: center;
}
@media only screen and (min-width: 992px) {
  .socials__container .socials__img a:hover {
    background-color: white;
  }
  .socials__container .socials__img a:hover svg {
    color: var(--secondary-text-color);
    transition: 0.2s ease-in-out;
    transform: scale(1.08);
  }
}
.socials__container .socials__credits p {
  font-size: var(--xs-font-size);
  color: var(--main-secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
  text-align: center;
}

.socials__img.sm-socials a {
  width: 30px;
  height: 30px;
}
.socials__img.sm-socials a svg {
  width: 10px;
  height: 10px;
}

.socials__img.lg-socials a {
  width: 35px;
  height: 35px;
}
.socials__img.lg-socials a svg {
  width: 20px;
  height: 20px;
}

.root__modal-overlay {
  padding: 0px var(--inside-padding);
  z-index: 999999;
}

.root__modal-content {
  position: relative;
  margin: auto;
  background: var(--secondary-dark-color);
  max-width: 750px;
  min-height: 320px;
  width: 100%;
  padding: 5px;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 25px 10px;
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  box-shadow: 0px 1.4px 2.1px rgba(0, 0, 0, 0.032), 0px 4.7px 6.9px rgba(0, 0, 0, 0.048), 0px 21px 31px rgba(0, 0, 0, 0.08);
}
.root__modal-content .root__modal-close {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
}
.root__modal-content .root__modal-close svg {
  color: var(--main-text-color);
}
@media only screen and (min-width: 992px) {
  .root__modal-content .root__modal-close:hover svg {
    opacity: 0.55;
  }
}
.root__modal-content h1 {
  font-size: var(--l-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
  text-align: center;
}
.root__modal-content p {
  max-width: 75%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .root__modal-content {
    max-width: 550px;
    min-height: 250px;
  }
  .root__modal-content h1 {
    font-size: var(--m-font-size);
  }
  .root__modal-content p {
    font-size: var(--s-font-size);
  }
}

.root__modal-arrow {
  color: rgb(255, 255, 255);
}

.root__modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}

@keyframes anvil {
  0% {
    transform: scale(0.5) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  50% {
    transform: scale(0.7) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.yellow__modal-content {
  background-image: linear-gradient(112deg, rgba(0, 65, 107, 0.4) 20%, rgba(248, 180, 26, 0.4) 100%, rgba(0, 65, 107, 0.4) 80%);
  background-size: 200% 200%;
  background-position: top left;
  animation: gradientAnimation 10s ease-in infinite;
}

.red__modal-content {
  background-image: linear-gradient(115deg, rgba(0, 65, 107, 0.4) 20%, rgba(241, 91, 91, 0.4) 100%, rgba(0, 65, 107, 0.4) 20%);
  background-size: 200% 200%;
  background-position: top left;
  animation: gradientAnimation 10s ease-in infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}
.dropdown__container {
  width: 100%;
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
}
.dropdown__container .dropdown__input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  overflow: hidden;
}
.dropdown__container .dropdown__input:focus {
  border: 1px solid rgba(64, 177, 229, 0.5) !important;
  padding: 10px !important;
  background-color: rgba(var(--footer-color), 0.5) !important;
}
.dropdown__container .dropdown__input:focus h1 {
  color: var(--main-secondary-text-color) !important;
  -webkit-text-fill-color: var(--main-color) !important;
  transition: 0.3s ease-in-out;
}
@media only screen and (min-width: 992px) {
  .dropdown__container .dropdown__input:hover {
    border: 1.7px solid var(--main-color);
    transition: 0.1s ease-out;
  }
  .dropdown__container .dropdown__input:hover h1 {
    padding-left: 10px;
    color: var(--main-color) !important;
    -webkit-text-fill-color: var(--main-color) !important;
    transition: 0.3s ease-in-out;
  }
}
.dropdown__container .dropdown__input h1 {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-secondary-text-color) !important;
  -webkit-text-fill-color: var(--main-secondary-text-color) !important;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 680px) {
  .dropdown__container .dropdown__input h1 {
    font-size: var(--xs-font-size) !important;
  }
}
.dropdown__container .dropdown__menu {
  border-radius: 15px;
  border: 1px solid var(--main-color);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: var(--footer-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dropdown__container .dropdown__menu h1 {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-weight: 500;
  font-family: "Poppins-Medium";
}
.dropdown__container .dropdown__menu .dropdown__item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.about__video {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px 150px;
  background-color: #fff;
}
.about__video::after {
  content: "";
  position: absolute;
  top: 0;
  background-color: var(--footer-color);
  width: 100%;
  height: 40%;
  z-index: 0;
}
.about__video::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: black;
  width: 100%;
  height: 45%;
  z-index: 0;
  background: url("assets/vectors/streight_vector_down.svg");
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 992px) {
  .about__video {
    padding: 150px 0px;
  }
}
@media only screen and (max-width: 692px) {
  .about__video {
    padding: 50px 0px;
  }
}
.about__video .about__video-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px var(--inside-padding);
  z-index: 5;
}

.video__wrapper {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease-in;
  border-radius: 25px;
}
@media only screen and (max-width: 992px) {
  .video__wrapper {
    border-radius: 15px;
  }
}
@media only screen and (min-width: 1400px) {
  .video__wrapper.video__playing {
    transform: scale(1.3);
    transition: 0.15s ease-in !important;
  }
}
.video__wrapper svg {
  width: 5em;
  height: 5em;
}
.video__wrapper video {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow: 0px 0.8px 2.2px rgba(0, 0, 0, 0.017), 0px 2.3px 5.3px rgba(0, 0, 0, 0.024), 0px 4.9px 10px rgba(0, 0, 0, 0.03), 0px 9.5px 17.9px rgba(0, 0, 0, 0.036), 0px 18.6px 33.4px rgba(0, 0, 0, 0.043), 0px 44px 80px rgba(0, 0, 0, 0.06);
}
.video__wrapper .video__button-wrapper {
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  z-index: 1;
}
.video__wrapper .play__button {
  position: absolute;
  width: 139px;
  height: 139px;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  padding-left: 5px;
  opacity: 100;
}
.video__wrapper .play__button.video__playing {
  opacity: 0 !important;
}
.video__wrapper .play__button.hide-btn {
  opacity: 0 !important;
  cursor: unset !important;
  pointer-events: none !important;
}
@media only screen and (max-width: 992px) {
  .video__wrapper .play__button {
    width: 15vw;
    height: 15vw;
    padding-left: 0px;
  }
  .video__wrapper .play__button svg {
    width: 6vw;
    height: 6vw;
  }
}
@media only screen and (min-width: 992px) {
  .video__wrapper .play__button:hover {
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 70%;
    transform: scale(0.9);
  }
  .video__wrapper .play__button:active {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
    transform: scale(1);
  }
}

.footer {
  position: relative;
  width: 100%;
  padding: 250px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--footer-color);
  z-index: 0 !important;
}
@media only screen and (max-width: 992px) {
  .footer {
    padding-top: 17vw;
  }
}
.footer .footer__wrapper {
  width: 100%;
  max-width: 1516px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0px 20px;
}
@media only screen and (max-width: 992px) {
  .footer .footer__wrapper {
    gap: 50px;
  }
}
.footer .footer__wrapper .working__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer .footer__wrapper .working__info h3 {
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-text-color);
}
.footer .footer__wrapper .working__info p {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-secondary-text-color);
}
.footer .footer__wrapper .working__days {
  text-align: center;
}
.footer .footer__wrapper .working__days p span {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footer__wrapper .working__days p span:first-child::after {
  content: "";
  position: absolute;
  bottom: -5px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 2px;
  width: 68%;
  border-radius: 10px;
}
.footer .footer__wrapper .footer__title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
}
.footer .footer__wrapper .footer__title h1 span {
  color: var(--main-color);
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.footer .footer__wrapper .footer__places {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 65px;
}
@media only screen and (min-width: 1190px) {
  .footer .footer__wrapper .footer__places {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-content: center;
    place-items: center;
  }
}
@media only screen and (max-width: 590px) {
  .footer .footer__wrapper .footer__places {
    width: 100%;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (max-width: 992px) {
  .footer .footer__wrapper .footer__places {
    gap: 35px;
  }
}
.footer .footer__wrapper .footer__places .footer__place-card {
  max-width: 253px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
@media only screen and (min-width: 590px) {
  .footer .footer__wrapper .footer__places .footer__place-card {
    min-height: 290px;
  }
}
@media only screen and (max-width: 992px) {
  .footer .footer__wrapper .footer__places .footer__place-card {
    gap: 5px;
  }
}
@media only screen and (max-width: 590px) {
  .footer .footer__wrapper .footer__places .footer__place-card {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .footer .footer__wrapper .footer__places .footer__place-card h2 {
    font-size: var(--l-font-size) !important;
  }
  .footer .footer__wrapper .footer__places .footer__place-card h3,
  .footer .footer__wrapper .footer__places .footer__place-card p {
    font-size: var(--m-font-size) !important;
  }
}
.footer .footer__wrapper .footer__places .footer__place-card h2 {
  font-size: var(--l-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--main-color);
}
.footer .footer__wrapper .footer__info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.footer .footer__wrapper .footer__info .footer__info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul {
  display: flex;
  gap: 10px 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul a {
  font-size: var(--m-font-size);
  position: relative;
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
}
@media only screen and (max-width: 500px) {
  .footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul a {
    font-size: var(--s-font-size) !important;
  }
}
@media only screen and (min-width: 992px) {
  .footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul a:hover {
    color: var(--main-color);
  }
  .footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul a:hover::before {
    width: 100%;
  }
}
.footer .footer__wrapper .footer__info .footer__info-wrapper .footer__nav ul a::before {
  content: "";
  background-color: var(--main-color);
  position: absolute;
  width: 0%;
  height: 1.5px;
  bottom: 0;
  transition: 0.35s all ease-in-out;
}

.footer__vector-hidden {
  display: none;
  background-image: none;
}

.footer__vector-up {
  position: absolute;
  top: -0.9px;
  width: 100%;
  height: 182px;
}

.footer__vector-yellow {
  background-image: url("assets/vectors/bloody_vector_up_yellow.svg");
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-white {
  position: absolute;
  width: 100%;
  height: 182px;
  background-image: url("assets/vectors/bloody_vector_up_white.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-secondWhite {
  background-image: url("assets/vectors/bloody_vector_up_secondWhite.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-secondWhite2 {
  background-image: url("assets/vectors/bloody_vector_up_secondWhite2.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-blue {
  background-image: url("assets/vectors/bloody_vector_up_blue.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-purple {
  background-image: url("assets/vectors/bloody_vector_up_purple.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.footer__vector-red {
  background-image: url("assets/vectors/bloody_vector_up_red.svg") !important;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: top;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  background-color: transparent;
  position: fixed;
  z-index: 999;
  top: -70px;
  flex-direction: column;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
.navbar .nav__container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 2px var(--inside-padding);
}
@media only screen and (max-width: 992px) {
  .navbar .nav__container {
    justify-content: space-between !important;
  }
}
.navbar .nav__container .nav__icon {
  width: 173px;
  height: 48px;
  overflow: hidden;
  cursor: pointer;
  padding-top: 3px;
}
.navbar .nav__container .nav__icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 992px) {
  .navbar .nav__container .nav__icon {
    width: 120px;
  }
}
.navbar .nav__container .nav__menu ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.navbar .nav__container .nav__menu ul li .active:focus {
  color: var(--secondary-light-green-color) !important;
  letter-spacing: unset !important;
}
.navbar .nav__container .nav__menu ul li a {
  word-wrap: normal !important;
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-text-color);
  position: relative;
  display: flex;
  justify-content: center;
}
.navbar .nav__container .nav__menu ul li a:focus {
  color: var(--secondary-light-green-color) !important;
  letter-spacing: 0.02rem !important;
}
@media only screen and (min-width: 992px) {
  .navbar .nav__container .nav__menu ul li a:hover a {
    color: var(--main-color);
  }
  .navbar .nav__container .nav__menu ul li a:hover::before {
    width: 75%;
  }
}
.navbar .nav__container .nav__menu ul li a::before {
  content: "";
  background-color: var(--secondary-color);
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: 0;
  transition: 0.35s all ease-in-out;
}

.nav__menu-mobile {
  display: none;
}

@media only screen and (max-width: 992px) {
  .navbar {
    top: 0;
    height: 135px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar .nav__container .nav__icon {
    height: 60px;
  }
  .nav__collapse {
    width: 40px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .nav__collapse .nav__collapse-btn {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 15px;
    border-radius: 5px;
    transition: 0.35s all ease-in-out;
  }
  .nav__collapse .nav__collapse-btn.blue-color {
    background-color: var(--main-color);
  }
  .nav__collapse .nav__collapse-btn.blue-color::before, .nav__collapse .nav__collapse-btn.blue-color::after {
    background-color: var(--main-color);
  }
  .nav__collapse .nav__collapse-btn.white-color {
    background-color: var(--main-text-color);
  }
  .nav__collapse .nav__collapse-btn.white-color::before, .nav__collapse .nav__collapse-btn.white-color::after {
    background-color: var(--main-text-color);
  }
  .nav__collapse .nav__collapse-btn::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 10px;
    transition: 0.35s all ease-in-out;
    border-radius: 5px;
  }
  .nav__collapse .nav__collapse-btn::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 10px;
    transition: 0.35s all ease-in-out;
    bottom: 0;
    top: 20px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 992px) and (min-width: 600px) {
  .nav__collapse:hover .nav__collapse-btn {
    background-color: #4a5c70;
  }
  .nav__collapse:hover .nav__collapse-btn::before {
    background-color: #4a5c70;
  }
  .nav__collapse:hover .nav__collapse-btn::after {
    background-color: #4a5c70;
  }
}
@media only screen and (max-width: 992px) {
  .nav__collapse.open-collapse {
    width: 40px;
    height: 40px;
    padding-bottom: 5px;
  }
  .nav__collapse.open-collapse .nav__collapse-btn {
    top: 30px;
    left: 1px;
    top: 20px;
    transform: rotate(50deg);
  }
  .nav__collapse.open-collapse .nav__collapse-btn::before {
    top: 0px;
    transform: rotate(80deg);
    transition: 0.35s all ease-in-out;
  }
  .nav__collapse.open-collapse .nav__collapse-btn::after {
    opacity: 0;
    transition: 0.35s all ease-in-out;
  }
  .nav__menu-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-text-color);
    bottom: 0;
    left: -400vh;
    z-index: 700 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 135px 10px var(--inside-padding);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper .nav__credits {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper ul {
    display: flex;
    flex-direction: column;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper ul li.hidden-navLink {
    display: none;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper ul li .active:focus {
    color: var(--secondary-light-green-color) !important;
    letter-spacing: unset !important;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper ul *:not(:last-child) a {
    font-size: 2rem;
    font-weight: 300;
    font-family: "Poppins-Light";
    color: var(--main-text-color);
    position: relative;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
    mix-blend-mode: exclusion;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper ul *:not(:last-child) a:focus {
    color: var(--secondary-light-green-color);
    letter-spacing: 0.1rem;
  }
}
@media only screen and (max-width: 992px) and (max-width: 430px) {
  .nav__menu-mobile .nav__menu-mobile-wrapper ul *:not(:last-child) a {
    font-size: 6.66vw !important;
  }
}
@media only screen and (max-width: 992px) {
  .nav__menu-mobile .nav__menu-mobile-wrapper ul *:not(:last-child) a.active::before {
    content: "";
    background-color: var(--secondary-color);
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    transition: 0.35s all ease-in-out;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper h1 {
    font-size: var(--xl-font-size);
    font-weight: 700;
    font-family: "Poppins-Bold";
    color: var(--main-text-color);
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper h1 span {
    color: var(--main-color);
    font-size: var(--xl-font-size);
    font-weight: 700;
    font-family: "Poppins-Bold";
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper p {
    font-size: var(--xs-font-size);
    font-weight: 300;
    font-family: "Poppins-Light";
    color: var(--main-text-color);
    text-align: left;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper .nav__mobile-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper .nav__stem-img-mobile {
    max-width: 100px;
    overflow: hidden;
  }
  .nav__menu-mobile .nav__menu-mobile-wrapper .nav__stem-img-mobile img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .nav__menu-mobile.open-nav {
    position: fixed;
    left: 0;
  }
  .nav__menu {
    display: none;
  }
  .nav__close {
    width: 30px;
    height: 30px;
  }
  .nav__close svg {
    width: 30px;
    height: 30px;
    color: var(--main-text-color);
    transition: 0.35s all ease-in-out;
  }
  .nav__close:hover {
    transition: 0.35s all ease-in-out;
    cursor: pointer;
  }
  .nav__close:hover svg {
    color: var(--main-color);
    transition: 0.35s all ease-in-out;
  }
}
.nav__btn {
  margin-top: 10px;
}

.contrast-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 115px;
  background-color: var(--main-text-color);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 9999 !important;
}
.contrast-nav .nav__container {
  padding-bottom: 7px;
}
.contrast-nav ul li .nav__stem-img {
  top: -18px;
}
.contrast-nav ul li a {
  color: var(--secondary-dark-color) !important;
}
.contrast-nav ul .active {
  color: var(--secondary-light-green-color) !important;
}

.sticky-nav {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 115px;
  gap: 0;
  background-color: var(--main-text-color);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 9999 !important;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
}
.sticky-nav .nav__container {
  padding-bottom: 7px;
}
@media only screen and (max-width: 992px) {
  .sticky-nav {
    height: 70px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sticky-nav ul li a {
  color: var(--secondary-dark-color) !important;
}
.sticky-nav ul li .nav__stem-img {
  position: absolute;
  top: -7px;
  max-width: 65px;
  overflow: hidden;
}
.sticky-nav ul li .nav__stem-img img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.sticky-nav ul .active {
  color: var(--secondary-light-green-color) !important;
}

.hide-navbar {
  top: -100vh;
}

.active {
  color: var(--secondary-light-green-color) !important;
  position: relative;
  padding: 0px 5px;
}
.active::before {
  width: 100% !important;
}

.register-display-none {
  display: none;
}

.register-display-none.active {
  display: unset !important;
}

.hidden-navLinks {
  display: none;
}

.nav__stem-img {
  position: absolute;
  top: -23px;
  max-width: 100px;
  overflow: hidden;
  z-index: 10;
}
.nav__stem-img img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}

.nav__menu-mobile.open-nav ~ .navbar {
  background-color: var(--secondary-text-color);
  transition-delay: 0.25s;
}

.info__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px var(--inside-padding);
  background-color: var(--secondary-dark-color);
}
@media only screen and (max-width: 992px) {
  .info__header {
    display: none;
  }
}
.info__header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
}
.info__header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.info__header-content p {
  font-size: var(--xs-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-text-color);
}

.body.regular .programs__content-header {
  position: relative;
  width: 100%;
  min-height: 45vh;
  padding: 150px 0px 50px;
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
}
.body.regular .programs__content-header .programs__content-container-header {
  width: 100%;
  max-width: 1185px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  padding: 0px var(--inside-padding);
  z-index: 5;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-header .programs__content-container-header {
    flex-direction: column;
  }
  .body.regular .programs__content-header .programs__content-container-header .programs__content-text-header {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header h1 {
  position: relative;
  width: 100%;
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header P {
  font-size: var(--m-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 0px 50px;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle {
    padding: 5px 17px;
    border-radius: 5px;
  }
  .body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle img {
    display: none !important;
  }
  .body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle h1 {
    position: static !important;
  }
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle img {
  max-width: 700px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle h1 {
  position: absolute;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-image {
  max-width: 450px;
  width: 100%;
}
.body.regular .programs__content-header .programs__content-container-header .programs__content-image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.body.regular .programs__content-separator {
  width: 100%;
  padding: 75px 0px 130px;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-body-color);
}
.body.regular .programs__content-separator .programs__content-container {
  position: relative;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 600px) {
  .body.regular .programs__content-separator .programs__content-container p {
    font-size: var(--s-font-size) !important;
  }
  .body.regular .programs__content-separator .programs__content-container .separator-img-wrapper {
    max-width: 200px !important;
    margin: 0 !important;
  }
}
.body.regular .programs__content-separator .programs__content-container h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-text-color);
}
.body.regular .programs__content-separator .programs__content-container p {
  max-width: 916px;
  width: 100%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--secondary-text-color);
  padding: 5px 0px 5px 17px;
  position: relative;
  z-index: 1;
  text-shadow: 0px 0px var(--secondary-text-color);
}
.body.regular .programs__content-separator .programs__content-container p::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--secondary-text-color);
}
.body.regular .programs__content-separator .programs__content-container .separator-img-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 272px;
  width: 100%;
  opacity: 0.3;
  margin: 0px 40px 10px 0px;
  z-index: 0;
}
.body.regular .programs__content-separator .programs__content-container .separator-img-wrapper img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.body.regular .programs__content-cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  background-color: var(--footer-color);
  z-index: 1;
  padding-top: 70px;
}
.body.regular .programs__content-cards .programs__content-cards-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 0px var(--inside-padding);
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px var(--inside-padding) 100px;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info {
    padding-bottom: 60px;
  }
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-title {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-title h1 {
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
  font-size: var(--xl-font-size);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-title h1 {
    font-size: var(--l-font-size);
  }
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-title h1::before {
    max-width: 750px !important;
    background: var(--secondary-light-green-color) !important;
    border-radius: 8% 92% 6% 94%/87% 40% 60% 13%;
    height: 150% !important;
    top: -10px !important;
  }
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-title h1::before {
  content: "";
  max-width: 900px;
  width: 100%;
  height: 80px;
  position: absolute;
  top: -15px;
  background: url("/src/assets/images/programs_pages/rezults_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card {
    flex-direction: column;
    justify-content: center;
  }
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card h1 {
    text-align: center !important;
    font-size: var(--m-font-size) !important;
  }
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc p {
    font-size: 0.9rem !important;
  }
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card h1 {
  font-size: var(--l-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  text-align: left;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc .text-splitter-year {
    height: 3px !important;
    width: 20% !important;
    min-width: 20px !important;
  }
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc .text-splitter-year {
  width: 5px;
  min-width: 5px;
  height: 20px;
  background-color: var(--main-color);
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc div p {
  font-size: var(--s-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
  line-height: 150%;
}
.body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc div p span {
  display: none;
}
@media only screen and (max-width: 992px) {
  .body.regular .programs__content-cards .programs__content-year-results-wrapper .year__results-info .year__results-container .year__results-card .year__results-content-desc div p span {
    font-size: var(--m-font-size);
    display: unset;
  }
}
.body.regular .vector-wrapper {
  width: 100%;
  max-height: auto;
  overflow: hidden;
  background-color: var(--secondary-body-color);
  font-size: 0px;
  margin: 0;
  padding: 0;
}
.body.regular .vector-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.body.regular .content__container-prg {
  width: 100%;
  max-width: 1100px;
  padding: 0px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
}
@media only screen and (max-width: 992px) {
  .body.regular .content__container-prg {
    padding: 0px 0px 40px;
    gap: 50px;
  }
}
.body.regular .content__container-prg .content__line-splitter {
  width: 100%;
  height: 0.135rem;
  border-radius: 100px;
  background-color: var(--main-color);
  opacity: 0.4;
}
.body.regular .content__container-prg .content__wrapper-prg {
  width: 100%;
  padding: 0 var(--inside-padding);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 70px;
}
@media only screen and (max-width: 992px) {
  .body.regular .content__container-prg .content__wrapper-prg {
    padding: 0px;
    gap: 30px;
  }
}
.body.regular .content__container-prg .content__wrapper-prg .content__titles-prg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.body.regular .content__container-prg .content__wrapper-prg .content__titles-prg h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
  text-transform: capitalize;
}
.body.regular .content__container-prg .content__wrapper-prg .content__titles-prg h2 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-text-color);
  text-transform: capitalize;
}
.body.regular .content__container-prg .content__wrapper-prg .content__images-prg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 899px;
  width: 100%;
  flex-wrap: wrap;
}
@media only screen and (max-width: 510px) {
  .body.regular .content__container-prg .content__wrapper-prg .content__images-prg {
    gap: 10px;
  }
  .body.regular .content__container-prg .content__wrapper-prg .content__images-prg .content__img-wrapper {
    min-width: 155px !important;
    min-height: 155px !important;
    width: 155px !important;
    height: 155px !important;
  }
}
.body.regular .content__container-prg .content__wrapper-prg .content__images-prg .content__img-wrapper {
  min-width: 220px;
  min-height: 220px;
  width: 220px;
  height: 220px;
  overflow: hidden;
  border-radius: 100%;
  border: solid 3px var(--main-color);
}
.body.regular .content__container-prg .content__wrapper-prg .content__images-prg .content__img-wrapper img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.body.regular .content__container-prg .content__wrapper-prg .content__cards-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}
@media only screen and (max-width: 1075px) {
  .body.regular .content__container-prg .content__wrapper-prg .content__cards-container {
    justify-content: center !important;
  }
}
.body.regular .content__card {
  position: relative;
  max-width: 480px;
  min-height: 525px;
  padding: 50px 25px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  background-color: var(--secondary-dark-color);
  border: solid 2px var(--secondary-dark-color);
  transition: 0.4s;
}
@media only screen and (max-width: 992px) {
  .body.regular .content__card {
    padding: 50px 10px;
  }
}
.body.regular .content__card::after {
  content: "";
  width: 102%;
  height: 100%;
  position: absolute;
  border-radius: 40px;
  top: 10px;
  left: -5px;
  right: 0;
  background-color: var(--secondary-dark-color);
  opacity: 0.2;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  transition: 0.2s;
}
@media only screen and (max-width: 600px) {
  .body.regular .content__card {
    height: unset;
  }
}
@media only screen and (min-width: 992px) {
  .body.regular .content__card:hover .language-img {
    transform: scale(0.9);
  }
}
.body.regular .content__card .language-img {
  max-width: 170px;
  max-height: 170px;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  overflow: hidden;
  opacity: 0.5;
  z-index: 2;
  mix-blend-mode: overlay;
}
.body.regular .content__card .language-img img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.body.regular .content__card .content__card-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  flex-direction: column;
  text-align: left;
  z-index: 5;
}
.body.regular .content__card .content__card-wrapper .content-seperator {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.body.regular .content__card .content__card-wrapper .content__card-title-cont {
  position: relative;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.body.regular .content__card .content__card-wrapper .content__card-title-cont h1 {
  font-size: var(--l-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.body.regular .content__card .content__card-wrapper .content__card-title-cont .line-text {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: var(--main-color);
}
.body.regular .content__card .content__card-wrapper p {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 14; /* number of lines to show */
  line-clamp: 14;
  -webkit-box-orient: vertical;
  padding-left: 12px;
}
.body.regular .content__card .content__card-wrapper h2 {
  font-size: var(--m-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
  text-transform: capitalize;
}
.body.regular .content__card .content__card-wrapper h2 span {
  font-size: var(--m-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  text-transform: capitalize;
}
.body.regular .programs__content-register {
  width: 100%;
  padding: 0px var(--inside-padding) 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
}
.body.regular .programs__content-register .programs__content-register-wrapper {
  position: relative;
  width: 100%;
  max-width: 830px;
  padding: 70px var(--inside-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: linear-gradient(108deg, rgb(0, 65, 107) 2%, rgba(0, 65, 107, 0.2) 100%, rgba(0, 65, 107, 0.2) 100%);
  outline: 2px solid rgba(64, 177, 229, 0);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
}
@media only screen and (min-width: 992px) {
  .body.regular .programs__content-register .programs__content-register-wrapper:hover {
    outline: 2px solid rgba(64, 177, 229, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s ease-in;
  }
}
.body.regular .programs__content-register .programs__content-register-wrapper .programs__content-register-content {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 750px) {
  .body.regular .programs__content-register .programs__content-register-wrapper .programs__content-register-content {
    width: 100%;
  }
}
.body.regular .programs__content-register .programs__content-register-wrapper .programs__content-register-content h1 {
  font-size: var(--l-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
}
.body.regular .programs__content-register .programs__content-register-wrapper .programs__content-register-content p {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-secondary-text-color);
}

.blue-text {
  color: var(--main-color) !important;
}

.red-text {
  color: var(--secondary-light-red) !important;
}

.purple-text {
  color: var(--secondary-purple-color) !important;
}

.yellow-text {
  color: var(--secondary-color) !important;
}

.green-text {
  color: var(--secondary-light-green-color) !important;
}

.blue-border {
  border: solid 3px var(--main-color) !important;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) {
  .blue-border:hover {
    border: solid 1px var(--main-color) !important;
    transition: 0.4s;
  }
}

.red-border {
  border: solid 3px var(--secondary-light-red) !important;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) {
  .red-border:hover {
    border: solid 1px var(--secondary-light-red) !important;
    transition: 0.4s;
  }
}

.purple-border {
  border: solid 3px var(--secondary-purple-color) !important;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) {
  .purple-border:hover {
    transition: 0.4s;
    border: solid 1px var(--secondary-purple-color) !important;
  }
}

.yellow-border {
  border: solid 3px var(--secondary-color) !important;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) {
  .yellow-border:hover {
    transition: 0.4s;
    border: solid 1px var(--secondary-color) !important;
  }
}

.green-border {
  transition: 0.4s;
  border: solid 3px var(--secondary-light-green-color) !important;
}
@media only screen and (min-width: 992px) {
  .green-border:hover {
    transition: 0.4s;
    border: solid 1px var(--secondary-light-green-color) !important;
  }
}

.blue-line {
  background-color: var(--main-color) !important;
}

.red-line {
  background-color: var(--secondary-light-red) !important;
}

.purple-line {
  background-color: var(--secondary-purple-color) !important;
}

.yellow-line {
  background-color: var(--secondary-color) !important;
}

.green-line {
  background-color: var(--secondary-light-green-color) !important;
}

.blue-spliter {
  color: var(--main-color) !important;
}

.red-spliter {
  color: var(--secondary-light-red) !important;
}

.purple-spliter {
  color: var(--secondary-purple-color) !important;
}

.yellow-spliter {
  color: var(--secondary-color) !important;
}

.green-spliter {
  color: var(--secondary-light-green-color) !important;
}

.blue-splitter {
  background-color: var(--main-color) !important;
}

.red-splitter {
  background-color: var(--secondary-light-red) !important;
}

.purple-splitter {
  background-color: var(--secondary-purple-color) !important;
}

.yellow-splitter {
  background-color: var(--secondary-color) !important;
}

.green-splitter {
  background-color: var(--secondary-light-green-color) !important;
}

.programs-content-scroll-top {
  background-color: var(--main-color) !important;
}

.header__bg-illustration-cnt {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  gap: 300px;
  overflow: hidden;
  align-items: center;
  padding: 0px 95px;
}
@media only screen and (max-width: 992px) {
  .header__bg-illustration-cnt {
    justify-content: center;
  }
}
.header__bg-illustration-cnt .header__bg-illustration {
  height: 100%;
  max-width: 285px;
  z-index: 1;
  opacity: 0.25;
}
@media only screen and (max-width: 992px) {
  .header__bg-illustration-cnt .header__bg-illustration {
    opacity: 0.05;
  }
}

.body.ur {
  background-color: var(--secondary-body-color);
  overflow-x: hidden;
}
.body.ur .programs__content-header {
  position: relative;
  width: 100%;
  min-height: 45vh;
  padding: 150px 0px 50px;
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
}
.body.ur .programs__content-header .programs__content-container-header {
  width: 100%;
  max-width: 1185px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  padding: 0px var(--inside-padding);
  z-index: 5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header {
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header h1 {
  position: relative;
  width: 100%;
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 0px 50px;
}
@media only screen and (max-width: 992px) {
  .body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle {
    padding: 5px 17px;
    border-radius: 5px;
  }
  .body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle img {
    display: none !important;
  }
  .body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle h1 {
    position: static !important;
  }
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle img {
  max-width: 700px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-text-header .programs__content-text-subtitle h1 {
  position: absolute;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-image {
  max-width: 450px;
  width: 100%;
}
.body.ur .programs__content-header .programs__content-container-header .programs__content-image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.body.ur .programs__content-header {
  overflow-x: hidden;
}
.body.ur .programs__learning-container {
  position: relative;
  background-color: var(--secondary-dark-body-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;
}
.body.ur .programs__learning-container::after {
  content: "";
  position: absolute;
  bottom: -194px;
  width: 100%;
  height: 197px;
  background: url("assets/vectors/aboutProgram_bottom.svg");
  background-position: bottom;
  background-repeat: repeat-x;
}
@media only screen and (max-width: 992px) {
  .body.ur .programs__learning-container {
    padding: 5px 0px;
  }
}
.body.ur .programs__learning-container .programs__learning-wrapper {
  position: relative;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 600px) {
  .body.ur .programs__learning-container .programs__learning-wrapper h1 {
    font-size: var(--l-font-size) !important;
  }
  .body.ur .programs__learning-container .programs__learning-wrapper p {
    letter-spacing: 0.3px;
    line-height: 140%;
  }
  .body.ur .programs__learning-container .programs__learning-wrapper .separator-img-wrapper {
    max-width: 200px !important;
    margin: 0 !important;
  }
}
.body.ur .programs__learning-container .programs__learning-wrapper h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
}
.body.ur .programs__learning-container .programs__learning-wrapper p {
  max-width: 916px;
  width: 100%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-text-color);
  padding: 5px 0px 5px 17px;
  position: relative;
  z-index: 1;
}
.body.ur .programs__learning-container .programs__learning-wrapper .separator-img-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 272px;
  width: 100%;
  opacity: 0.3;
  margin: 0px 40px 10px 0px;
  z-index: 0;
}
.body.ur .programs__learning-container .programs__learning-wrapper .separator-img-wrapper img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.body.ur .program__ur__content {
  width: 100%;
  padding: 190px 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-text-color);
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content {
    padding-bottom: 15px;
  }
}
.body.ur .program__ur__content .program__ur__content-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  flex-direction: column;
  padding: 0px var(--inside-padding);
  padding-right: 30px;
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container {
    padding: 0px var(--inside-padding);
    padding-right: var(--inside-padding);
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__content-container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 5px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__content-container-title h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
  text-transform: capitalize;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__content-container-title h2 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--secondary-subtitle-text-color);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media only screen and (max-width: 1050px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules {
    justify-content: center;
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card {
  position: relative;
  width: 100%;
  padding: 30px 30px;
  min-height: 310px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 13px;
  background-color: rgba(230, 231, 232, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.7);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card.blue-outline {
  border: 2px solid rgba(64, 177, 229, 0.7);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card.red-outline {
  border: 2px solid rgba(241, 91, 91, 0.7);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card.purple-outline {
  border: 2px solid rgba(159, 100, 168, 0.7);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card.yellow-outline {
  border: 2px solid rgba(255, 176, 0, 0.7);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  z-index: 5;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator .content__card-title-cont {
  position: relative;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator .content__card-title-cont h1 {
  font-size: var(--l-font-size);
  color: var(--main-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator .content__card-title-cont h1 {
    font-size: var(--m-font-size);
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator .content__card-title-cont div {
  position: absolute;
  left: -10px;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--main-color);
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator .content__card-title-cont div {
  left: -15px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator p {
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-subtitle-text-color);
  font-size: var(--s-font-size);
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator p {
    font-size: var(--xs-font-size);
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .content__card-wrapper .content-seperator h2 {
  font-family: "Poppins-Bold";
  font-weight: 700;
  font-size: var(--s-font-size);
  color: #5e5e5e;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .language-img {
  position: absolute;
  right: -20px;
  bottom: -20px;
  max-width: 215px;
  height: 130px;
  overflow: hidden;
  z-index: 1;
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .language-img {
    right: 5px;
    bottom: 5px;
    opacity: 0.2;
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-modules .content__card .language-img img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  aspect-ratio: 5/5;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-titles h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  text-align: center;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-titles h3 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--secondary-dark-body-color);
  text-align: center;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div p {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--secondary-subtitle-text-color);
  text-align: center;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div span {
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-subtitle-text-color);
  text-align: center;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div h1 {
  font-size: var(--l-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-dark-body-color);
  text-align: center;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-wrap: wrap;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options h3 {
  font-size: var(--xs-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
  text-align: center;
  padding: 20px 10px;
  background-color: var(--secondary-dark-body-color);
  border-radius: 100px;
  letter-spacing: 0.5px;
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options h3:first-child {
  border-radius: 100px 0px 0px 100px;
  padding-left: 15px;
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options h3:first-child {
    width: 65%;
    border-radius: 20px 20px 0px 0px;
  }
}
.body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options h3:nth-child(2) {
  border-radius: 0px 100px 100px 0px;
  padding-right: 15px;
}
@media only screen and (max-width: 992px) {
  .body.ur .program__ur__content .program__ur__content-container .program__ur__weeks-wrapper .program__ur__weeks-info .plan__program-info-desc div .plan__program-info-options h3:nth-child(2) {
    width: 65%;
    border-radius: 0px 0px 20px 20px;
  }
}
.body.ur .programs__content-register {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px var(--inside-padding);
  padding-bottom: 140px;
}
@media only screen and (max-width: 992px) {
  .body.ur .programs__content-register {
    padding-bottom: 65px;
  }
}
.body.ur .programs__content-register .programs__content-register-wrapper {
  position: relative;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px var(--inside-padding);
  min-height: 300px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  outline: 1px solid #9f64a8;
}
@media only screen and (max-width: 992px) {
  .body.ur .programs__content-register .programs__content-register-wrapper {
    width: 85%;
    min-height: 220px;
  }
}
@media only screen and (max-width: 692px) {
  .body.ur .programs__content-register .programs__content-register-wrapper {
    width: 100%;
    min-height: 200px;
  }
}
.body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 5;
  gap: 5px;
}
@media only screen and (max-width: 992px) {
  .body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content h1 {
    font-size: var(--m-font-size) !important;
  }
  .body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content p {
    font-size: var(--s-font-size) !important;
  }
  .body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content button {
    margin-top: 10px !important;
  }
}
.body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content h1 {
  font-size: var(--l-font-size);
  font-family: "Poppins-Bold";
  color: var(--main-secondary-text-color);
  font-weight: 700;
  text-align: center;
}
.body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content p {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  color: var(--secondary-subtitle-text-color);
  font-weight: 300;
  text-align: center;
}
.body.ur .programs__content-register .programs__content-register-wrapper .programs__content-register-content button {
  margin-top: 25px;
}

.footer__programs-content {
  padding-top: 30px;
}

.programs__organized-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 130px var(--inside-padding);
  padding-bottom: 300px;
  background-color: #ffffff;
}
@media only screen and (max-width: 992px) {
  .programs__organized-container {
    padding: 75px var(--inside-padding);
    padding-bottom: 230px;
  }
}
.programs__organized-container::after {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 194px;
  background: url("assets/vectors/aboutProgram_top.svg");
  background-position: bottom;
  background-repeat: repeat-x;
}
.programs__organized-container .programs__organized-wrapper {
  width: 100%;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 992px) {
  .programs__organized-container .programs__organized-wrapper {
    flex-direction: column;
    gap: 15px;
  }
  .programs__organized-container .programs__organized-wrapper .programs__organized-slider {
    max-width: 75% !important;
  }
}
@media only screen and (max-width: 992px) and (max-width: 660px) {
  .programs__organized-container .programs__organized-wrapper .programs__organized-slider {
    max-width: 100% !important;
  }
}
.programs__organized-container .programs__organized-wrapper .programs__organized-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-text h1 {
  font-size: var(--xl-font-size);
  color: var(--secondary-dark-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  text-align: left;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-text p {
  font-size: var(--m-font-size);
  color: var(--secondary-dark-body-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  text-align: left;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider {
  max-width: 45%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 330px;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel {
  border-radius: 10px;
  height: 100%;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel .swiper-pagination {
  margin-bottom: 15px;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel .swiper-pagination .swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: transparent !important;
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff !important;
  border: transparent;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel .programs__organized-img {
  width: 100%;
  height: 100%;
}
.programs__organized-container .programs__organized-wrapper .programs__organized-slider .programs__organized-carousel .programs__organized-img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  align-self: center;
}

.plan__program-table-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.plan__program-table-container .plan__program-table-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.plan__program-table-container .plan__program-table-content .plan__program-table-titles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0px;
}
.plan__program-table-container .plan__program-table-content .plan__program-table-titles h4 {
  font-size: var(--s-font-size);
  color: var(--secondary-dark-body-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  text-align: center;
}
.plan__program-table-container .plan__program-table-content .plan__program-table-titles h1 {
  font-size: var(--l-font-size);
  color: var(--secondary-dark-body-color) !important;
  font-family: "Poppins-Bold";
  text-align: center;
  font-weight: 700;
}
.plan__program-table-container .plan__program-table-content .plan__program-table {
  width: 100%;
  max-width: 555px;
  border-collapse: collapse;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-head tr th {
  border: 1px solid rgba(12, 27, 43, 0.3);
  background-color: var(--secondary-dark-body-color);
  padding: 5px 10px;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-head tr th h2 {
  font-size: var(--s-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-head tr th:first-child {
  background-color: transparent;
  border: none;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body {
  background-color: rgba(21, 46, 74, 0.1);
  border-left: 1px solid rgba(12, 27, 43, 0.3);
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td {
  padding: 5px 10px;
  border-bottom: 1px solid rgba(12, 27, 43, 0.3);
  border-right: 1px solid rgba(12, 27, 43, 0.3);
  align-items: center;
  justify-content: center;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td div {
  width: 43px;
  height: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 500px) {
  .plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td div {
    display: none;
  }
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td div img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td h2 {
  font-size: var(--xs-font-size);
  color: var(--secondary-dark-body-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td:nth-child(2) h2 {
  text-align: center;
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td:nth-child(3) {
  padding: 5px 40px;
}
.plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td:nth-child(3) h2 {
  text-align: center;
  font-weight: 700;
  font-family: "Poppins-Bold";
}
@media only screen and (max-width: 430px) {
  .plan__program-table-container .plan__program-table-content .plan__program-table .plan__program-table-body tr td:nth-child(3) {
    padding: 5px 5px;
  }
}

.slider__div-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 70px 55px;
  background-color: var(--main-secondary-text-color);
}
@media only screen and (max-width: 992px) {
  .slider__div-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slider__div-container .slider__info {
    width: 70% !important;
    height: auto !important;
    position: relative !important;
    transform: translateY(-50px) !important;
    right: 0 !important;
  }
  .slider__div-container .slider__info .slider__info-img {
    right: -120px !important;
  }
  .slider__div-container .slider__img-container {
    width: 80% !important;
  }
  .slider__div-container .slider__img-container .slider__img {
    max-height: 350px !important;
  }
}
@media only screen and (max-width: 800px) {
  .slider__div-container {
    padding: 10px 10px 55px;
    padding-bottom: 23px;
  }
  .slider__div-container .slider__info {
    width: 90% !important;
    height: auto !important;
    position: relative !important;
    transform: translateY(-50px) !important;
    right: 0 !important;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3) !important;
  }
  .slider__div-container .slider__info .slider__info-img {
    display: none !important;
  }
  .slider__div-container .slider__info h1 {
    font-size: var(--s-font-size) !important;
  }
  .slider__div-container .slider__info p {
    font-size: var(--xs-font-size) !important;
  }
  .slider__div-container .slider__img-container {
    width: 100% !important;
  }
  .slider__div-container .slider__img-container .slider__img {
    height: 250px !important;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.3) !important;
  }
  .slider__div-container .slider__img-container .slider__img img {
    height: 100%;
  }
  .slider__div-container .slider__img-container::after {
    width: 98% !important;
    left: 1% !important;
    bottom: -10px !important;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.3) !important;
  }
}
.slider__div-container .slider__img-container {
  position: relative;
  width: 55%;
}
.slider__div-container .slider__img-container::after {
  content: "";
  position: absolute;
  left: -8px;
  bottom: -8px;
  background-color: var(--secondary-dark-body-color);
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 15px;
  box-shadow: -3px 4px 10px 0 rgba(0, 0, 0, 0.3);
}
.slider__div-container .slider__img-container .slider__img {
  width: 100%;
  height: 315px;
  overflow: hidden;
  border-radius: 13px;
  box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider__div-container .slider__img-container .slider__img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.slider__div-container .slider__info {
  position: absolute;
  right: 70px;
  width: 50%;
  padding: 25px 20px;
  background-color: var(--secondary-dark-body-color);
  border-radius: 25px;
  min-height: 215px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.3);
}
.slider__div-container .slider__info h1 {
  font-size: var(--l-font-size);
  font-family: "Poppins-Medium";
  color: var(--main-color);
  font-weight: 500;
}
.slider__div-container .slider__info p {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
}
.slider__div-container .slider__info .slider__info-img {
  position: absolute;
  right: -90px;
  bottom: -70px;
  max-width: 180px;
  width: 100%;
  overflow: hidden;
}
.slider__div-container .slider__info .slider__info-img img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.error__page {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 140px 25px 0px;
  background-color: #131b94;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  overflow: hidden;
}
@media only screen and (max-height: 960px) {
  .error__page {
    overflow-y: auto;
  }
}
@media only screen and (max-height: 730px) {
  .error__page {
    gap: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .error__page h1 {
    font-size: 2rem;
  }
  .error__page .error__info h1 {
    font-size: var(--l-font-size) !important;
  }
  .error__page .error__info p {
    font-size: var(--m-font-size) !important;
  }
}
.error__page h1 {
  z-index: 1;
}
.error__page .error__container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  gap: 15px;
}
.error__page .error__container .error__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (max-height: 730px) {
  .error__page .error__container .error__content {
    gap: 0px;
  }
  .error__page .error__container .error__content .error__page-gif {
    max-height: 110px !important;
  }
  .error__page .error__container .error__content .error__page-gif img {
    width: auto !important;
  }
}
.error__page .error__container .error__content .error__page-gif {
  max-width: 400px;
  max-height: 250px;
}
.error__page .error__container .error__content .error__page-gif img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.error__page .error__container .error__content .error__info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.error__page .error__container .error__content .error__info h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-text-color);
}
.error__page .error__container .error__content .error__info p {
  font-size: var(--l-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--main-text-color);
}
.error__page .error__container .error__page-information {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.error__page .error__container .error__page-information ul a {
  font-size: 30px;
  color: var(--main-color);
}
@media only screen and (min-width: 992992px) {
  .error__page .error__container .error__page-information ul a:hover svg {
    color: var(--main-text-color);
    transition: 0.35s all;
    transform: scale(1.1);
  }
}
.error__page .error__container .error__page-information p {
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
  font-size: var(--s-font-size);
}/*# sourceMappingURL=style.css.map */