.home__about {
  width: 100%;
  padding: 70px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  h1 {
    color: var(--main-color) !important;
  }
  p {
    color: var(--secondary-text-color) !important;
  }
  .about__svg {
    max-width: 400px !important;
    transform: none !important;
  }
  @media only screen and (max-width: 992px) {
    padding: 40px 0px 0px;
  }
  .home__about-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px var(--inside-padding);
    background-color: rgba($color: #d4d4d4, $alpha: 0);
    transition: 0.4s ease-in;
    gap: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .home__about-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
}
