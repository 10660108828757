.body.regular {
  // Header design
  .programs__content-header {
    position: relative;
    width: 100%;
    min-height: 45vh;
    padding: 150px 0px 50px;
    padding-top: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--footer-color);

    .programs__content-container-header {
      width: 100%;
      max-width: 1185px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-around;
      gap: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0px var(--inside-padding);
      z-index: 5;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        .programs__content-text-header {
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
        }
      }

      .programs__content-text-header {
        // max-width: 615px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        h1 {
          position: relative;
          width: 100%;
          font-size: var(--xl-font-size);
          color: var(--main-text-color);
          font-family: $font-bold;
          font-weight: 700;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        P {
          font-size: var(--m-font-size);
          color: var(--main-text-color);
          font-family: $font-light;
          font-weight: 300;
        }
        .programs__content-text-subtitle {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: max-content;
          padding: 0px 50px;
          @media only screen and (max-width: 992px) {
            padding: 5px 17px;
            border-radius: 5px;
            img {
              display: none !important;
            }

            h1 {
              position: static !important;
            }
          }
          img {
            max-width: 700px;
            width: 100%;
            object-fit: contain;
          }
          h1 {
            position: absolute;
          }
        }
        // p {
        //   font-size: var(--m-font-size);
        //   color: var(--main-text-color);
        //   font-family: $font-light;
        //   font-weight: 300;
        //   word-break: break-word;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 7; /* number of lines to show */
        //   -webkit-box-orient: vertical;
        // }
      }

      .programs__content-image {
        max-width: 450px;
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .programs__content-separator {
    width: 100%;
    padding: 75px 0px 130px;
    min-height: 40vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-body-color);

    .programs__content-container {
      position: relative;
      width: 100%;
      max-width: 1100px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0px var(--inside-padding);

      @media only screen and (max-width: 600px) {
        p {
          font-size: var(--s-font-size) !important;
        }

        .separator-img-wrapper {
          max-width: 200px !important;
          margin: 0 !important;
        }
      }

      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--secondary-text-color);
        // text-transform: capitalize !important;
      }

      p {
        max-width: 916px;
        width: 100%;
        font-size: var(--m-font-size);
        font-family: $font-light;
        font-weight: 300;
        color: var(--secondary-text-color);
        padding: 5px 0px 5px 17px;
        position: relative;
        z-index: 1;
        text-shadow: 0px 0px var(--secondary-text-color);
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 5px;
          height: 100%;
          background-color: var(--secondary-text-color);
        }
      }

      .separator-img-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 272px;
        width: 100%;
        opacity: 0.3;
        margin: 0px 40px 10px 0px;
        z-index: 0;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .programs__content-cards {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    background-color: var(--footer-color);
    z-index: 1;
    padding-top: 70px;
    // &::before {
    //   content: "";
    //   width: 100%;
    //   height: 95%;
    //   position: absolute;
    //   top: 370px;
    //   background-color: var(--footer-color)color)color)color);
    //   z-index: -1;
    //   @media only screen and (max-width: 992px) {
    //     height: 97%;
    //   }
    // }
    .programs__content-cards-wrapper {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      padding: 0px var(--inside-padding);
      // &:first-child {
      //   position: relative;
      //   top: -500px;
      // }
    }
    .programs__content-year-results-wrapper {
      width: 100%;
      max-width: 1200px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .year__results-info {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 40px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px var(--inside-padding) 100px;
        @media only screen and (max-width: 992px) {
          // gap: 60px;
          padding-bottom: 60px;
        }
        .year__results-title {
          width: 100%;
          text-align: center;
          margin-bottom: 25px;
          h1 {
            font-family: $font-bold;
            font-weight: 700;
            color: var(--main-text-color);
            font-size: var(--xl-font-size);
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            @media only screen and (max-width: 992px) {
              font-size: var(--l-font-size);
              &::before {
                max-width: 750px !important;
                background: var(--secondary-light-green-color) !important;
                border-radius: 8% 92% 6% 94% / 87% 40% 60% 13%;
                height: 150% !important;
                top: -10px !important;
              }
            }
            &::before {
              content: "";
              max-width: 900px;
              width: 100%;
              height: 80px;
              position: absolute;
              top: -15px;
              background: url("/src/assets/images/programs_pages/rezults_background.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100%;
              z-index: -1;
            }
          }
        }
        .year__results-container {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 60px;

          .year__results-card {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;
            justify-content: center;
            text-align: left;
            @media only screen and (max-width: 992px) {
              flex-direction: column;
              justify-content: center;
              h1 {
                text-align: center !important;
                font-size: var(--m-font-size) !important;
              }
              .year__results-content-desc {
                p {
                  font-size: 0.9rem !important;
                }
              }
            }
            h1 {
              font-size: var(--l-font-size);
              font-weight: 700;
              font-family: $font-bold;
              text-align: left;
            }
            .year__results-content-desc {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              gap: 5px;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              align-items: flex-start;

              @media only screen and (max-width: 992px) {
                flex-direction: column;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                text-align: left;
                .text-splitter-year {
                  height: 3px !important;
                  width: 20% !important;
                  min-width: 20px !important;
                }
              }
              .text-splitter-year {
                width: 5px;
                min-width: 5px;
                height: 20px;
                background-color: var(--main-color);
              }
              div {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                p {
                  // max-width: 850px;
                  font-size: var(--s-font-size);
                  font-family: $font-light;
                  font-weight: 300;
                  color: var(--main-secondary-text-color);
                  line-height: 150%;
                  span {
                    display: none;
                  }

                  @media only screen and (max-width: 992px) {
                    span {
                      font-size: var(--m-font-size);
                      display: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .vector-wrapper {
    width: 100%;
    max-height: auto;
    overflow: hidden;
    background-color: var(--secondary-body-color);
    font-size: 0px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content__container-prg {
    width: 100%;
    max-width: 1100px;
    padding: 0px 0px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
    @media only screen and (max-width: 992px) {
      padding: 0px 0px 40px;
      gap: 50px;
    }
    .content__line-splitter {
      width: 100%;
      height: 0.135rem;
      border-radius: 100px;
      background-color: var(--main-color);
      opacity: 0.4;
    }
    .content__wrapper-prg {
      width: 100%;
      padding: 0 var(--inside-padding);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      flex-direction: column;
      gap: 70px;
      @media only screen and (max-width: 992px) {
        padding: 0px;
        gap: 30px;
      }
      .content__titles-prg {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        h1 {
          font-size: var(--xl-font-size);
          font-family: $font-bold;
          font-weight: 700;
          color: var(--main-text-color);
          text-transform: capitalize;
        }

        h2 {
          font-size: var(--m-font-size);
          font-family: $font-light;
          font-weight: 300;
          color: var(--main-text-color);
          text-transform: capitalize;
        }
      }

      .content__images-prg {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        gap: 40px;
        max-width: 899px;
        width: 100%;
        flex-wrap: wrap;
        @media only screen and (max-width: 510px) {
          gap: 10px;
          .content__img-wrapper {
            min-width: 155px !important;
            min-height: 155px !important;
            width: 155px !important ;
            height: 155px !important;
          }
        }
        .content__img-wrapper {
          min-width: 220px;
          min-height: 220px;
          width: 220px;
          height: 220px;
          overflow: hidden;
          border-radius: 100%;
          border: solid 3px var(--main-color);

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .content__cards-container {
        width: 100%;
        // max-width: 1100px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 40px;
        @media only screen and (max-width: 1075px) {
          justify-content: center !important;
        }
      }
    }
  }

  // Module Card Design

  .content__card {
    position: relative;
    max-width: 480px;
    min-height: 525px;
    padding: 50px 25px;
    border-radius: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    background-color: var(--secondary-dark-color);
    border: solid 2px var(--secondary-dark-color);
    transition: 0.4s;
    @media only screen and (max-width: 992px) {
      padding: 50px 10px;
    }
    &::after {
      content: "";
      width: 102%;
      // width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 40px;
      top: 10px;
      left: -5px;
      right: 0;
      background-color: var(--secondary-dark-color);
      opacity: 0.2;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      transition: 0.2s;
    }
    @media only screen and (max-width: "600px") {
      height: unset;
    }
    @media only screen and (min-width: "992px") {
      &:hover {
        // border: solid 2px #28578c;
        // transition: 0.4s ease-in-out;
        // background-color: var(--footer-color);

        .language-img {
          transform: scale(0.9);
        }
        // &::after {
        //   width: 480px;
        //   left: 0;
        //   top: 0;
        //   transition: 0.4s;
        // }
      }
    }
    .language-img {
      max-width: 170px;
      max-height: 170px;
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 20px;
      margin-right: 20px;
      overflow: hidden;
      opacity: 0.5;
      z-index: 2;
      mix-blend-mode: overlay;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .content__card-wrapper {
      position: relative;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 40px;
      flex-direction: column;
      text-align: left;
      z-index: 5;
      .content-seperator {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
      .content__card-title-cont {
        position: relative;
        height: max-content;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 10px;
        h1 {
          font-size: var(--l-font-size);
          color: var(--main-text-color);
          font-weight: 700;
          font-family: $font-bold;
          // text-transform: capitalize;
        }
        .line-text {
          width: 5px;
          height: 100%;
          position: absolute;
          left: 0;
          background-color: var(--main-color);
        }
      }

      p {
        font-size: var(--s-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-text-color);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 14; /* number of lines to show */
        line-clamp: 14;
        -webkit-box-orient: vertical;
        padding-left: 12px;
      }
      h2 {
        font-size: var(--m-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
        text-transform: capitalize;
        span {
          font-size: var(--m-font-size);
          font-weight: 700;
          font-family: $font-bold;
          text-transform: capitalize;
        }
      }
    }
  }
  // Register card
  .programs__content-register {
    width: 100%;
    padding: 0px var(--inside-padding) 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--footer-color);

    .programs__content-register-wrapper {
      position: relative;
      width: 100%;
      max-width: 830px;
      padding: 70px var(--inside-padding);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      // background-color: var(--secondary-dark-color);
      background: linear-gradient(
        108deg,
        rgba(0, 65, 107, 1) 2%,
        rgba(0, 65, 107, 0.2) 100%,
        rgba(0, 65, 107, 0.2) 100%
      );
      outline: 2px solid rgba($color: $main-color, $alpha: 0);
      box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0);
      @media only screen and (min-width: 992px) {
        &:hover {
          outline: 2px solid rgba($color: $main-color, $alpha: 0.3);
          box-shadow: 0px 4px 10px 0px rgba($color: #000000, $alpha: 0.1);
          transition: 0.2s ease-in;
        }
      }

      .programs__content-register-content {
        width: 70%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 15px;
        @media only screen and (max-width: 750px) {
          width: 100%;
        }
        h1 {
          font-size: var(--l-font-size);
          font-weight: 700;
          font-family: $font-bold;
          color: var(--main-text-color);
        }
        p {
          font-size: var(--s-font-size);
          font-weight: 300;
          font-family: $font-light;
          color: var(--main-secondary-text-color);
        }
      }
    }
  }
}

// =================== Theme setups ================= //

// text colors for theme
.blue-text {
  color: var(--main-color) !important;
}

.red-text {
  color: var(--secondary-light-red) !important;
}

.purple-text {
  color: var(--secondary-purple-color) !important;
}

.yellow-text {
  color: var(--secondary-color) !important;
}

.green-text {
  color: var(--secondary-light-green-color) !important;
}

// Border Theme
.blue-border {
  border: solid 3px var(--main-color) !important;
  transition: 0.4s;
  @media only screen and (min-width: "992px") {
    &:hover {
      border: solid 1px var(--main-color) !important;
      transition: 0.4s;
    }
  }
}

.red-border {
  border: solid 3px var(--secondary-light-red) !important;
  transition: 0.4s;
  @media only screen and (min-width: "992px") {
    &:hover {
      border: solid 1px var(--secondary-light-red) !important;
      transition: 0.4s;
    }
  }
}

.purple-border {
  border: solid 3px var(--secondary-purple-color) !important;
  transition: 0.4s;
  @media only screen and (min-width: "992px") {
    &:hover {
      transition: 0.4s;
      border: solid 1px var(--secondary-purple-color) !important;
    }
  }
}

.yellow-border {
  border: solid 3px var(--secondary-color) !important;
  transition: 0.4s;
  @media only screen and (min-width: "992px") {
    &:hover {
      transition: 0.4s;
      border: solid 1px var(--secondary-color) !important;
    }
  }
}

.green-border {
  transition: 0.4s;
  border: solid 3px var(--secondary-light-green-color) !important;
  @media only screen and (min-width: "992px") {
    &:hover {
      transition: 0.4s;
      border: solid 1px var(--secondary-light-green-color) !important;
    }
  }
}

// Line Theme
.blue-line {
  background-color: var(--main-color) !important;
}

.red-line {
  background-color: var(--secondary-light-red) !important;
}

.purple-line {
  background-color: var(--secondary-purple-color) !important;
}

.yellow-line {
  background-color: var(--secondary-color) !important;
}

.green-line {
  background-color: var(--secondary-light-green-color) !important;
}

// Year Results Splitter
.blue-spliter {
  color: var(--main-color) !important;
}

.red-spliter {
  color: var(--secondary-light-red) !important;
}

.purple-spliter {
  color: var(--secondary-purple-color) !important;
}

.yellow-spliter {
  color: var(--secondary-color) !important;
}

.green-spliter {
  color: var(--secondary-light-green-color) !important;
}

// Content Cards Splitter
.blue-splitter {
  background-color: var(--main-color) !important;
}

.red-splitter {
  background-color: var(--secondary-light-red) !important;
}

.purple-splitter {
  background-color: var(--secondary-purple-color) !important;
}

.yellow-splitter {
  background-color: var(--secondary-color) !important;
}

.green-splitter {
  background-color: var(--secondary-light-green-color) !important;
}

// .programs__content-cards-wrapper > :first-child {
//   .content__img-wrapper {
//     outline: 5px solid var(--secondary-body-color) !important;
//     transition: 0.1s ease-in;
//     &:hover {
//       outline: 1px solid var(--secondary-body-color) !important;
//       border-width: 3px !important;
//       transform: scale(1.03);
//       transition: 0.1s ease-in;
//     }
//   }
//   .content__titles-prg {
//     h1 {
//       color: var(--secondary-text-color) !important;
//     }
//     h2 {
//       color: var(--secondary-text-color) !important;
//     }
//   }
// }

// Scroll To top
.programs-content-scroll-top {
  background-color: var(--main-color) !important;
}
.header__bg-illustration-cnt {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  gap: 300px;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 95px;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  .header__bg-illustration {
    height: 100%;
    max-width: 285px;
    z-index: 1;
    opacity: 0.25;
    @media only screen and (max-width: 992px) {
      opacity: 0.05;
    }
  }
}
