.socials__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px;
  .socials__img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-around;
    gap: 7px;
    a {
      width: 40px;
      height: 40px;
      background-color: rgba($color: $main-text-color, $alpha: 0);
      border-radius: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba($color: $main-secondary-text-color, $alpha: 0.75);
      transition: 0.35s ease-in-out;
      svg {
        width: 20px;
        height: 20px;
        transition: 0.2s ease-in-out;
        color: var(--main-text-color);
        align-self: center;
      }
      @media only screen and (min-width: 992px) {
        &:hover {
          background-color: rgba($color: $main-text-color, $alpha: 1);
          svg {
            color: var(--secondary-text-color);
            transition: 0.2s ease-in-out;
            transform: scale(1.08);
          }
        }
      }
    }
  }
  .socials__credits {
    p {
      font-size: var(--xs-font-size);
      color: var(--main-secondary-text-color);
      font-weight: 300;
      font-family: $font-light;
      text-align: center;
    }
  }
}

.socials__img.sm-socials {
  a {
    width: 30px;
    height: 30px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}
.socials__img.lg-socials {
  a {
    width: 35px;
    height: 35px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
