.register-btn {
  width: 245px;
  height: 55px;
  flex-grow: 0;
  text-align: center;
  border: none;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
  border: solid 1px var(--secondary-text-color);
  border-radius: 15px 15px 0px 15px;
  &:before {
    content: "";
    border-radius: 15px 15px 0px 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
  @media only screen and (min-width: 992px) {
    &:hover {
      &::before {
        opacity: 100;
      }
    }
  }
}
