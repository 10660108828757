.article__help {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;

  .article__help-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 40px var(--inside-padding);
    background-color: var(--secondary-dark-body-color);
    .article__help-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      @media only screen and (max-width: 992px) {
        h1 {
          font-size: var(--l-font-size) !important;
        }
        p {
          font-size: var(--s-font-size) !important;
        }
      }
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-text-color);
        font-weight: 700;
        font-family: $font-bold;
      }
      p {
        font-size: var(--m-font-size);
        color: var(--main-secondary-text-color);
        font-weight: 300;
        font-family: $font-light;
      }
    }
  }
  .article__help-content {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 40px var(--inside-padding);
    background-color: var(--main-secondary-text-color);
    @media only screen and (max-width: 992px) {
      gap: 50px;
    }
    .article__help-content-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      gap: 30px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        gap: 10px;
        p {
          max-width: 100% !important;
          text-align: center !important;
          font-size: var(--s-font-size) !important;
        }
        background-color: rgba($color: $main-text-color, $alpha: 0.2);
        padding: 10px;
        border-radius: 5px;
      }
      p {
        max-width: 61%;
        font-size: var(--m-font-size);
        color: var(--secondary-dark-body-color);
        font-weight: 500;
        font-family: $font-medium;
      }
      .article__help-img {
        max-width: 634px;
        max-height: 238px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.article__help-vector {
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-end;
  transform: translateY(5px);
  img {
    width: 100%;
    object-fit: contain;
  }
}
