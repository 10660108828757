.programs__organized-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 130px var(--inside-padding);
  padding-bottom: 300px;
  background-color: #ffffff;
  @media only screen and (max-width: 992px) {
    padding: 75px var(--inside-padding);
    padding-bottom: 230px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 194px;
    background: url("assets/vectors/aboutProgram_top.svg");
    background-position: bottom;
    background-repeat: repeat-x;
  }
  .programs__organized-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 15px;
      .programs__organized-slider {
        max-width: 75% !important;
        @media only screen and (max-width: 660px) {
          max-width: 100% !important;
        }
      }
    }
    .programs__organized-text {
      // width: 50%;
      // flex: 0.6;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      justify-content: center;
      h1 {
        font-size: var(--xl-font-size);
        color: var(--secondary-dark-color);
        font-family: $font-bold;
        font-weight: 700;
        text-align: left;
      }
      p {
        font-size: var(--m-font-size);
        color: var(--secondary-dark-body-color);
        font-family: $font-light;
        font-weight: 300;
        text-align: left;
      }
    }
    .programs__organized-slider {
      max-width: 45%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      height: 330px;
      .programs__organized-carousel {
        border-radius: 10px;
        height: 100%;
        .swiper-pagination {
          margin-bottom: 15px;
          .swiper-pagination-bullet {
            opacity: 1 !important;
            background-color: transparent !important;
            border: 1px solid #fff;
            width: 8px;
            height: 8px;
            &-active {
              background-color: #fff !important;
              border: transparent;
            }
          }
        }

        .programs__organized-img {
          width: 100%;
          height: 100%;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            align-self: center;
          }
        }
      }
    }
  }
}
