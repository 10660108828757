.programs {
  width: 100%;
  padding: 160px 0px 0px;
  min-height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  // background-image: url("/src/assets/images/programs/heroImage.png");
  // background-position: bottom;
  // background-repeat: no-repeat;
  // background-size: cover;
  background-image: linear-gradient(
    0deg,
    rgba(var(--main-color), 0) 70%,
    rgba(#d4b1da, 0.55) 100%
  );
  background-size: 100% 150%;
  background-position: top left;
  animation: programsBreathingAnim 10s ease-in-out infinite;
  @media only screen and (max-width: 650px) {
    padding-top: 135px;
    padding-bottom: 25px;
  }
  @media only screen and (max-width: 992px) {
    min-height: 60vh;
  }
  .programs__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 0px var(--inside-padding);

    .programs__container-content {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      text-align: center;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        .programs__container-info {
          align-items: center !important;
          max-width: 700px !important;
          h1,
          p {
            text-align: center !important;
          }
        }
        .programs__container-img {
          &::after {
            width: 70% !important;
            height: 20% !important;
            background-color: #18cded !important;
          }
        }
      }
      .programs__container-img {
        position: relative;
        flex: 0.5;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        padding: 50px 20px 50px;
        @media only screen and (max-width: 992px) {
          display: none;
        }
        &::after {
          content: "";
          width: 80%;
          height: 60%;
          position: absolute;
          background-color: #18cded !important;
          border-radius: 100%;
          -webkit-filter: blur(35px);
          filter: blur(35px);
          z-index: 0;
          opacity: 0.6;
        }
        img {
          width: 100%;
          height: 100%;
          max-height: 400px;
          object-fit: contain;
          z-index: 1;
        }
      }
      .programs__container-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        flex: 0.5;
        .divider {
          max-width: 40%;
          width: 100%;
          height: 3px;
          display: block;
          background-color: white;
          border-radius: 20px;
        }
        @media only screen and (max-width: 692px) {
          h1 {
            font-size: var(--l-font-size) !important;
            text-align: center !important;
          }
          p {
            font-size: var(--s-font-size) !important;
            text-align: center !important;
          }
        }
        h1 {
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold;
          color: var(--main-text-color);
          text-align: left;
        }
        p {
          max-width: 932px;
          font-size: var(--s-font-size);
          font-weight: 300;
          font-family: $font-light;
          color: var(--main-text-color);
          text-align: left;
        }
      }
    }
    .scroll-down {
      margin-top: 20px;
      animation: upDown 0.9s ease-in-out infinite;
      cursor: pointer;
    }
  }
}
.programs__vector-svg {
  width: 100%;
  max-height: 108.54px;
  background-color: var(--main-secondary-text-color-2);
  z-index: 5;
  img {
    width: 100%;
    object-fit: cover;
  }
}
// Animation
@keyframes upDown {
  0%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
}
@keyframes programsBreathingAnim {
  0% {
    background-position: top;
  }
  30% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}
