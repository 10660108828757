@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.article__about {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}
.article__about .article__about-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px var(--inside-padding);
  gap: 20px;
}
@media only screen and (max-width: 992px) {
  .article__about .article__about-container {
    flex-direction: column;
    gap: 10px;
  }
  .article__about .article__about-container .article__about-content {
    max-width: 100% !important;
  }
  .article__about .article__about-container .article__about-img {
    display: none;
  }
}
.article__about .article__about-container .article__about-content {
  max-width: 65%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.article__about .article__about-container .article__about-content h1 {
  font-size: var(--xl-font-size);
  color: var(--main-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
}
.article__about .article__about-container .article__about-content p {
  font-size: var(--m-font-size);
  color: var(--secondary-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
}
.article__about .article__about-container .article__about-img {
  max-width: 863px;
  width: 100%;
  overflow: hidden;
  transform: translateY(-60px);
  z-index: 100;
}
.article__about .article__about-container .article__about-img img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.article__header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-dark-color);
  padding: 120px 0px;
  min-height: 60vh;
  background: url("assets/images/illustrations/binary/binaryBlueOpacity.webp") repeat var(--secondary-dark-color);
  background-position: center;
  background-repeat: repeat;
  overflow-x: hidden;
}
.article__header::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.article__header .article__header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
  z-index: 5;
  padding: 0px var(--inside-padding);
}
.article__header .article__header-container h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--main-color);
  text-align: center;
}
.article__header .article__header-container p {
  max-width: 65%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-text-color);
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .article__header .article__header-container p {
    max-width: 100%;
  }
}
.article__header .article__header-container button {
  margin-top: 15px;
}

.article__help {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}
.article__help .article__help-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px var(--inside-padding);
  background-color: var(--secondary-dark-body-color);
}
.article__help .article__help-container .article__help-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
@media only screen and (max-width: 992px) {
  .article__help .article__help-container .article__help-wrapper h1 {
    font-size: var(--l-font-size) !important;
  }
  .article__help .article__help-container .article__help-wrapper p {
    font-size: var(--s-font-size) !important;
  }
}
.article__help .article__help-container .article__help-wrapper h1 {
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.article__help .article__help-container .article__help-wrapper p {
  font-size: var(--m-font-size);
  color: var(--main-secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
}
.article__help .article__help-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 40px var(--inside-padding);
  background-color: var(--main-secondary-text-color);
}
@media only screen and (max-width: 992px) {
  .article__help .article__help-content {
    gap: 50px;
  }
}
.article__help .article__help-content .article__help-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
@media only screen and (max-width: 992px) {
  .article__help .article__help-content .article__help-content-container {
    flex-direction: column;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 5px;
  }
  .article__help .article__help-content .article__help-content-container p {
    max-width: 100% !important;
    text-align: center !important;
    font-size: var(--s-font-size) !important;
  }
}
.article__help .article__help-content .article__help-content-container p {
  max-width: 61%;
  font-size: var(--m-font-size);
  color: var(--secondary-dark-body-color);
  font-weight: 500;
  font-family: "Poppins-Medium";
}
.article__help .article__help-content .article__help-content-container .article__help-img {
  max-width: 634px;
  max-height: 238px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.article__help .article__help-content .article__help-content-container .article__help-img img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.article__help-vector {
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  transform: translateY(5px);
}
.article__help-vector img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.article__integration {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  background-color: var(--main-secondary-text-color-2);
  overflow-x: hidden;
}
.article__integration .article__integration-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding: 0px var(--inside-padding);
}
.article__integration .article__integration-container .article__integration-text {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
@media only screen and (max-width: 700px) {
  .article__integration .article__integration-container .article__integration-text {
    flex-direction: column;
    gap: 10px;
  }
  .article__integration .article__integration-container .article__integration-text .article__integration-title {
    align-items: center !important;
  }
  .article__integration .article__integration-container .article__integration-text .article__integration-title h1,
  .article__integration .article__integration-container .article__integration-text .article__integration-title p {
    text-align: center !important;
  }
}
.article__integration .article__integration-container .article__integration-text .article__integration-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (max-width: 992px) {
  .article__integration .article__integration-container .article__integration-text .article__integration-title h1 {
    font-size: var(--l-font-size) !important;
  }
  .article__integration .article__integration-container .article__integration-text .article__integration-title p {
    font-size: var(--s-font-size) !important;
  }
}
.article__integration .article__integration-container .article__integration-text .article__integration-title h1 {
  font-size: var(--xl-font-size);
  color: var(--secondary-light-red);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.article__integration .article__integration-container .article__integration-text .article__integration-title p {
  font-size: var(--m-font-size);
  color: var(--secondary-dark-body-color);
  font-weight: 500;
  font-family: "Poppins-Medium";
  max-width: 95%;
}
.article__integration .article__integration-container .article__integration-text .article__integration-image {
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media only screen and (max-width: 992px) {
  .article__integration .article__integration-container .article__integration-text .article__integration-image {
    max-width: 200px;
  }
}
.article__integration .article__integration-container .article__integration-text .article__integration-image img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.article__integration .article__integration-container .article__integration-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
}
.article__integration .article__integration-container .article__integration-content svg {
  color: var(--secondary-light-red);
  width: 45px !important;
  height: 45px !important;
}
.article__integration .article__integration-container .article__integration-content .article__integration__content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.article__integration .article__integration-container .article__integration-content .article__integration__content-wrapper div {
  width: 100%;
  padding: 15px 35px;
  background-color: var(--main-text-color);
  border-radius: 15px;
}
.article__integration .article__integration-container .article__integration-content .article__integration__content-wrapper div p {
  font-size: var(--m-font-size);
  color: var(--secondary-dark-body-color);
  font-weight: 500;
  font-family: "Poppins-Medium";
  max-width: 95%;
}
@media only screen and (max-width: 992px) {
  .article__integration .article__integration-container .article__integration-content .article__integration__content-wrapper div p {
    font-size: var(--s-font-size);
  }
}
.article__integration .article__integration-container .article__integration-img {
  position: absolute;
  right: -130px;
  bottom: -50px;
  max-width: 350px;
  overflow: hidden;
  z-index: 0;
  opacity: 0.5;
}
.article__integration .article__integration-container .article__integration-img img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.article__integration-vector {
  width: 100%;
  height: 122px;
  overflow: hidden;
}
@media only screen and (max-width: 692px) {
  .article__integration-vector {
    height: 80px;
  }
}
.article__integration-vector img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.article__separator {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
  background: url("assets/images/article/separator/bg.webp");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}
.article__separator::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 0;
}
.article__separator .article__separator-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding: 0px var(--inside-padding);
}
.article__separator .article__separator-container p {
  max-width: 70%;
  font-size: var(--m-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  text-align: center;
}
@media only screen and (max-width: 652px) {
  .article__separator .article__separator-container p {
    max-width: 90%;
  }
}

.article__register {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  background-color: #fff;
  overflow-x: hidden;
}
@media only screen and (max-width: 992px) {
  .article__register {
    padding: 10px 0px 100px;
  }
}
.article__register .article__register-container {
  padding: 0px var(--inside-padding);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.article__register .article__register-container p {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--secondary-dark-body-color);
  text-align: center;
  max-width: 75%;
}
@media only screen and (max-width: 750px) {
  .article__register .article__register-container p {
    max-width: 100%;
  }
}

.article__use {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 50px;
  background-color: #fff;
  overflow-x: hidden;
}
.article__use .article__use-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0px var(--inside-padding);
}
.article__use .article__use-container .article__use-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-content h1 {
    font-size: var(--l-font-size) !important;
  }
  .article__use .article__use-container .article__use-content p {
    font-size: var(--s-font-size) !important;
  }
}
.article__use .article__use-container .article__use-content h1 {
  font-size: var(--xl-font-size);
  color: var(--main-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
.article__use .article__use-container .article__use-content p {
  font-size: var(--m-font-size);
  color: var(--secondary-text-color);
  font-weight: 500;
  font-family: "Poppins-Medium";
}
.article__use .article__use-container .article__use-carousel {
  display: flex;
  width: 100%;
}
.article__use .article__use-container .article__use-carousel .article__carousel {
  width: 100%;
  display: flex;
  padding: 30px 0px;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-carousel .article__carousel {
    padding: 30px 0px 70px;
  }
}
.article__use .article__use-container .article__use-carousel .article__carousel .carousel__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 50px;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-carousel .article__carousel .carousel__img {
    padding: 0px;
  }
}
.article__use .article__use-container .article__use-carousel .article__carousel .carousel__img div {
  padding: 10px 25px 50px;
  width: 95%;
  height: 100%;
  overflow: hidden;
  max-width: 990px;
  background-color: #313338;
  max-height: 426px;
  border-radius: 15px;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-carousel .article__carousel .carousel__img div {
    width: 100%;
  }
}
.article__use .article__use-container .article__use-carousel .article__carousel .carousel__img div img {
  width: 100%;
  aspect-ratio: 2.5;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-carousel .article__carousel .carousel__img div img {
    -o-object-fit: fill;
       object-fit: fill;
    aspect-ratio: unset;
  }
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-prev,
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-next {
  background-color: #313338;
  width: 64px;
  height: 64px;
  border-radius: 50px;
}
@media only screen and (max-width: 992px) {
  .article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-prev,
  .article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-next {
    top: unset !important;
    bottom: 0 !important;
  }
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-prev {
  left: 0;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-prev::after {
  content: "";
  background: url("assets/images/icons/angle_left_white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  align-self: center;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-next {
  right: 0;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-button-next::after {
  content: "";
  background: url("assets/images/icons/angle_right_white.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  align-self: center;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-pagination {
  bottom: 0;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  opacity: 0.2 !important;
}
.article__use .article__use-container .article__use-carousel .article__carousel .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--main-color) !important;
  opacity: 1 !important;
}/*# sourceMappingURL=articleStyle.css.map */