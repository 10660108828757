.home {
  width: 100%;
  min-height: 95vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;

  @media only screen and (max-height: 500px) {
    padding: 135px 0px;
  }
  @media only screen and (max-width: 992px) {
    padding: 135px 0px 80px;
    min-height: 75vh;
    clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%);
  }
  @media only screen and (max-height: 655px) {
    min-height: 85vh;
  }
  .home__slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1;
    width: 100%;
    height: 100%;
    z-index: -1;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.65);
      z-index: 1;
    }
    .swiper-wrapper {
      height: 100% !important;

      .home__slider-img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          animation: zoomIn 40s infinite alternate;
          @media only screen and (max-width: 992px) {
            max-width: 1000px !important;
          }
          @media only screen and (max-width: 500px) {
            max-width: 510px !important;
          }
        }
      }
    }
  }
  .home__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;

    .home__text {
      max-width: 530px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 0px var(--inside-padding);

      .Typewriter__cursor {
        font-family: $font-light;
        font-weight: 300;
      }
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold !important;
        color: var(--main-text-color);
        text-align: center;
        span {
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold !important;
          color: var(--main-text-color);
          text-align: center;
        }
      }
      p {
        font-size: var(--l-font-size);
        font-weight: 300;
        font-family: $font-light !important;
        color: var(--main-text-color);
        text-align: center;
        @media only screen and (max-width: 992px) {
          font-size: var(--m-font-size);
          span {
            font-size: var(--m-font-size) !important;
          }
        }
        .Typewriter__cursor {
          display: none;
        }
        span {
          font-size: var(--l-font-size);
          font-weight: 300;
          font-family: $font-light;
          color: var(--main-text-color);
          text-align: center;
        }
      }
    }
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.5); /* Final size (adjust the scale value as needed) */
  }
}
