.article__about {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  .article__about-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px var(--inside-padding);
    gap: 20px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 10px;
      .article__about-content {
        max-width: 100% !important;
      }
      .article__about-img {
        display: none;
      }
    }
    .article__about-content {
      max-width: 65%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      h1 {
        font-size: var(--xl-font-size);
        color: var(--main-color);
        font-family: $font-bold;
        font-weight: 700;
      }
      p {
        font-size: var(--m-font-size);
        color: var(--secondary-text-color);
        font-family: $font-light;
        font-weight: 300;
      }
    }
    .article__about-img {
      max-width: 863px;
      width: 100%;
      overflow: hidden;
      transform: translateY(-60px);
      z-index: 100;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
