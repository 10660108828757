.article__header {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-dark-color);
  padding: 120px 0px;
  min-height: 60vh;
  background: url("assets/images/illustrations/binary/binaryBlueOpacity.webp")
    repeat var(--secondary-dark-color);
  background-position: center;
  background-repeat: repeat;
  overflow-x: hidden;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 0;
  }
  .article__header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
    z-index: 5;
    padding: 0px var(--inside-padding);
    h1 {
      font-size: var(--xl-font-size);
      font-family: $font-bold;
      font-weight: 700;
      color: var(--main-color);
      text-align: center;
    }
    p {
      max-width: 65%;
      font-size: var(--m-font-size);
      font-family: $font-light;
      font-weight: 300;
      color: var(--main-text-color);
      text-align: center;
      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
    button {
      margin-top: 15px;
    }
  }
}
