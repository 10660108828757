.home__contact-number {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 0px 50px;
  background-color: var(--secondary-dark-color);
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  .home__contact-container {
    width: 100%;
    padding: 0px var(--inside-padding);
    display: flex;
    align-items: center;
    justify-content: center;
    .home__contact-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        gap: 40px;

        .home__contact-content {
          width: 100% !important;
          justify-content: center !important;
          align-items: center !important;
          h1,
          h2 {
            text-align: center !important;
          }
        }
      }
      .home__contact-content {
        width: 40%;
        display: flex;
        flex-direction: column;
        h1 {
          font-size: 2.2rem;
          font-family: $font-bold;
          color: var(--main-text-color);
          font-weight: 700;
          span {
            color: var(--main-color);
          }
        }
        h2 {
          font-size: var(--m-font-size);
          font-family: $font-light;
          color: var(--main-secondary-text-color);
          font-weight: 300;
        }
        form {
          margin-top: 20px;
          display: flex;
          width: 100%;
          max-width: 330px;
          height: 45px;

          div {
            position: relative;
            width: 100%;
            display: flex;
            gap: 3px;
            height: 100%;
            background-color: var(--secondary-dark-body-color);
            border-radius: 5px 0px 0px 5px;
            overflow: hidden;
            padding: 3px;

            select {
              border: none;
              background-color: #0e1e30;
              border-radius: 4px 2px 2px 4px;
              color: var(--main-text-color);
              font-family: $font-medium;
              font-weight: 500;
              font-size: var(--s-font-size);
              outline: none;
            }
            .contact__phone-number-input {
              width: 100%;
              background-color: transparent;
              border: none;
              outline: none;
              color: var(--main-text-color);
              font-family: $font-light;
              font-weight: 300;
              font-size: var(--m-font-size);
              -moz-appearance: textfield;
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          button {
            width: 55px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 4px 4px 0px;
            border: none;
            outline: none;
            background-color: #0e1e30;
            cursor: pointer;
            svg {
              width: 18px;
              height: 18px;
              color: var(--main-text-color);
            }
            &:hover {
              background-color: var(--secondary-light-red);
            }
          }
        }
        .error__message {
          width: 100%;
          padding: 5px 0px;
          h2 {
            font-size: var(--xs-font-size);
            color: var(--main-text-color);
            font-weight: 300;
            font-family: $font-light;
            letter-spacing: 0.25px;
            &.error {
              color: var(--secondary-light-red);
              font-family: $font-medium;
            }
          }
        }
        .contact__number-recaptcha {
          // width: 100%;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .home__contact-img {
        width: 100%;
        max-width: 420px;
        img {
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}
