.programs__section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color-2);
  padding: 120px 0 170px;
  // background: url("assets/svg/bloody_round_contact_svg.svg") repeat
  //   var(--secondary-purple-color);

  // background-position: bottom;
  // background-repeat: repeat-x;
  // background-size: 100%;
  @media only screen and (max-width: 992px) {
    padding: 110px 0 15vw;
  }
  .programs__container {
    width: 100%;
    max-width: 1230px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    gap: 90px;
    padding: 0px var(--inside-padding);
    @media only screen and (max-width: 692px) {
      gap: 50px;
    }
    .programs__section__intro {
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      @media only screen and (max-width: 692px) {
        p {
          font-size: var(--s-font-size) !important;
        }
      }
      .divider {
        width: 60%;
        height: 3px;
        display: block;
        background-color: var(--secondary-subtitle-text-color);
      }
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--secondary-text-color);
      }
      p {
        font-family: $font-light;
        font-size: var(--l-font-size);
        font-weight: 300;
        color: var(--secondary-subtitle-text-color);
      }
    }
    .programs__cards-section {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      gap: 100px;
      .programs__section-title {
        font-size: var(--xl-font-size);
        color: var(--secondary-text-color);
        font-weight: 700;
        font-family: $font-bold;
        @media only screen and (max-width: 992px) {
          font-size: var(--l-font-size);
        }
      }
    }
  }
}

// Cards Section setup
.programs__section__grid {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .programs__section__grid__title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    h1 {
      font-family: $font-medium;
      font-size: var(--xl-font-size);
      color: var(--main-text-color);
      font-weight: 500;
      position: relative;
    }
    h2 {
      font-family: $font-light;
      font-size: var(--m-font-size);
      font-weight: 300;
      color: var(--main-text-color);
    }
  }
  .programs__section__grid__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}
@media only screen and (max-width: 1230px) {
  .programs__section__grid {
    max-width: 822px !important;
  }
  .programs__section__grid__cards {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

// Programs Card Design
.program__card {
  position: relative;
  max-width: 390px;
  width: 100%;
  // max-height: 465px;
  height: 465px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  padding: 6px;
  background-color: rgba($color: #fff, $alpha: 0.9);
  border: 2px solid rgba($color: #fff, $alpha: 0.2);
  overflow: hidden;
  box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
  @media only screen and (max-width: 992px) {
    max-width: 380px;
    height: 420px;
    .program__card-content {
      h1 {
        font-size: var(--m-font-size) !important;
      }
      span {
        font-size: var(--xs-font-size) !important;
      }
    }
  }
  &:hover {
    background-color: rgba($color: #000, $alpha: 0.2);
    border: 2px solid rgba($color: #000, $alpha: 0.2);
    transition: 0.4s ease-in;
    .program__card-divider {
      background-color: rgba($color: #fff, $alpha: 0.5);
    }
    .program__card-bgImg {
      right: -20px;
      transition: 0.25s ease-in;
    }
    .program__card-content {
      h1,
      p {
        color: var(--main-text-color);
      }
      span {
        color: var(--main-text-color);
        &::after {
          background-color: rgba($color: #000, $alpha: 0);
          border: 1px solid rgba($color: #fff, $alpha: 0.3);
          transition: 0.25s ease-in;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    &.yellow-theme {
      &:hover {
        background-color: var(--secondary-color);
        button {
          background-color: var(--secondary-purple-color) !important;
        }
      }
    }
    &.purple-theme {
      &:hover {
        background-color: var(--secondary-purple-color);
        button {
          background-color: var(--secondary-light-green-color) !important;
        }
      }
    }
    &.darkBlue-theme {
      &:hover {
        background-color: var(--secondary-dark-body-color);
        button {
          background-color: var(--main-color) !important;
        }
      }
    }
    &.blue-theme {
      &:hover {
        background-color: var(--footer-color);
        button {
          background-color: var(--secondary-light-red) !important;
        }
      }
    }
    &.lightBlue-theme {
      &:hover {
        background-color: var(--main-color);
        button {
          background-color: var(--secondary-light-red) !important;
        }
      }
    }
    &.red-theme {
      &:hover {
        background-color: var(--secondary-light-red);
        button {
          background-color: var(--secondary-color) !important;
        }
      }
    }
  }
  .program__card-bgImg {
    content: "";
    position: absolute;
    bottom: -9px;
    right: -100px;
    max-width: 100px;
    width: 100%;
    height: 100px;
    z-index: 0;
    opacity: 0.4;
    transition: 0.25s ease-in;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .program__card-img {
    width: 100%;
    // height: 50%;
    flex: 0.6;
    overflow: hidden;
    border-radius: 15px 15px 5px 5px;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }
  .program__card-divider {
    width: 100%;
    height: 2px;
    border-radius: 100px;
    z-index: 1;
    background-color: rgba($color: $secondary-text-color, $alpha: 0.2);
    z-index: 1;
  }
  .program__card-content {
    flex: 0.4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
    z-index: 1;
    div {
      margin-top: 5px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
    h1 {
      font-size: var(--m-font-size);
      color: var(--secondary-text-color);
      font-weight: 700;
      font-family: $font-bold;
      text-align: center;
      align-self: center;
    }
    p {
      font-size: var(--xs-font-size);
      color: var(--secondary-text-color);
      font-weight: 300;
      font-family: $font-light;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    span {
      position: relative;
      padding: 6px;
      font-size: var(--s-font-size);
      color: var(--secondary-text-color);
      font-family: $font-light;
      align-self: center;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color: $main-text-color, $alpha: 0.7);
        width: 100%;
        height: 100%;
        border-radius: 15px;
        z-index: -1;
        border: 1px solid rgba($color: $secondary-text-color, $alpha: 0.3);
        transition: 0.25s ease-in;
      }
    }
  }
}

// Contact
.programs__contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  background-color: var(--secondary-color);
  .programs__contact-container {
    width: 100%;
    max-width: 1230px;
    padding: 0px var(--inside-padding);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 100px;
    @media only screen and (max-width: 1210px) {
      max-width: 500px;
    }
    .programs__contact-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 5px;
    }
  }
}
