.dropdown__container {
  width: 100%;
  height: 65px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  .dropdown__input {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    overflow: hidden;

    &:focus {
      border: 1px solid rgba($color: $main-color, $alpha: 0.5) !important;
      padding: 10px !important;
      background-color: rgba(
        $color: var(--footer-color),
        $alpha: 0.5
      ) !important;

      h1 {
        color: var(--main-secondary-text-color) !important;
        -webkit-text-fill-color: var(--main-color) !important;
        transition: 0.3s ease-in-out;
      }
    }
    @media only screen and (min-width: 992px) {
      &:hover {
        border: 1.7px solid var(--main-color);
        transition: 0.1s ease-out;

        h1 {
          padding-left: 10px;
          color: var(--main-color) !important;
          -webkit-text-fill-color: var(--main-color) !important;
          transition: 0.3s ease-in-out;
        }
      }
    }
    h1 {
      font-size: var(--m-font-size);
      font-weight: 500;
      font-family: $font-medium;
      color: var(--main-secondary-text-color) !important;
      -webkit-text-fill-color: var(--main-secondary-text-color) !important;
      transition: 0.3s ease-in-out;
      @media only screen and (max-width: 680px) {
        font-size: var(--xs-font-size) !important;
      }
    }
  }
  .dropdown__menu {
    border-radius: 15px;
    border: 1px solid var(--main-color);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: var(--footer-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    h1 {
      font-size: var(--xs-font-size);
      color: var(--main-text-color);
      font-weight: 500;
      font-family: $font-medium;
    }
    .dropdown__item {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
  }
}
