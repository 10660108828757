.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  background-color: transparent;
  position: fixed;
  z-index: 999;
  top: -70px;
  flex-direction: column;
  box-shadow: 0px 0px 0px rgba($color: #000000, $alpha: 0);
  .nav__container {
    width: 100%;
    max-width: 1400px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    padding: 2px var(--inside-padding);
    @media only screen and (max-width: 992px) {
      justify-content: space-between !important;
    }
    .nav__icon {
      width: 173px;
      height: 48px;
      overflow: hidden;
      cursor: pointer;
      padding-top: 3px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media only screen and (max-width: 992px) {
        width: 120px;
      }
    }
    .nav__menu {
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        gap: 20px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        li {
          .active {
            &:focus {
              color: var(--secondary-light-green-color) !important;
              letter-spacing: unset !important;
            }
          }
          a {
            word-wrap: normal !important;
            font-size: var(--m-font-size);
            font-weight: 500;
            font-family: $font-medium;
            color: var(--main-text-color);
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            &:focus {
              color: var(--secondary-light-green-color) !important;
              letter-spacing: 0.02rem !important;
            }
            @media only screen and (min-width: 992px) {
              &:hover {
                a {
                  color: var(--main-color);
                }
                &::before {
                  width: 75%;
                }
              }
            }

            &::before {
              content: "";
              background-color: var(--secondary-color);
              position: absolute;
              width: 0%;
              height: 2px;
              bottom: 0;
              transition: 0.35s all ease-in-out;
            }
          }
        }
      }
    }
  }
}
.nav__menu-mobile {
  display: none;
}

// =============== Mobile Devices =============== //

@media only screen and (max-width: 992px) {
  .navbar {
    top: 0;
    height: 135px;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    .nav__container {
      .nav__icon {
        height: 60px;
      }
    }
  }
  // Navbar Collapse Button
  .nav__collapse {
    width: 40px;
    height: 50px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;

    .nav__collapse-btn {
      width: 100%;
      height: 2px;
      position: absolute;
      top: 15px;
      border-radius: 5px;
      transition: 0.35s all ease-in-out;
      &.blue-color {
        background-color: var(--main-color);
        &::before,
        &::after {
          background-color: var(--main-color);
        }
      }
      &.white-color {
        background-color: var(--main-text-color);
        &::before,
        &::after {
          background-color: var(--main-text-color);
        }
      }
      &::before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 10px;
        transition: 0.35s all ease-in-out;
        border-radius: 5px;
      }
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 10px;
        transition: 0.35s all ease-in-out;
        bottom: 0;
        top: 20px;
        border-radius: 5px;
      }
    }
    @media only screen and (min-width: 600px) {
      &:hover {
        .nav__collapse-btn {
          background-color: #4a5c70;
          &::before {
            background-color: #4a5c70;
          }
          &::after {
            background-color: #4a5c70;
          }
        }
      }
    }
  }
  .nav__collapse.open-collapse {
    width: 40px;
    height: 40px;
    padding-bottom: 5px;
    .nav__collapse-btn {
      top: 30px;
      left: 1px;
      top: 20px;
      transform: rotate(50deg);
      &::before {
        top: -0px;
        transform: rotate(80deg);
        transition: 0.35s all ease-in-out;
      }
      &::after {
        opacity: 0;
        transition: 0.35s all ease-in-out;
      }
    }
  }

  // Navbar Menu
  .nav__menu-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-text-color);
    // background-color: var(--main-text-color);
    bottom: 0;
    left: -400vh;
    z-index: 700 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 135px 10px var(--inside-padding);
    overflow-y: scroll;
    overflow-x: hidden;
    .nav__menu-mobile-wrapper {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;

      .nav__credits {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
      }
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        li {
          &.hidden-navLink {
            display: none;
          }
          .active {
            &:focus {
              color: var(--secondary-light-green-color) !important;
              letter-spacing: unset !important;
            }
          }
        }

        *:not(:last-child) {
          a {
            font-size: 2rem;
            font-weight: 300;
            font-family: $font-light;
            color: var(--main-text-color);
            position: relative;
            width: max-content;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            mix-blend-mode: exclusion;
            &:focus {
              color: var(--secondary-light-green-color);
              letter-spacing: 0.1rem;
            }

            @media only screen and (max-width: 430px) {
              font-size: 6.66vw !important;
            }
            &.active {
              &::before {
                content: "";
                background-color: var(--secondary-color);
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                transition: 0.35s all ease-in-out;
              }
            }
          }
        }
      }
      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--main-text-color);
        span {
          color: var(--main-color);
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold;
        }
      }
      p {
        font-size: var(--xs-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--main-text-color);
        text-align: left;
      }
      .nav__mobile-title {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
      }
      .nav__stem-img-mobile {
        max-width: 100px;
        overflow: hidden;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .nav__menu-mobile.open-nav {
    position: fixed;
    left: 0;
  }
  .nav__menu {
    display: none;
  }
  .nav__close {
    width: 30px;
    height: 30px;
    svg {
      width: 30px;
      height: 30px;
      color: var(--main-text-color);
      transition: 0.35s all ease-in-out;
    }
    &:hover {
      transition: 0.35s all ease-in-out;
      svg {
        color: var(--main-color);
        transition: 0.35s all ease-in-out;
      }
      cursor: pointer;
    }
  }
}

.nav__btn {
  margin-top: 10px;
}
// Contrast Navbar
.contrast-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 115px;
  background-color: var(--main-text-color);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 9999 !important;
  .nav__container {
    padding-bottom: 7px;
  }
  ul {
    li {
      .nav__stem-img {
        top: -18px;
      }
      a {
        color: var(--secondary-dark-color) !important;
        // &::before {
        //   background-color: var(--main-color) !important;
        // }
      }
    }
    .active {
      color: var(--secondary-light-green-color) !important;
    }
  }
}

// Sticky Navbar
.sticky-nav {
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 115px;
  gap: 0;
  background-color: var(--main-text-color);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 9999 !important;

  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
  .nav__container {
    padding-bottom: 7px;
  }
  @media only screen and (max-width: 992px) {
    height: 70px;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }
  ul {
    li {
      a {
        color: var(--secondary-dark-color) !important;
        // &::before {
        //   background-color: var(--main-color) !important;
        // }
      }
      .nav__stem-img {
        position: absolute;
        top: -7px;
        max-width: 65px;
        overflow: hidden;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
    .active {
      color: var(--secondary-light-green-color) !important;
    }
  }
}

// Hide navbar
.hide-navbar {
  top: -100vh;
}

// Active Link
.active {
  color: var(--secondary-light-green-color) !important;
  position: relative;
  padding: 0px 5px;
  &::before {
    width: 100% !important;
  }
}

// Display none for Register nav link
.register-display-none {
  display: none;
}

.register-display-none.active {
  display: unset !important;
}
.hidden-navLinks {
  display: none;
}

.nav__stem-img {
  position: absolute;
  top: -23px;
  max-width: 100px;
  overflow: hidden;
  z-index: 10;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.nav__menu-mobile.open-nav ~ .navbar {
  background-color: var(--secondary-text-color);
  transition-delay: 0.25s;
}
