.home__methodology {
  width: 100%;
  padding: 90px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  .home__methodology-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    padding: 0px var(--inside-padding);
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 50px;
      .home__methodology-content {
        align-items: center !important;
        .home__methodology-title {
          align-items: center !important;
          justify-content: center !important;
          text-align: center !important;
        }
        p {
          max-width: 80%;
          text-align: left !important;
          span {
            justify-content: center !important;
            text-align: left !important;
          }
          @media only screen and (max-width: 520px) {
            max-width: 100% !important;
            font-size: var(--s-font-size) !important;
            span {
              font-size: var(--s-font-size) !important;
            }
          }
        }
      }
      .home__methodology-img {
        max-width: 350px !important;
        max-height: 300px !important;
        min-height: 150px !important;
      }
    }

    .home__methodology-content {
      width: 100%;
      height: 30% !important;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 35px;
      overflow: hidden;
      .home__methodology-title {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h1 {
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold;
          color: var(--secondary-text-color);
        }
        h2 {
          font-size: var(--l-font-size);
          font-weight: 500;
          font-family: $font-medium;
          color: var(--secondary-text-color);
        }
      }
      p {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        text-align: left;
        color: var(--secondary-text-color);
        span {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          gap: 10px;
          font-size: var(--m-font-size);
          font-weight: 500;
          font-family: $font-medium;
          text-align: left;
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
      }
    }
    .home__methodology-img {
      width: 100%;
      // height: 100%;
      max-width: 570px;
      height: 480px;
      min-height: 300px;
      overflow: hidden;
      border-radius: 20px;
      filter: brightness(0.8);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
