@charset "UTF-8";
@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.home {
  width: 100%;
  min-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  overflow: hidden;
}
@media only screen and (max-height: 500px) {
  .home {
    padding: 135px 0px;
  }
}
@media only screen and (max-width: 992px) {
  .home {
    padding: 135px 0px 80px;
    min-height: 75vh;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%);
  }
}
@media only screen and (max-height: 655px) {
  .home {
    min-height: 85vh;
  }
}
.home .home__slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.home .home__slider::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
.home .home__slider .swiper-wrapper {
  height: 100% !important;
}
.home .home__slider .swiper-wrapper .home__slider-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.home .home__slider .swiper-wrapper .home__slider-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  animation: zoomIn 40s infinite alternate;
}
@media only screen and (max-width: 992px) {
  .home .home__slider .swiper-wrapper .home__slider-img img {
    max-width: 1000px !important;
  }
}
@media only screen and (max-width: 500px) {
  .home .home__slider .swiper-wrapper .home__slider-img img {
    max-width: 510px !important;
  }
}
.home .home__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .home__container .home__text {
  max-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0px var(--inside-padding);
}
.home .home__container .home__text .Typewriter__cursor {
  font-family: "Poppins-Light";
  font-weight: 300;
}
.home .home__container .home__text h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold" !important;
  color: var(--main-text-color);
  text-align: center;
}
.home .home__container .home__text h1 span {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold" !important;
  color: var(--main-text-color);
  text-align: center;
}
.home .home__container .home__text p {
  font-size: var(--l-font-size);
  font-weight: 300;
  font-family: "Poppins-Light" !important;
  color: var(--main-text-color);
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .home .home__container .home__text p {
    font-size: var(--m-font-size);
  }
  .home .home__container .home__text p span {
    font-size: var(--m-font-size) !important;
  }
}
.home .home__container .home__text p .Typewriter__cursor {
  display: none;
}
.home .home__container .home__text p span {
  font-size: var(--l-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: center;
}

@keyframes zoomIn {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.5); /* Final size (adjust the scale value as needed) */
  }
}
.home__about {
  width: 100%;
  padding: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__about h1 {
  color: var(--main-color) !important;
}
.home__about p {
  color: var(--secondary-text-color) !important;
}
.home__about .about__svg {
  max-width: 400px !important;
  transform: none !important;
}
@media only screen and (max-width: 992px) {
  .home__about {
    padding: 40px 0px 0px;
  }
}
.home__about .home__about-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px var(--inside-padding);
  background-color: rgba(212, 212, 212, 0);
  transition: 0.4s ease-in;
  gap: 25px;
}

@media only screen and (max-width: 992px) {
  .home__about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
}
.home__programs {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: var(--main-secondary-text-color-2);
}
.home__programs .home__programs-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 100px;
  padding: 0px var(--inside-padding);
}
.home__programs .home__programs-content .home__programs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 25px;
}
.home__programs .home__programs-content .home__programs-wrapper .home__programs-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.home__programs .home__programs-content .home__programs-wrapper .home__programs-title h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-text-color);
}
.home__programs .home__programs-container {
  width: 100%;
  display: flex;
  gap: 30px 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  -webkit-gap: 30px 20px;
  -moz-gap: 30px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -ms-flexbox;
}

.home__programs-card {
  position: relative;
  max-width: 330px;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  border: 0.5px solid transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  background-color: var(--main-text-color);
  box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.02), 1px 37px 24px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 600px) {
  .home__programs-card {
    min-height: 350px;
    height: unset;
    justify-content: flex-start;
  }
}
.home__programs-card.yellow-theme:hover {
  background-color: var(--secondary-color);
}
.home__programs-card.yellow-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.yellow-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.yellow-theme:hover .home__programs-content-titles h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.yellow-theme:hover .home__programs-content-titles h2::after {
  background-color: var(--secondary-purple-color);
}
.home__programs-card.yellow-theme:hover button {
  background-color: var(--secondary-purple-color) !important;
}
.home__programs-card.purple-theme:hover {
  background-color: var(--secondary-purple-color);
}
.home__programs-card.purple-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.purple-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.purple-theme:hover .home__programs-content-titles h1 {
  color: var(--main-text-color) !important;
}
.home__programs-card.purple-theme:hover .home__programs-content-titles h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.purple-theme:hover .home__programs-content-titles h2::after {
  background-color: var(--secondary-light-green-color);
}
.home__programs-card.purple-theme:hover button {
  background-color: var(--secondary-light-green-color) !important;
}
.home__programs-card.darkBlue-theme:hover {
  background-color: var(--secondary-dark-body-color);
}
.home__programs-card.darkBlue-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.darkBlue-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.darkBlue-theme:hover .home__programs-content-titles h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.darkBlue-theme:hover .home__programs-content-titles h2::after {
  background-color: var(--main-color);
}
.home__programs-card.darkBlue-theme:hover button {
  background-color: var(--main-color) !important;
}
.home__programs-card.blue-theme:hover {
  background-color: var(--footer-color);
}
.home__programs-card.blue-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.blue-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.blue-theme:hover .home__programs-content-titles h1 {
  color: var(--main-text-color) !important;
}
.home__programs-card.blue-theme:hover .home__programs-content-titles h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.blue-theme:hover .home__programs-content-titles h2::after {
  background-color: var(--secondary-light-red);
}
.home__programs-card.blue-theme:hover button {
  background-color: var(--secondary-light-red) !important;
}
.home__programs-card.red-theme:hover {
  background-color: var(--secondary-light-red);
}
.home__programs-card.red-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.red-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.red-theme:hover .home__programs-content-titles h1 {
  color: var(--main-text-color) !important;
}
.home__programs-card.red-theme:hover .home__programs-content-titles h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.red-theme:hover .home__programs-content-titles h2::after {
  background-color: var(--secondary-color);
}
.home__programs-card.red-theme:hover button {
  background-color: var(--secondary-color) !important;
}
.home__programs-card.lightBlue-theme:hover {
  background-color: var(--main-color);
}
.home__programs-card.lightBlue-theme:hover .home__programs-content-titles .home__programs-subtitle {
  border: solid 1px var(--main-text-color) !important;
}
.home__programs-card.lightBlue-theme:hover .home__programs-content-titles .home__programs-subtitle h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.lightBlue-theme:hover .home__programs-content-titles span h1 {
  color: var(--main-text-color) !important;
}
.home__programs-card.lightBlue-theme:hover .home__programs-content-titles span h2 {
  color: var(--main-text-color) !important;
}
.home__programs-card.lightBlue-theme:hover .home__programs-content-titles span h2::after {
  background-color: var(--secondary-light-red);
}
.home__programs-card.lightBlue-theme:hover button {
  background-color: var(--secondary-light-red) !important;
}
.home__programs-card .home__programs-bgImg {
  position: absolute;
  max-width: 170px;
  width: 100%;
  overflow: hidden;
  bottom: -60%;
  z-index: 0;
}
.home__programs-card .home__programs-bgImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.home__programs-card .home__programs-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .home__programs-card .home__programs-img {
    height: 100%;
    max-height: 200px;
  }
}
.home__programs-card .home__programs-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home__programs-card .home__programs-content-card {
  display: flex;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 30px;
  border: 0px 0px 20px 20px;
  padding: 0px 20px 15px;
  z-index: 1;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  height: 100%;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles .summer__program-title {
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-dark-body-color);
  text-align: center;
  text-transform: none;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles .home__programs-subtitle {
  padding: 7px 10px;
  border-radius: 50px;
  border: solid 1px var(--secondary-dark-body-color);
}
.home__programs-card .home__programs-content-card .home__programs-content-titles .home__programs-subtitle h2 {
  font-size: var(--xs-font-size);
  font-family: "Poppins-Medium";
  color: var(--secondary-dark-body-color);
  font-weight: 500;
  letter-spacing: 0.25px;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles span {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  justify-content: center;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles span h1 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-dark-body-color);
  text-align: center;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles span h2 {
  position: relative;
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-dark-body-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.home__programs-card .home__programs-content-card .home__programs-content-titles span h2::after {
  position: absolute;
  content: "";
  bottom: -5px;
  height: 2px;
  width: 80%;
  background-color: var(--secondary-purple-color);
  border-radius: 20px;
}
.home__programs-card .home__programs-content-card a button {
  width: 180px;
  height: 45px;
  font-size: var(--s-font-size) !important;
}
@media only screen and (min-width: 992px) {
  .home__programs-card:hover {
    padding: 15px;
    box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.07), 1px 37px 24px rgba(0, 0, 0, 0.14);
    background-color: var(--secondary-dark-body-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .home__programs-card:hover .home__programs-content-card {
    padding: 0px 0px 10px;
  }
  .home__programs-card:hover .home__programs-content-card .home__programs-subtitle {
    padding: 5px 15px;
  }
  .home__programs-card:hover .home__programs-img {
    border-radius: 10px 10px 5px 5px;
  }
  .home__programs-card:hover .home__programs-bgImg {
    bottom: -40%;
  }
}
@media only screen and (max-width: 992px) {
  .home__programs-card:hover {
    padding: 15px;
    box-shadow: 0.1px 4.6px 3px rgba(0, 0, 0, 0.07), 1px 37px 24px rgba(0, 0, 0, 0.14);
    background-color: var(--footer-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .home__programs-card:hover .home__programs-img {
    border-radius: 10px 10px 5px 5px;
  }
}

.wavy-svg {
  width: 100%;
  height: 190px;
}
@media only screen and (max-width: 692px) {
  .wavy-svg {
    height: 100px;
  }
}
.wavy-svg img {
  width: 100%;
  height: 130%;
  -o-object-fit: cover;
     object-fit: cover;
}

.bloody-svg {
  width: 100%;
  height: 182px;
  background-image: url("assets/vectors/bloody_vector_down_blue.svg") !important;
  background-repeat: repeat-x;
  background-size: 1260px;
  background-position: bottom;
}
.bloody-svg div {
  top: 0;
}

.home__methodology {
  width: 100%;
  padding: 90px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__methodology .home__methodology-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 992px) {
  .home__methodology .home__methodology-container {
    flex-direction: column;
    gap: 50px;
  }
  .home__methodology .home__methodology-container .home__methodology-content {
    align-items: center !important;
  }
  .home__methodology .home__methodology-container .home__methodology-content .home__methodology-title {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }
  .home__methodology .home__methodology-container .home__methodology-content p {
    max-width: 80%;
    text-align: left !important;
  }
  .home__methodology .home__methodology-container .home__methodology-content p span {
    justify-content: center !important;
    text-align: left !important;
  }
}
@media only screen and (max-width: 992px) and (max-width: 520px) {
  .home__methodology .home__methodology-container .home__methodology-content p {
    max-width: 100% !important;
    font-size: var(--s-font-size) !important;
  }
  .home__methodology .home__methodology-container .home__methodology-content p span {
    font-size: var(--s-font-size) !important;
  }
}
@media only screen and (max-width: 992px) {
  .home__methodology .home__methodology-container .home__methodology-img {
    max-width: 350px !important;
    max-height: 300px !important;
    min-height: 150px !important;
  }
}
.home__methodology .home__methodology-container .home__methodology-content {
  width: 100%;
  height: 30% !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 35px;
  overflow: hidden;
}
.home__methodology .home__methodology-container .home__methodology-content .home__methodology-title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.home__methodology .home__methodology-container .home__methodology-content .home__methodology-title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-text-color);
}
.home__methodology .home__methodology-container .home__methodology-content .home__methodology-title h2 {
  font-size: var(--l-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color);
}
.home__methodology .home__methodology-container .home__methodology-content p {
  font-size: var(--m-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  text-align: left;
  color: var(--secondary-text-color);
}
.home__methodology .home__methodology-container .home__methodology-content p span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: var(--m-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  text-align: left;
}
.home__methodology .home__methodology-container .home__methodology-content p span img {
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}
.home__methodology .home__methodology-container .home__methodology-img {
  width: 100%;
  max-width: 570px;
  height: 480px;
  min-height: 300px;
  overflow: hidden;
  border-radius: 20px;
  filter: brightness(0.8);
}
.home__methodology .home__methodology-container .home__methodology-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.home__success {
  width: 100%;
  height: 100%;
  padding: 70px 0px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color);
  overflow: hidden;
}
.home__success .about__success-title h1 {
  color: var(--secondary-text-color) !important;
}
.home__success .about__success-title h2 {
  color: var(--secondary-subtitle-text-color) !important;
}

.home__testimonials {
  position: relative;
  width: 100%;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
}
.home__testimonials::before {
  content: "";
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: url("assets/images/testimonials/binaryNumbersBg.png");
  background-repeat: repeat;
  background-position: center;
  background-size: 181.78px 313.21px;
  background-attachment: fixed;
  z-index: 0;
  opacity: 0.3;
}
.home__testimonials .home__testimonials-container {
  width: 100%;
  padding: 0px var(--inside-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container {
    justify-content: center;
    align-items: center;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-img {
  max-width: 50%;
  width: 100%;
  height: 300px;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-img {
    display: none;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container {
    width: 100%;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title {
    align-items: center;
    justify-content: center;
  }
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title h1,
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title h2 {
    text-align: center !important;
  }
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title h2 {
    font-size: var(--m-font-size) !important;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-light-red);
  text-align: left;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-title h2 {
  font-size: var(--l-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--main-secondary-text-color);
  text-align: left;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .swiper.swiper-initialized {
  position: relative;
  width: 100% !important;
  overflow: visible;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content {
    width: 100%;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  gap: 10px;
  padding: 10px 10px 10px;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards {
    flex-direction: column;
    gap: 10px;
  }
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info {
    max-width: 100% !important;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-img {
  position: relative;
  max-width: 170px !important;
  width: 100%;
  height: 170px;
  background-color: rgba(12, 27, 43, 0.4);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--secondary-light-red);
  border-radius: 100%;
  -webkit-backdrop-filter: Blur(1px);
          backdrop-filter: Blur(1px);
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
@media only screen and (max-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info {
    align-items: center;
  }
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info h3,
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info h4,
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info p {
    text-align: center;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info p {
  font-size: var(--s-font-size);
  font-family: "Poppins-Light";
  font-weight: lighter;
  color: var(--main-text-color);
}
@media only screen and (min-width: 992px) {
  .home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info p {
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 8;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info p::before {
  content: "🙶";
  color: var(--secondary-light-red);
  font-size: var(--xs-font-size);
  padding-right: 2px;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info p::after {
  content: "🙷";
  color: var(--secondary-light-red);
  font-size: var(--xs-font-size);
  padding-left: 2px;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info h3 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-light-red);
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .home__testimonials-content .home__testimonials-cards .home__testimonials-info h4 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  font-weight: 300;
  color: var(--main-secondary-text-color);
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .swiper.swiper-initialized {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .swiper-pagination {
  position: unset !important;
  width: 120px !important;
  transform: unset !important;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .swiper-pagination-bullet {
  background-color: var(--secondary-dark-body-color) !important;
  width: 15px !important;
  height: 15px !important;
  opacity: 1 !important;
}
.home__testimonials .home__testimonials-container .home__testimonials-info-container .swiper-pagination-bullet-active {
  background-color: var(--secondary-light-red) !important;
  width: 20px !important;
  height: 15px !important;
  border-radius: 10px !important;
  transition: 0.4s ease-in;
}

.home__partners {
  width: 100%;
  padding: 100px 0px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__partners .home__partners-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 0px var(--inside-padding);
}
.home__partners .home__partners-container .home__partners-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.home__partners .home__partners-container .home__partners-title h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-text-color);
  text-align: center;
}
.home__partners .home__partners-container .home__partners-title h2 {
  font-size: var(--l-font-size);
  font-weight: 500;
  font-family: "Poppins-Medium";
  color: var(--secondary-text-color);
  text-align: center;
}
.home__partners .home__partners-container .home__partners-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
}
.home__partners .home__partners-container .home__partners-content .simple-pagination-swiper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.home__partners .home__partners-container .home__partners-content .simple-pagination-swiper .swiper-pagination {
  display: inline-block;
  position: unset;
}
.home__partners .home__partners-container .home__partners-content .simple-pagination-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--main-color) !important;
  background-color: var(--main-color) !important;
}
.home__partners .home__partners-container .home__partners-content .simple-pagination-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}
.home__partners .home__partners-container .home__partners-content .home__partners-img {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  min-width: 200px;
  height: 120px;
  width: 100%;
  overflow: hidden;
}
.home__partners .home__partners-container .home__partners-content .home__partners-img img {
  width: 100%;
  aspect-ratio: 3/2;
  -o-object-fit: contain;
     object-fit: contain;
}

.home__contact-number {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 0px 50px;
  background-color: var(--secondary-dark-color);
}
@media only screen and (max-width: 992px) {
  .home__contact-number {
    padding-top: 40px;
  }
}
.home__contact-number .home__contact-container {
  width: 100%;
  padding: 0px var(--inside-padding);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__contact-number .home__contact-container .home__contact-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media only screen and (max-width: 992px) {
  .home__contact-number .home__contact-container .home__contact-wrapper {
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
  .home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content h1,
  .home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content h2 {
    text-align: center !important;
  }
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content h1 {
  font-size: 2.2rem;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  font-weight: 700;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content h1 span {
  color: var(--main-color);
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content h2 {
  font-size: var(--m-font-size);
  font-family: "Poppins-Light";
  color: var(--main-secondary-text-color);
  font-weight: 300;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form {
  margin-top: 20px;
  display: flex;
  width: 100%;
  max-width: 330px;
  height: 45px;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form div {
  position: relative;
  width: 100%;
  display: flex;
  gap: 3px;
  height: 100%;
  background-color: var(--secondary-dark-body-color);
  border-radius: 5px 0px 0px 5px;
  overflow: hidden;
  padding: 3px;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form div select {
  border: none;
  background-color: #0e1e30;
  border-radius: 4px 2px 2px 4px;
  color: var(--main-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: var(--s-font-size);
  outline: none;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form div .contact__phone-number-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  font-size: var(--m-font-size);
  -moz-appearance: textfield;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form div .contact__phone-number-input::-webkit-inner-spin-button, .home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form div .contact__phone-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form button {
  width: 55px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;
  border: none;
  outline: none;
  background-color: #0e1e30;
  cursor: pointer;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form button svg {
  width: 18px;
  height: 18px;
  color: var(--main-text-color);
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content form button:hover {
  background-color: var(--secondary-light-red);
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content .error__message {
  width: 100%;
  padding: 5px 0px;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content .error__message h2 {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
  letter-spacing: 0.25px;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content .error__message h2.error {
  color: var(--secondary-light-red);
  font-family: "Poppins-Medium";
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-content .contact__number-recaptcha {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-img {
  width: 100%;
  max-width: 420px;
}
.home__contact-number .home__contact-container .home__contact-wrapper .home__contact-img img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

.about__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.about__text button {
  margin-top: 10px;
}
@media only screen and (max-width: 692px) {
  .about__text h1 {
    font-size: var(--l-font-size) !important;
    text-align: center !important;
  }
  .about__text p {
    font-size: var(--s-font-size) !important;
    text-align: center !important;
  }
}
.about__text h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  text-align: left;
}
.about__text p {
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: left;
}

.about__svg {
  max-width: 538px;
  width: 100%;
  overflow: hidden;
  transform: translateY(50px);
}
.about__svg img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media only screen and (max-width: 992px) {
  .about__text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about__text p {
    text-align: center;
  }
}
.about {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
}
.about .about__header-vectors {
  position: absolute;
  bottom: -10%;
  z-index: 0;
}
.about .about__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 136px 0px 0px;
  min-height: 50vh;
}
@media only screen and (max-width: 992px) {
  .about .about__wrapper {
    padding: 136px 0px 20px;
  }
}
.about .about__wrapper .about__container {
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  padding: 0px var(--inside-padding);
  gap: 30px;
}
.about .about__wrapper .about__container p {
  width: 100%;
  color: var(--main-text-color);
}
@media only screen and (max-width: 992px) {
  .about .about__wrapper .about__container {
    justify-content: space-around !important;
  }
  .about .about__wrapper .about__container .about__svg-mobile {
    display: none !important;
  }
  .about .about__wrapper .about__container .about__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .about .about__wrapper .about__container .about__text p {
    color: var(--main-text-color);
  }
}
.about::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 300px;
  height: 100%;
  border-radius: 0px 0px 0px 50px;
  background: url("assets/images/testimonials/binaryNumbersBg.png");
  background-size: contain;
  background-repeat: repeat-y;
  transform: translateX(-30px);
  opacity: 0.06;
  overflow: hidden;
}
.about::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 300px;
  height: 100%;
  border-radius: 0px 0px 50px 0px;
  background: url("assets/images/testimonials/binaryNumbersBg.png");
  background-size: contain;
  background-repeat: repeat;
  transform: translateX(30px);
  opacity: 0.06;
  overflow: hidden;
}
@media only screen and (max-width: 992px) {
  .about::after {
    left: -100px;
  }
  .about::before {
    right: -100px;
  }
}
@media only screen and (max-width: 992px) and (max-width: 640px) {
  .about::after {
    left: -200px;
  }
  .about::before {
    right: -200px;
  }
}

.about__success {
  width: 100%;
  padding: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-x: hidden;
}

.about__success-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px var(--inside-padding);
  overflow: hidden;
}
.about__success-container .about__success-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.about__success-container .about__success-carousel .success__carousel .swiper-wrapper {
  display: flex;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination {
  margin-top: 20px;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-pagination {
    margin-top: 0px;
  }
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  opacity: 0.2;
}
.about__success-container .about__success-carousel .success__carousel .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--main-color) !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 1;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next {
  width: 64px;
  height: 64px;
  background-color: rgba(12, 27, 43, 0.8);
  border-radius: 50px;
  right: 0;
  z-index: 999 !important;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next:hover {
  background-color: #0c1b2b;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-next::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("assets/images/icons/angle_right.png");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-button-next {
    bottom: 0 !important;
    top: unset !important;
  }
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev {
  width: 64px;
  height: 64px;
  background-color: rgba(12, 27, 43, 0.8);
  border-radius: 50px;
  left: 0;
  z-index: 999 !important;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev:hover {
  background-color: #0c1b2b;
}
.about__success-container .about__success-carousel .success__carousel .swiper-button-prev::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("assets/images/icons/angle_left.png");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
}
@media only screen and (max-width: 992px) {
  .about__success-container .about__success-carousel .success__carousel .swiper-button-prev {
    bottom: 0 !important;
    top: unset !important;
  }
}
.about__success-container .about__success-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
@media only screen and (max-width: 650px) {
  .about__success-container .about__success-title h2 {
    max-width: 100% !important;
  }
}
.about__success-container .about__success-title h1 {
  font-size: var(--xl-font-size);
  font-family: "Poppins-Bold";
  font-weight: 700;
  color: var(--secondary-text-color);
}
.about__success-container .about__success-title h2 {
  width: 100%;
  max-width: 60%;
  font-size: var(--m-font-size);
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: var(--secondary-text-color);
}

.success__section-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
}
.success__section-container .success__section-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
}
.success__section-container .success__section-wrapper .success__timeline {
  position: absolute;
  top: 4%;
  left: 0;
  display: flex;
  width: 3px;
  height: calc(100% - 100px);
}
.success__section-container .success__section-wrapper .success__timeline span {
  display: flex;
  width: 3px;
  height: 100%;
  background-color: var(--secondary-purple-color);
  border-radius: 5px;
}
.success__section-container .success__section-wrapper .success__section-content {
  width: 100%;
  padding: 30px 0px;
  display: flex;
  gap: 70px;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content {
    gap: 35px;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item {
    align-items: flex-start;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__content-img,
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date-img {
    display: none !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date {
    max-width: 15px !important;
    transform: translateY(80px) !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__date h3 {
    display: none;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
    max-width: -moz-max-content !important;
    max-width: max-content !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card::after {
    display: none;
  }
}
@media only screen and (max-width: 992px) and (max-width: 700px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
    width: 100% !important;
    max-width: 100% !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    height: 130px !important;
    width: 210px !important;
    align-self: center !important;
    transform: translateY(-10px);
  }
}
@media only screen and (max-width: 992px) and (max-width: 700px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    width: 70% !important;
    height: 140px !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
  width: 100%;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date span {
  width: 100%;
  min-width: 15px;
  background-color: var(--secondary-purple-color);
  height: 3px;
  border-radius: 5px 0px 0px 5px;
  z-index: 1;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date h3 {
  font-size: var(--xs-font-size);
  color: var(--secondary-subtitle-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  z-index: 1;
  padding-left: 10px;
  word-wrap: break-word;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date .item__date-img {
  max-width: 150px;
  max-height: 112px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-1%, -30%);
  overflow: hidden;
  z-index: -1;
  opacity: 0.4;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__date .item__date-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.4s;
  z-index: 5;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card::after {
  content: "";
  position: absolute;
  bottom: -20%;
  left: 0;
  right: 0;
  height: 20px;
  width: 105px;
  margin-left: auto;
  margin-right: auto;
  background: url("assets/images/illustrations/effect_element.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  opacity: 0.5;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card h3 {
  font-size: var(--xs-font-size);
  color: var(--secondary-subtitle-text-color);
  font-family: "Poppins-Medium";
  font-weight: 500;
  word-wrap: break-word;
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card h3 {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover {
    transform: scale(1.01);
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover .item__img::after {
    -webkit-backdrop-filter: blur(0px) !important;
            backdrop-filter: blur(0px) !important;
  }
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card:hover .item__content {
    border-radius: 10px !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content {
  position: relative;
  max-width: 330px;
  width: 100%;
  padding: 30px 24px;
  border-radius: 15px;
  box-shadow: 2px 7px 11px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content h1 {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Bold";
  font-weight: 700;
  letter-spacing: 0.035rem;
  text-align: left;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__content p {
  font-size: var(--xs-font-size);
  color: var(--main-text-color);
  font-family: "Poppins-Light";
  font-weight: 300;
  letter-spacing: 0.02rem;
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  z-index: 1;
  box-shadow: -3px 0 6px 1px rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 992px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    transform: translateX(-10px);
  }
}
@media only screen and (max-width: 400px) {
  .success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img {
    width: 90% !important;
  }
}
.success__section-container .success__section-wrapper .success__section-content .success__item .item__card .item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=homeStyle.css.map */