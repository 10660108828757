.about__sep {
  width: 100%;
  padding: 100px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-body-color);
  .about__sep-container {
    max-width: 1230px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px var(--inside-padding);
    flex-direction: column;
    gap: 70px;
    @media only screen and (max-width: 1240px) {
      max-width: 500px;
    }
    .about__sep-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      h1 {
        font-size: var(--xl-font-size);
        font-weight: 700;
        font-family: $font-bold;
        color: var(--secondary-light-red);
      }
      h2 {
        font-size: var(--m-font-size);
        font-weight: 300;
        font-family: $font-light;
        color: var(--secondary-text-color);
      }
    }
  }
}

// // SEP inputs design
// .sep__container {
//   width: 100%;
//   display: -webkit-box;
// display: -ms-flexbox;
// display: flex;
//   justify-content: space-between;
//   -webkit-box-align: center;
// -ms-flex-align: center;
// align-items: center;
//   gap: 90px;
//   @media only screen and (max-width: 992px) {
//     gap: 20px;
//   }
//   @media only screen and (max-width: 790px) {
//     flex-direction: column;
//     gap: 20px;
//     .sep__inputs {
//       width: 100% !important;
//     }
//     .sep__input {
//       font-size: var(--s-font-size) !important;
//       &::placeholder {
//         font-size: var(--s-font-size) !important;
//       }
//     }
//   }
//   .sep__inputs {
//     width: clamp(490px, 100%, 200px);
//     display: -webkit-box;
// display: -ms-flexbox;
// display: flex;
//     flex-direction: column;
//     gap: 15px;
//     -webkit-box-align: center;
// -ms-flex-align: center;
// align-items: center;
//     justify-content: center;
//     .sep__input {
//       width: 100%;
//       height: 65px;
//       background-color: var(--secondary-dark-color);
//       border-radius: 15px;
//       font-size: var(--m-font-size);
//       color: var(--main-text-color);
//       font-family: $font-bold;
//       font-weight: 700;
//       padding: 0px 5px;
//       border: 0.5px solid var(--secondary-dark-color);
//       outline: none;
//       &:focus {
//         border: 4px solid var(--secondary-dark-color);
//         &::placeholder {
//           color: var(--main-text-color);
//           transition: 0.35s;
//         }
//       }
//       @media only screen and (min-width: 992px) {
//         &:hover {
//           border: 1px solid var(--main-color);
//           transform: scale(1.02);
//           &::placeholder {
//             color: var(--main-color);
//             transition: 0.35s;
//           }
//         }
//       }
//       &::placeholder {
//         font-size: var(--m-font-size);
//         font-family: $font-bold;
//         font-weight: 700;
//         color: var(--main-secondary-text-color);
//         transition: 0.35s;
//       }
//     }
//   }
//   .sep__message {
//     max-width: 550px;
//     display: -webkit-box;
// display: -ms-flexbox;
// display: flex;
//     flex-direction: column;
//     gap: 15px;
//     -webkit-box-align: center;
// -ms-flex-align: center;
// align-items: center;
//     justify-content: center;
//     p {
//       max-width: 550px;
//       font-size: var(--xs-font-size);
//       font-family: $font-light;
//       font-weight: 300;
//       color: var(--disabled-color);
//       text-align: center;
//     }
//     .sep-message {
//       width: 100%;
//       height: 100%;
//       max-height: 430px;
//       border-radius: 15px;
//       font-size: var(--m-font-size);
//       color: var(--main-text-color);
//       font-family: $font-bold;
//       font-weight: 700;
//       background-color: var(--secondary-dark-color);
//       resize: none;
//       padding: 10px;
//       border: 0.5px solid var(--secondary-dark-color);
//       outline: none;
//       &:focus {
//         border: 4px solid var(--secondary-dark-color);
//         &::placeholder {
//           color: var(--main-text-color);
//           transition: 0.35s;
//         }
//       }
//       @media only screen and (min-width: 992px) {
//         &:hover {
//           border: 1px solid var(--main-color);
//           transform: scale(1.02);
//           &::placeholder {
//             color: var(--main-color);
//             transition: 0.35s;
//           }
//         }
//       }
//       &::placeholder {
//         font-size: var(--m-font-size);
//         font-family: $font-bold;
//         font-weight: 700;
//         color: var(--main-secondary-text-color);
//         transition: 0.35s;
//       }
//     }
//     .sep-btn {
//       width: 100% !important;
//     }
//   }
// }

// Inputs Color
.dark-blue-inputs {
  background-color: var(--secondary-dark-color) !important;
  &:focus {
    &::placeholder {
      color: var(--secondary-light-red) !important;
    }
    color: var(--secondary-light-red) !important;
  }
  &:hover {
    &::placeholder {
      color: var(--secondary-light-red) !important;
    }
    color: var(--secondary-light-red) !important;
  }
}
