@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}

// Fonts Variables
$font-bold: "Poppins-Bold"; // Font weight 700
$font-medium: "Poppins-Medium"; // Font weight 500
$font-light: "Poppins-Light"; // Font weight 300

// Colors
$main-secondary-text-color: #e6e7e8;
$main-text-color: #fff;
$secondary-color: #ffb000;
$main-color: #40b1e5;
$secondary-body-color: #e7edfb;
$secondary-text-color: #333;
$secondary-dark-color: #00416b;
$secondary-light-blue: #4fabb8;
$secondary-light-red: #f15b5b;
$secondary-purple-color: #9f64a8;
$secondary-light-green-color: #8dc63f;
$secondary-subtitle-text-color: #5e5e5e;
$secondary-dark-body-color: #0c1b2b;
$disabled-color: #5e5e5e;
$footer-color: #152e4a;
