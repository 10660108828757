.home__testimonials {
  position: relative;
  width: 100%;
  padding: 50px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-color);
  &::before {
    content: "";
    position: absolute;
    flex: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: url("assets/images/testimonials/binaryNumbersBg.png");
    background-repeat: repeat;
    background-position: center;
    background-size: 181.78px 313.21px;
    background-attachment: fixed;
    z-index: 0;
    opacity: 0.3;
  }
  .home__testimonials-container {
    width: 100%;
    padding: 0px var(--inside-padding);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
    @media only screen and (max-width: 992px) {
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .home__testimonials-info-img {
      max-width: 50%;
      width: 100%;
      height: 300px;
      @media only screen and (max-width: 992px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .home__testimonials-info-container {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      .home__testimonials-title {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        z-index: 1;
        @media only screen and (max-width: 992px) {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          h1,
          h2 {
            text-align: center !important;
          }
          h2 {
            font-size: var(--m-font-size) !important;
          }
        }
        h1 {
          font-size: var(--xl-font-size);
          font-weight: 700;
          font-family: $font-bold;
          color: var(--secondary-light-red);
          text-align: left;
        }
        h2 {
          font-size: var(--l-font-size);
          font-weight: 500;
          font-family: $font-medium;
          color: var(--main-secondary-text-color);
          text-align: left;
        }
      }
      .home__testimonials-content {
        position: relative;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .swiper.swiper-initialized {
          position: relative;
          width: 100% !important;
          overflow: visible;
        }
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
        .home__testimonials-cards {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          justify-content: center;
          max-width: 600px;
          width: 100%;
          gap: 10px;
          padding: 10px 10px 10px;
          @media only screen and (max-width: 992px) {
            flex-direction: column;
            gap: 10px;

            .home__testimonials-info {
              max-width: 100% !important;
            }
          }
          .home__testimonials-img {
            position: relative;
            max-width: 170px !important;
            width: 100%;
            height: 170px;
            background-color: rgba($color: #0c1b2b, $alpha: 0.4);
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            border: 3px solid var(--secondary-light-red);
            border-radius: 100%;
            backdrop-filter: Blur(1px);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .home__testimonials-info {
            max-width: 100%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            @media only screen and (max-width: 992px) {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              h3,
              h4,
              p {
                text-align: center;
              }
            }
            p {
              font-size: var(--s-font-size);
              font-family: $font-light;
              font-weight: lighter;
              color: var(--main-text-color);

              @media only screen and (min-width: 992px) {
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                line-clamp: 8;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
              }

              &::before {
                content: "🙶";
                color: var(--secondary-light-red);
                font-size: var(--xs-font-size);
                padding-right: 2px;
              }
              &::after {
                content: "🙷";
                color: var(--secondary-light-red);
                font-size: var(--xs-font-size);
                padding-left: 2px;
              }
            }
            h3 {
              font-size: var(--m-font-size);
              font-family: $font-bold;
              font-weight: 700;
              color: var(--secondary-light-red);
            }
            h4 {
              font-size: var(--m-font-size);
              font-family: $font-light;
              font-weight: 300;
              color: var(--main-secondary-text-color);
            }
          }
        }
      }
      .swiper.swiper-initialized {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
      }
      .swiper-pagination {
        position: unset !important;
        width: 120px !important;
        transform: unset !important;
      }
      .swiper-pagination-bullet {
        background-color: var(--secondary-dark-body-color) !important;
        width: 15px !important;
        height: 15px !important;
        opacity: 1 !important;
      }

      .swiper-pagination-bullet-active {
        background-color: var(--secondary-light-red) !important;
        width: 20px !important;
        height: 15px !important;
        border-radius: 10px !important;
        transition: 0.4s ease-in;
      }
    }
  }
}
