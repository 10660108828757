// Styling
.slider__div-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 70px 55px;
  background-color: var(--main-secondary-text-color);
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .slider__info {
      width: 70% !important;
      height: auto !important;
      position: relative !important;
      transform: translateY(-50px) !important;
      right: 0 !important;
      .slider__info-img {
        right: -120px !important;
      }
    }
    .slider__img-container {
      width: 80% !important;
      .slider__img {
        max-height: 350px !important;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    padding: 10px 10px 55px;
    padding-bottom: 23px;
    .slider__info {
      width: 90% !important;
      height: auto !important;
      position: relative !important;
      transform: translateY(-50px) !important;
      right: 0 !important;
      box-shadow: 0px 5px 10px 2px rgba($color: #000000, $alpha: 0.3) !important;
      .slider__info-img {
        display: none !important;
      }
      h1 {
        font-size: var(--s-font-size) !important;
      }
      p {
        font-size: var(--xs-font-size) !important;
      }
    }
    .slider__img-container {
      width: 100% !important;
      .slider__img {
        height: 250px !important;
        box-shadow: 0px 5px 4px 0px rgba($color: #000000, $alpha: 0.3) !important;
        img {
          height: 100%;
        }
      }
      &::after {
        width: 98% !important;
        left: 1% !important;
        bottom: -10px !important;
        box-shadow: 0px 5px 4px 0px rgba($color: #000000, $alpha: 0.3) !important;
      }
    }
  }
  .slider__img-container {
    position: relative;
    width: 55%;
    &::after {
      content: "";
      position: absolute;
      left: -8px;
      bottom: -8px;
      background-color: var(--secondary-dark-body-color);
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 15px;
      box-shadow: -3px 4px 10px 0 rgba(0, 0, 0, 0.3);
    }
    .slider__img {
      width: 100%;
      height: 315px;
      // height: 100%;
      overflow: hidden;
      border-radius: 13px;
      box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.3);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .slider__info {
    position: absolute;
    right: 70px;
    width: 50%;
    padding: 25px 20px;
    background-color: var(--secondary-dark-body-color);
    border-radius: 25px;
    min-height: 215px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    // transform: translateX(-70px);
    box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 0.3);
    h1 {
      font-size: var(--l-font-size);
      font-family: $font-medium;
      color: var(--main-color);
      font-weight: 500;
    }
    p {
      font-size: var(--m-font-size);
      font-family: $font-light;
      font-weight: 300;
      color: var(--main-secondary-text-color);
    }
    .slider__info-img {
      position: absolute;
      right: -90px;
      bottom: -70px;
      max-width: 180px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
