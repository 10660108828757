@font-face {
  font-family: "Poppins-Bold";
  src: url("/src/assets/font/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/src/assets/font/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}
.programs__section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-secondary-text-color-2);
  padding: 120px 0 170px;
}
@media only screen and (max-width: 992px) {
  .programs__section {
    padding: 110px 0 15vw;
  }
}
.programs__section .programs__container {
  width: 100%;
  max-width: 1230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;
  padding: 0px var(--inside-padding);
}
@media only screen and (max-width: 692px) {
  .programs__section .programs__container {
    gap: 50px;
  }
}
.programs__section .programs__container .programs__section__intro {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 692px) {
  .programs__section .programs__container .programs__section__intro p {
    font-size: var(--s-font-size) !important;
  }
}
.programs__section .programs__container .programs__section__intro .divider {
  width: 60%;
  height: 3px;
  display: block;
  background-color: var(--secondary-subtitle-text-color);
}
.programs__section .programs__container .programs__section__intro h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--secondary-text-color);
}
.programs__section .programs__container .programs__section__intro p {
  font-family: "Poppins-Light";
  font-size: var(--l-font-size);
  font-weight: 300;
  color: var(--secondary-subtitle-text-color);
}
.programs__section .programs__container .programs__cards-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
.programs__section .programs__container .programs__cards-section .programs__section-title {
  font-size: var(--xl-font-size);
  color: var(--secondary-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
}
@media only screen and (max-width: 992px) {
  .programs__section .programs__container .programs__cards-section .programs__section-title {
    font-size: var(--l-font-size);
  }
}

.programs__section__grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.programs__section__grid .programs__section__grid__title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
.programs__section__grid .programs__section__grid__title h1 {
  font-family: "Poppins-Medium";
  font-size: var(--xl-font-size);
  color: var(--main-text-color);
  font-weight: 500;
  position: relative;
}
.programs__section__grid .programs__section__grid__title h2 {
  font-family: "Poppins-Light";
  font-size: var(--m-font-size);
  font-weight: 300;
  color: var(--main-text-color);
}
.programs__section__grid .programs__section__grid__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

@media only screen and (max-width: 1230px) {
  .programs__section__grid {
    max-width: 822px !important;
  }
  .programs__section__grid__cards {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.program__card {
  position: relative;
  max-width: 390px;
  width: 100%;
  height: 465px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: -1px 4px 20px -2px rgba(0, 0, 0, 0.22);
}
@media only screen and (max-width: 992px) {
  .program__card {
    max-width: 380px;
    height: 420px;
  }
  .program__card .program__card-content h1 {
    font-size: var(--m-font-size) !important;
  }
  .program__card .program__card-content span {
    font-size: var(--xs-font-size) !important;
  }
}
.program__card:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(0, 0, 0, 0.2);
  transition: 0.4s ease-in;
}
.program__card:hover .program__card-divider {
  background-color: rgba(255, 255, 255, 0.5);
}
.program__card:hover .program__card-bgImg {
  right: -20px;
  transition: 0.25s ease-in;
}
.program__card:hover .program__card-content h1,
.program__card:hover .program__card-content p {
  color: var(--main-text-color);
}
.program__card:hover .program__card-content span {
  color: var(--main-text-color);
}
.program__card:hover .program__card-content span::after {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.25s ease-in;
}
@media only screen and (min-width: 992px) {
  .program__card.yellow-theme:hover {
    background-color: var(--secondary-color);
  }
  .program__card.yellow-theme:hover button {
    background-color: var(--secondary-purple-color) !important;
  }
  .program__card.purple-theme:hover {
    background-color: var(--secondary-purple-color);
  }
  .program__card.purple-theme:hover button {
    background-color: var(--secondary-light-green-color) !important;
  }
  .program__card.darkBlue-theme:hover {
    background-color: var(--secondary-dark-body-color);
  }
  .program__card.darkBlue-theme:hover button {
    background-color: var(--main-color) !important;
  }
  .program__card.blue-theme:hover {
    background-color: var(--footer-color);
  }
  .program__card.blue-theme:hover button {
    background-color: var(--secondary-light-red) !important;
  }
  .program__card.lightBlue-theme:hover {
    background-color: var(--main-color);
  }
  .program__card.lightBlue-theme:hover button {
    background-color: var(--secondary-light-red) !important;
  }
  .program__card.red-theme:hover {
    background-color: var(--secondary-light-red);
  }
  .program__card.red-theme:hover button {
    background-color: var(--secondary-color) !important;
  }
}
.program__card .program__card-bgImg {
  content: "";
  position: absolute;
  bottom: -9px;
  right: -100px;
  max-width: 100px;
  width: 100%;
  height: 100px;
  z-index: 0;
  opacity: 0.4;
  transition: 0.25s ease-in;
}
.program__card .program__card-bgImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.program__card .program__card-img {
  width: 100%;
  flex: 0.6;
  overflow: hidden;
  border-radius: 15px 15px 5px 5px;
  z-index: 1;
}
.program__card .program__card-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: inherit;
}
.program__card .program__card-divider {
  width: 100%;
  height: 2px;
  border-radius: 100px;
  z-index: 1;
  background-color: rgba(51, 51, 51, 0.2);
  z-index: 1;
}
.program__card .program__card-content {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  z-index: 1;
}
.program__card .program__card-content div {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.program__card .program__card-content h1 {
  font-size: var(--m-font-size);
  color: var(--secondary-text-color);
  font-weight: 700;
  font-family: "Poppins-Bold";
  text-align: center;
  align-self: center;
}
.program__card .program__card-content p {
  font-size: var(--xs-font-size);
  color: var(--secondary-text-color);
  font-weight: 300;
  font-family: "Poppins-Light";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: center;
}
.program__card .program__card-content span {
  position: relative;
  padding: 6px;
  font-size: var(--s-font-size);
  color: var(--secondary-text-color);
  font-family: "Poppins-Light";
  align-self: center;
}
.program__card .program__card-content span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  border-radius: 15px;
  z-index: -1;
  border: 1px solid rgba(51, 51, 51, 0.3);
  transition: 0.25s ease-in;
}

.programs__contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
  background-color: var(--secondary-color);
}
.programs__contact .programs__contact-container {
  width: 100%;
  max-width: 1230px;
  padding: 0px var(--inside-padding);
  display: flex;
  flex-direction: column;
  gap: 100px;
}
@media only screen and (max-width: 1210px) {
  .programs__contact .programs__contact-container {
    max-width: 500px;
  }
}
.programs__contact .programs__contact-container .programs__contact-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}

.programs {
  width: 100%;
  padding: 160px 0px 0px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  background-image: linear-gradient(0deg, rgba(var(--main-color), 0) 70%, rgba(212, 177, 218, 0.55) 100%);
  background-size: 100% 150%;
  background-position: top left;
  animation: programsBreathingAnim 10s ease-in-out infinite;
}
@media only screen and (max-width: 650px) {
  .programs {
    padding-top: 135px;
    padding-bottom: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .programs {
    min-height: 60vh;
  }
}
.programs .programs__container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 0px var(--inside-padding);
}
.programs .programs__container .programs__container-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .programs .programs__container .programs__container-content {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
  .programs .programs__container .programs__container-content .programs__container-info {
    align-items: center !important;
    max-width: 700px !important;
  }
  .programs .programs__container .programs__container-content .programs__container-info h1,
  .programs .programs__container .programs__container-content .programs__container-info p {
    text-align: center !important;
  }
  .programs .programs__container .programs__container-content .programs__container-img::after {
    width: 70% !important;
    height: 20% !important;
    background-color: #18cded !important;
  }
}
.programs .programs__container .programs__container-content .programs__container-img {
  position: relative;
  flex: 0.5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 50px;
}
@media only screen and (max-width: 992px) {
  .programs .programs__container .programs__container-content .programs__container-img {
    display: none;
  }
}
.programs .programs__container .programs__container-content .programs__container-img::after {
  content: "";
  width: 80%;
  height: 60%;
  position: absolute;
  background-color: #18cded !important;
  border-radius: 100%;
  filter: blur(35px);
  z-index: 0;
  opacity: 0.6;
}
.programs .programs__container .programs__container-content .programs__container-img img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.programs .programs__container .programs__container-content .programs__container-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  flex: 0.5;
}
.programs .programs__container .programs__container-content .programs__container-info .divider {
  max-width: 40%;
  width: 100%;
  height: 3px;
  display: block;
  background-color: white;
  border-radius: 20px;
}
@media only screen and (max-width: 692px) {
  .programs .programs__container .programs__container-content .programs__container-info h1 {
    font-size: var(--l-font-size) !important;
    text-align: center !important;
  }
  .programs .programs__container .programs__container-content .programs__container-info p {
    font-size: var(--s-font-size) !important;
    text-align: center !important;
  }
}
.programs .programs__container .programs__container-content .programs__container-info h1 {
  font-size: var(--xl-font-size);
  font-weight: 700;
  font-family: "Poppins-Bold";
  color: var(--main-text-color);
  text-align: left;
}
.programs .programs__container .programs__container-content .programs__container-info p {
  max-width: 932px;
  font-size: var(--s-font-size);
  font-weight: 300;
  font-family: "Poppins-Light";
  color: var(--main-text-color);
  text-align: left;
}
.programs .programs__container .scroll-down {
  margin-top: 20px;
  animation: upDown 0.9s ease-in-out infinite;
  cursor: pointer;
}

.programs__vector-svg {
  width: 100%;
  max-height: 108.54px;
  background-color: var(--main-secondary-text-color-2);
  z-index: 5;
}
.programs__vector-svg img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
}
@keyframes programsBreathingAnim {
  0% {
    background-position: top;
  }
  30% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}/*# sourceMappingURL=programsStyle.css.map */